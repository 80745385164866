/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  InvoicesPayload,
  allInvoicesList,
  approveInvoice,
  deleteInvoice,
  getTypedInvoicesStat,
  invoiceDetails,
  markPaidInvoice,
  ownInvoiceList,
  rejectInvoice,
  returnInvoice,
  usePagination,
  waitingForApprovalInvoiceList,
  TypeInvoicesStatPayload,
  ErrorResponse,
  getInvoiceListReport,
  putInvoiceOnHold,
  InvoicesReportPayload,
} from '@nbfc-expense-tool/data-store/utils';
import { useCallback, useEffect, useMemo, useReducer, useState } from 'react';
import { useForm } from 'react-hook-form';
import {
  InvoiceDetails,
  InvoiceListItem,
  Invoice_Status,
  Payment_status,
} from './types';
// eslint-disable-next-line @nx/enforce-module-boundaries
import { toastQueue } from '@nbfc-expense-tool/ui';
import { useNavigate } from 'react-router-dom';

type InvoicesResponse = {
  data: InvoiceListItem[];
  current_page: number;
  total: number;
  per_page?: number;
  last_page?: number;
};

type InvoicesListState = {
  status: 'in_progress' | 'success' | 'failed';
  error?: string | Error | null;
  invoices?: InvoiceListItem[];
  totalInvoices?: number;
  perPage?: number;
  currentPage?: number;
  syncingWithFilers?: boolean;
  last_page?: number;
};

const initialInvoiceList: InvoicesListState = {
  status: 'in_progress',
  error: null,
  invoices: undefined,
  syncingWithFilers: false,
};

type INVOICES_LIST_TYPES =
  | { type: 'FETCHING_INVOICES' }
  | { type: 'FETCHED_INVOICES'; payload: InvoicesResponse }
  | { type: 'FETCHING_INVOICES_FAILED'; payload: Error }
  | { type: 'FETCHING_INVOICES_WITH_FILTERS' };

const reducer = (
  state: InvoicesListState,
  action: INVOICES_LIST_TYPES
): InvoicesListState => {
  switch (action.type) {
    case 'FETCHING_INVOICES':
      return {
        ...state,
        ...initialInvoiceList,
      };
    case 'FETCHING_INVOICES_WITH_FILTERS':
      return {
        ...state,
        error: null,
        syncingWithFilers: true,
      };
    case 'FETCHED_INVOICES':
      return {
        ...state,
        status: 'success',
        error: null,
        syncingWithFilers: false,
        currentPage: action.payload.current_page,
        totalInvoices: action.payload.total,
        invoices: action.payload.data,
        perPage: action.payload.per_page,
        last_page: action.payload.last_page,
      };
    case 'FETCHING_INVOICES_FAILED':
      return {
        ...state,
        status: 'failed',
        error: action.payload,
        syncingWithFilers: false,
      };
    default:
      return state;
  }
};

type DateFilter = {
  label: string;
  value: string;
  dates?: [Date, Date];
};

type OwnInvoicesFilters = {
  dateFilter?: DateFilter;
  branches?: string[];
  vendors?: string[];
  invoice_status?: Invoice_Status;
  payment_status?: Payment_status | null;
  q?: string;
};

const initialOwnInvoiceFilters: OwnInvoicesFilters = {
  dateFilter: {
    label: 'All Time',
    value: 'all',
  },
};

type INVOICES_DETAILS_TYPES =
  | { type: 'FETCHING_INVOICE' }
  | { type: 'FETCHED_INVOICE'; payload: InvoiceDetails }
  | { type: 'FETCHING_INVOICE_FAILED'; payload: Error };

type InvoiceDetailsState = {
  status: 'in_progress' | 'success' | 'failed';
  error?: string | Error | null;
  invoice?: InvoiceDetails;
};

const initialInvoiceDetails: InvoiceDetailsState = {
  status: 'in_progress',
  error: null,
  invoice: undefined,
};

const invoiceDetailsReducer = (
  state: InvoiceDetailsState,
  action: INVOICES_DETAILS_TYPES
): InvoiceDetailsState => {
  switch (action.type) {
    case 'FETCHING_INVOICE':
      return {
        ...state,
        ...initialInvoiceDetails,
      };
    case 'FETCHED_INVOICE':
      return {
        ...state,
        status: 'success',
        error: null,
        invoice: action.payload,
      };
    case 'FETCHING_INVOICE_FAILED':
      return {
        ...state,
        status: 'failed',
        error: action.payload,
      };
    default:
      return state;
  }
};

export function useInvoiceDetails(ticketNumber: string) {
  const [state, dispatch] = useReducer(
    invoiceDetailsReducer,
    initialInvoiceDetails
  );
  const navigate = useNavigate();
  const [shouldApiCall, setShouldApiCall] = useState<boolean>(true);
  const [
    isDeleteInvoiceConfirmationDialogVisible,
    setIsDeleteInvoiceConfirmationDialogVisible,
  ] = useState<boolean>(false);
  const [isDeleteInvoiceApiLoading, setIsDeleteInvoiceApiLoading] =
    useState<boolean>(false);

  const [isPutOnHoldInvoiceDialogVisible, setIsPutOnHoldInvoiceDialogVisible] =
    useState(false);
  const [isPutOnHoldInvoiceLoading, setIsPutOnHoldInvoiceLoading] =
    useState(false);

  const getInvoiceDetails = useCallback(async () => {
    try {
      const response = await invoiceDetails<{ data: InvoiceDetails }>(
        ticketNumber
      );
      dispatch({ type: 'FETCHED_INVOICE', payload: response.data });
    } catch (e) {
      const error = e as Error;
      dispatch({ type: 'FETCHING_INVOICE_FAILED', payload: error });
    }
  }, [ticketNumber]);

  useEffect(() => {
    if (shouldApiCall) {
      setShouldApiCall(false);
      getInvoiceDetails();
    }
  }, [shouldApiCall, getInvoiceDetails]);

  function reload() {
    setShouldApiCall(true);
  }

  const onClickPutInvoiceOnHold = () => {
    setIsPutOnHoldInvoiceDialogVisible(true);
  };

  const closePutInvoiceOnHoldConfirmationDialog = () => {
    setIsPutOnHoldInvoiceDialogVisible(false);
  };

  const onClickDeleteInvoice = () => {
    setIsDeleteInvoiceConfirmationDialogVisible(true);
  };

  const closeDeleteInvoiceConfirmationDialog = () => {
    setIsDeleteInvoiceConfirmationDialogVisible(false);
  };

  const onConfirmPutOnHold = async () => {
    try {
      setIsPutOnHoldInvoiceLoading(true);
      await putInvoiceOnHold(state.invoice?.ticket_number || '');
      reload();
      toastQueue.add(
        {
          title: 'Invoice put on hold successfully',
          type: 'success',
        },
        {
          timeout: 2000,
        }
      );
      setIsPutOnHoldInvoiceDialogVisible(false);
    } catch (e: any) {
      if (e.status === 422) {
        toastQueue.add(
          {
            title: e.message || 'Something went wrong, Please try again',
            type: 'error',
          },
          {
            timeout: 2000,
          }
        );
      } else {
        toastQueue.add(
          {
            title: 'Something went wrong, Please try again',
            type: 'error',
          },
          {
            timeout: 2000,
          }
        );
      }
    } finally {
      setIsPutOnHoldInvoiceLoading(false);
    }
  };

  const onConfirmDeleteInvoice = async () => {
    try {
      setIsDeleteInvoiceApiLoading(true);
      await deleteInvoice(state.invoice?.ticket_number || '');
      navigate(-1);
      toastQueue.add(
        {
          title: 'Invoice deleted successfully',
          type: 'success',
        },
        {
          timeout: 2000,
        }
      );
    } catch (e) {
      toastQueue.add(
        {
          title: 'Something went wrong, Please try again',
          type: 'error',
        },
        {
          timeout: 2000,
        }
      );
    } finally {
      setIsDeleteInvoiceApiLoading(false);
    }
  };

  return {
    ...state,
    reload,
    onClickDeleteInvoice,
    closeDeleteInvoiceConfirmationDialog,
    onConfirmDeleteInvoice,
    isDeleteInvoiceConfirmationDialogVisible,
    isDeleteInvoiceApiLoading,
    isPutOnHoldInvoiceDialogVisible,
    isPutOnHoldInvoiceLoading,
    onConfirmPutOnHold,
    onClickPutInvoiceOnHold,
    closePutInvoiceOnHoldConfirmationDialog,
  };
}

export function useActionOnInvoice(
  ticketNumber: string,
  type: 'approve' | 'reject' | 'return' | 'markPaid'
) {
  const approveAnInvoice = useCallback(
    async (remark?: string) => {
      try {
        await approveInvoice(ticketNumber, { remarks: remark });
      } catch (e) {
        return e as Error;
      }
    },
    [ticketNumber]
  );

  const rejectAnInvoice = useCallback(
    async (remark?: string) => {
      try {
        await rejectInvoice(ticketNumber, { remarks: remark });
      } catch (e) {
        return e as Error;
      }
    },
    [ticketNumber]
  );

  const returnAnInvoice = useCallback(
    async (remark?: string) => {
      try {
        await returnInvoice(ticketNumber, { remarks: remark });
      } catch (e) {
        return e as Error;
      }
    },
    [ticketNumber]
  );

  const markPaidAnInvoice = useCallback(
    async (utr_number?: string) => {
      await markPaidInvoice(ticketNumber, { utr_number });
    },
    [ticketNumber]
  );

  return useCallback(
    (remark?: string, utr_number?: string) => {
      switch (type) {
        case 'approve':
          return approveAnInvoice(remark);
        case 'reject':
          return rejectAnInvoice(remark);
        case 'return':
          return returnAnInvoice(remark);
        case 'markPaid':
          return markPaidAnInvoice(utr_number);
        default:
          return;
      }
    },
    [
      approveAnInvoice,
      rejectAnInvoice,
      type,
      returnAnInvoice,
      markPaidAnInvoice,
    ]
  );
}

type TypeOfInvoicesCountResponse = {
  data: Array<{
    total_count: number;
    invoice_status: string;
  }>;
};

type INVOICES_OVERVIEW_TYPES =
  | { type: 'FETCHING_INVOICE_OVERVIEW' }
  | { type: 'FETCHED_INVOICE_OVERVIEW'; payload: TypeOfInvoicesCountResponse }
  | { type: 'FETCHING_INVOICE_OVERVIEW_FAILED'; payload: Error };

type InvoiceOverviewState = {
  status: 'in_progress' | 'success' | 'failed';
  error?: string | Error | null;
  overviewStats?: Array<{
    total_count: number;
    invoice_status: string;
  }>;
};

const initialInvoiceOverview: InvoiceOverviewState = {
  status: 'in_progress',
  error: null,
  overviewStats: [],
};

const overviewReducer = (
  state: InvoiceOverviewState,
  action: INVOICES_OVERVIEW_TYPES
): InvoiceOverviewState => {
  switch (action.type) {
    case 'FETCHING_INVOICE_OVERVIEW':
      return {
        ...initialInvoiceOverview,
      };
    case 'FETCHED_INVOICE_OVERVIEW':
      return {
        ...state,
        status: 'success',
        error: null,
        overviewStats: action.payload.data,
      };
    case 'FETCHING_INVOICE_OVERVIEW_FAILED':
      return {
        ...state,
        status: 'failed',
        error: action.payload,
        overviewStats: [],
      };
    default:
      return state;
  }
};

export type TypesOfInvoices =
  | 'created-by-you'
  | 'waiting-for-your-approval'
  | 'all-invoices';

export function useInvoicesOverview(
  fetchFor: TypesOfInvoices = 'all-invoices'
) {
  const [shouldCallApi, setShouldCallApi] = useState<boolean>(true);
  const [state, dispatch] = useReducer(overviewReducer, initialInvoiceOverview);

  const { getValues, setValue } = useForm<OwnInvoicesFilters>({
    defaultValues: initialOwnInvoiceFilters,
  });

  const params = getValues();

  function handleDateChange(values: DateFilter) {
    setShouldCallApi(true);
    setValue('dateFilter', values);
  }

  function handleParamChange(
    key: keyof OwnInvoicesFilters,
    value?:
      | DateFilter
      | string
      | string[]
      | Invoice_Status
      | Payment_status
      | null
  ) {
    setShouldCallApi(true);
    setValue(key, value);
  }

  function resetFilters() {
    handleDateChange({ label: 'All Time', value: 'all' });
    setValue('dateFilter', {
      label: 'All Time',
      value: 'all',
    });
    setValue('branches', undefined);
    setValue('vendors', undefined);
    setValue('q', undefined);
    setShouldCallApi(true);
    dispatch({ type: 'FETCHING_INVOICE_OVERVIEW' });
  }

  const fetchTypedInvoicesCount = useCallback(async () => {
    const contructorPayload: TypeInvoicesStatPayload = {
      type: fetchFor === 'created-by-you' ? 'my' : 'all',
    };
    const { branches, vendors, dateFilter, q } = params;
    if (dateFilter?.dates) {
      contructorPayload['invoice_date_after'] =
        dateFilter.dates[0].toISOString();
      contructorPayload['invoice_date_before'] =
        dateFilter.dates[1].toISOString();
    }
    if (branches?.length) {
      contructorPayload['branch_id'] = branches;
    }
    if (vendors?.length) {
      contructorPayload['vendor_id'] = vendors;
    }
    if (q?.length) {
      contructorPayload['q'] = q;
    }
    try {
      const response = await getTypedInvoicesStat<TypeOfInvoicesCountResponse>(
        contructorPayload
      );
      dispatch({ type: 'FETCHED_INVOICE_OVERVIEW', payload: response });
    } catch (e) {
      dispatch({
        type: 'FETCHING_INVOICE_OVERVIEW_FAILED',
        payload: e as Error,
      });
    }
  }, [fetchFor, params]);

  useEffect(() => {
    if (shouldCallApi) {
      fetchTypedInvoicesCount();
      setShouldCallApi(false);
    }
  }, [shouldCallApi, fetchTypedInvoicesCount]);

  return {
    error: state.error,
    status: state.status,
    overview: state.overviewStats,
    resetFilters,
    handleDateChange,
    handleParamChange,
  };
}

export function useInvoices(
  fetchFor: TypesOfInvoices = 'all-invoices',
  initialFilters: OwnInvoicesFilters = initialOwnInvoiceFilters
) {
  const [state, dispatch] = useReducer(reducer, initialInvoiceList);
  const [shouldApiCall, setShouldApiCall] = useState<boolean>(true);

  const {
    current,
    lastPage,
    canGoBack,
    canGoNext,
    perPage,
    nextPage,
    previousPage,
    resetPagination,
  } = usePagination({
    perPage: 10,
    lastPage: state.last_page || 1,
  });
  const [reportLoading, setReportLoading] = useState<boolean>(false);

  const { getValues, setValue } = useForm<OwnInvoicesFilters>({
    defaultValues: initialFilters,
  });

  const params = getValues();

  const exportExcelReport = useCallback(
    async (forMarkMaid = false) => {
      try {
        setReportLoading(true);
        const {
          dateFilter,
          branches,
          vendors,
          invoice_status,
          payment_status,
          q,
        } = params;
        const constructedPayload: InvoicesReportPayload = {};
        if (dateFilter?.dates) {
          constructedPayload['invoice_date_after'] =
            dateFilter.dates[0].toISOString();
          constructedPayload['invoice_date_before'] =
            dateFilter.dates[1].toISOString();
        }
        if (branches?.length) {
          constructedPayload['branch_id'] = branches;
        }
        if (vendors?.length) {
          constructedPayload['vendor_id'] = vendors;
        }
        if (invoice_status) {
          constructedPayload['invoice_status'] = invoice_status;
        }
        if (payment_status) {
          constructedPayload['payment_status'] = payment_status;
        }
        if (q?.length) {
          constructedPayload['q'] = q;
        }
        if (forMarkMaid) {
          constructedPayload['payment_status'] = 'unpaid';
          constructedPayload['export_for_mark_paid'] = 1;
        }
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const data: any = await getInvoiceListReport<InvoicesResponse>(
          fetchFor,
          constructedPayload,
          {
            responseType: 'blob',
            headers: {
              'Content-Type':
                'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
              Accept: '*/*',
            },
          }
        );
        const linkElement = document.createElement('a');
        const url = URL.createObjectURL(data);
        linkElement.href = url;
        linkElement.download = `InvoiceList.xlsx`;
        document.body.appendChild(linkElement);
        linkElement.click();
        setTimeout(function () {
          document.body.removeChild(linkElement);
          window.URL.revokeObjectURL(url);
        }, 0);
        setReportLoading(false);
      } catch (err) {
        const error = err as ErrorResponse;
        toastQueue.add(
          {
            title: error.message || 'Something Went Wrong! Please try again',
            type: 'error',
          },
          {
            timeout: 2000,
          }
        );
        setReportLoading(false);
      }
    },
    [fetchFor, params]
  );

  const getInvoicesList = useCallback(async () => {
    try {
      const {
        dateFilter,
        branches,
        vendors,
        invoice_status,
        payment_status,
        q,
      } = params;
      const constructedPayload: InvoicesPayload = {};
      if (current) {
        constructedPayload['page'] = current;
      }
      if (dateFilter?.dates) {
        constructedPayload['invoice_date_after'] =
          dateFilter.dates[0].toISOString();
        constructedPayload['invoice_date_before'] =
          dateFilter.dates[1].toISOString();
      }
      if (branches?.length) {
        constructedPayload['branch_id'] = branches;
      }
      if (vendors?.length) {
        constructedPayload['vendor_id'] = vendors;
      }
      if (invoice_status) {
        constructedPayload['invoice_status'] = invoice_status;
      }
      if (payment_status) {
        constructedPayload['payment_status'] = payment_status;
      }
      if (q?.length) {
        constructedPayload['q'] = q;
      }
      if (fetchFor === 'created-by-you') {
        const response = await ownInvoiceList<InvoicesResponse>(
          constructedPayload
        );
        dispatch({ type: 'FETCHED_INVOICES', payload: response });
      } else if (fetchFor === 'waiting-for-your-approval') {
        const response = await waitingForApprovalInvoiceList<InvoicesResponse>(
          constructedPayload
        );
        dispatch({ type: 'FETCHED_INVOICES', payload: response });
      } else {
        const response = await allInvoicesList<InvoicesResponse>(
          constructedPayload
        );
        dispatch({ type: 'FETCHED_INVOICES', payload: response });
      }
    } catch (err) {
      const error = err as Error;
      dispatch({ type: 'FETCHING_INVOICES_FAILED', payload: error });
    }
  }, [current, params, fetchFor]);

  useEffect(() => {
    if (shouldApiCall) {
      getInvoicesList();
      setShouldApiCall(false);
    }
  }, [getInvoicesList, shouldApiCall]);

  const handlePageChange = (type: 'next' | 'previous') => {
    if (type === 'next') {
      nextPage();
    } else {
      previousPage();
    }
    setShouldApiCall(true);
    dispatch({ type: 'FETCHING_INVOICES_WITH_FILTERS' });
  };

  function handleDateChange(values: DateFilter) {
    resetPagination();
    setShouldApiCall(true);
    setValue('dateFilter', values);
    dispatch({ type: 'FETCHING_INVOICES_WITH_FILTERS' });
  }

  function handleParamChange(
    key: keyof OwnInvoicesFilters,
    value?:
      | DateFilter
      | string
      | string[]
      | Invoice_Status
      | Payment_status
      | null
  ) {
    resetPagination();
    setShouldApiCall(true);
    setValue(key, value);
    dispatch({ type: 'FETCHING_INVOICES_WITH_FILTERS' });
  }

  function resetFilters() {
    handleDateChange({ label: 'All Time', value: 'all' });
    setValue('dateFilter', {
      label: 'All Time',
      value: 'all',
    });
    resetPagination();
    if (!initialFilters.branches?.length) {
      setValue('branches', undefined);
    }
    if (!initialFilters.vendors?.length) {
      setValue('vendors', undefined);
    }
    if (!initialFilters.invoice_status) {
      setValue('invoice_status', undefined);
    }
    if (!initialFilters.payment_status) {
      setValue('payment_status', undefined);
    }
    setValue('q', undefined);
    setShouldApiCall(true);
    dispatch({ type: 'FETCHING_INVOICES' });
  }

  const hasAppliedFilters = useMemo(() => {
    let isFilterApplied = false;
    if (params.branches?.length && !initialFilters.branches?.length) {
      isFilterApplied = true;
      return isFilterApplied;
    }
    if (params.vendors?.length && !initialFilters.vendors?.length) {
      isFilterApplied = true;
      return isFilterApplied;
    }
    if (params.invoice_status && !initialFilters.invoice_status) {
      isFilterApplied = true;
      return isFilterApplied;
    }
    if (params.payment_status && !initialFilters.payment_status) {
      isFilterApplied = true;
      return isFilterApplied;
    }
    if (params.q?.length) {
      isFilterApplied = true;
      return isFilterApplied;
    }

    return isFilterApplied;
  }, [params, initialFilters]);

  return {
    error: state.error,
    currentPage: current,
    lastPage,
    canGoBack,
    canGoNext,
    perPage,
    params,
    hasAppliedFilters,
    loading: state.status,
    invoices: state.invoices,
    totalInvoices: state.totalInvoices,
    syncingData: state.syncingWithFilers,
    nextPage,
    previousPage,
    resetFilters,
    handleDateChange,
    handlePageChange,
    handleParamChange,
    exportExcelReport,
    reportLoading,
  };
}
