import { useAddOrEditVendor } from '@nbfc-expense-tool/data-store/dashboard';
import {
  Box,
  Button,
  CheckCircleFilled,
  CheckIcon,
  CrossIcon,
  DeleteIcon,
  FullScreenHeader,
  Inline,
  Radio,
  RadioGroup,
  RightCaratIcon,
  Stack,
  Text,
  TextInput,
  UploadDocument,
  SingleSelect,
  MultiSelect,
  PlusIcon,
  EditIcon,
  ConfirmationDialog,
  toastQueue,
  Modal,
  EmptyState,
} from '@nbfc-expense-tool/ui';
import React, { useMemo, useState } from 'react';
import { Controller } from 'react-hook-form';
import { AddOrEditVendorAddress } from './AddOrEditVendorAddress';
import { AddOrEditVendorPaymentDetails } from './AddOrEditVendorPaymentDetails';

const idPrefix = 'add-or-edit-vendor';

const AddOrEditVendor: React.FC<{
  flowType?: 'add' | 'edit';
}> = ({ flowType = 'add' }) => {
  const {
    formFillingStates,
    currentFormFillingStateIndex,
    vendorPrimaryDetailsFormControl,
    vendorLinkFormControl,
    createOrUpdateVendorApiLoading,
    vendorRegistrationTypeOptions,
    vendorTypeOptions,
    vendorBranchOptions,
    scrollViewRef,
    goBack,
    validateFormAndNext,
    selectPreviousFormState,
    changeVendorDetailsFormState,
    updateVendorDetails,
    vendorAddresses,
    currentAddressIndex,
    deleteVendorAddress,
    validatedVendorAddressDetails,
    validatedVendorPaymentDetails,
    modalState,
  } = useAddOrEditVendor(flowType);
  const [isDeleteAddressModalVisible, setIsDeleteAddressModalVisible] =
    useState(false);

  const renderFormFillingStatesIndicator = () => {
    if (flowType === 'edit') return null;
    return (
      <Box display="flex" flexDirection="row" alignItems="center">
        {formFillingStates.map((formFillingState, index) => {
          const isSelected = index === currentFormFillingStateIndex;
          const isCompleted = index < currentFormFillingStateIndex;
          return (
            <React.Fragment key={formFillingState.stateId}>
              <Box
                display="flex"
                flexDirection="row"
                alignItems="center"
                padding="1"
                backgroundColor={
                  isSelected ? 'surfacePrimaryLowest' : undefined
                }
                borderRadius="md"
              >
                {isCompleted ? (
                  <CheckCircleFilled
                    color="iconSuccess"
                    width="3"
                    marginRight="1"
                  />
                ) : (
                  <Box
                    borderRadius="full"
                    backgroundColor={
                      isSelected ? 'surfacePrimary' : 'surfaceNeutralLowest'
                    }
                    marginRight="1"
                    width="3"
                    height="3"
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                  >
                    <Text
                      variation="c1"
                      color={isSelected ? 'textOnSurface' : 'textLow'}
                    >
                      {index + 1}
                    </Text>
                  </Box>
                )}
                <Text
                  variation="c2"
                  color={isSelected ? 'textPrimary' : 'textMedium'}
                >
                  {formFillingState.title}
                </Text>
              </Box>
              {index !== formFillingStates.length - 1 && (
                <Box
                  width="6"
                  height="0.5"
                  backgroundColor="borderSeparator"
                  marginLeft="1"
                  marginRight="1"
                />
              )}
            </React.Fragment>
          );
        })}
      </Box>
    );
  };

  const renderVendorDetailsFormStateNavitgation = useMemo(() => {
    return (
      <Inline
        borderBottomWidth="1"
        borderColor="borderSeparator"
        marginBottom="4"
      >
        {formFillingStates.map((formFillingState, index) => {
          const isSelected = index === currentFormFillingStateIndex;
          return (
            <Box
              id={`${idPrefix}-change-${formFillingState.stateId}`}
              borderBottomWidth="2"
              borderColor={isSelected ? 'borderPrimary' : 'transparent'}
              padding="1.5"
              onClick={() => changeVendorDetailsFormState(index)}
              cursor="pointer"
            >
              <Text
                variation="t4"
                color={isSelected ? 'textPrimary' : 'textMedium'}
              >
                {formFillingState.title}
              </Text>
            </Box>
          );
        })}
      </Inline>
    );
  }, [
    formFillingStates,
    currentFormFillingStateIndex,
    changeVendorDetailsFormState,
  ]);

  const renderVendorPrimaryDetailsForm = useMemo(() => {
    return (
      <Box key="primaryDetails" ref={scrollViewRef}>
        <Box marginBottom="6">
          <Text variation="t3" marginBottom="3">
            Basic Details
          </Text>
          <Inline marginBottom="6">
            <Box flex="1" marginRight="5">
              <Controller
                control={vendorPrimaryDetailsFormControl}
                name="legalName"
                render={(props) => {
                  const {
                    field: { onChange, onBlur, value },
                    fieldState: { error },
                  } = props;
                  return (
                    <TextInput
                      id={`${idPrefix}-primary-details-enter-legal-entity-name`}
                      label={'Legal Entity Name'}
                      fullWidth
                      isRequired
                      placeholder="Enter legal entity name"
                      onChange={onChange}
                      onBlur={onBlur}
                      errorMessage={error?.message}
                      defaultValue={
                        value !== undefined ? String(value) : undefined
                      }
                    />
                  );
                }}
              />
            </Box>
            <Box flex="1" marginRight="5">
              <Controller
                control={vendorPrimaryDetailsFormControl}
                name="displayName"
                render={(props) => {
                  const {
                    field: { onChange, onBlur, value },
                    fieldState: { error },
                  } = props;
                  return (
                    <TextInput
                      id={`${idPrefix}-primary-details-enter-vendor-name`}
                      label={'Vendor Name'}
                      fullWidth
                      isRequired
                      placeholder="Enter vendor name"
                      description="This can be same as Legal Entity Name"
                      onChange={onChange}
                      onBlur={onBlur}
                      errorMessage={error?.message}
                      defaultValue={
                        value !== undefined ? String(value) : undefined
                      }
                    />
                  );
                }}
              />
            </Box>
            <Box flex="1" marginRight="5" />
          </Inline>
          <Inline marginBottom="6">
            <Box flex="1" marginRight="5">
              <Controller
                control={vendorPrimaryDetailsFormControl}
                name="vendorType"
                render={(props) => {
                  const {
                    field: { onChange, value },
                    fieldState: { error },
                  } = props;
                  return (
                    <SingleSelect
                      id={`${idPrefix}-primary-details-select-vendor-type`}
                      options={vendorTypeOptions}
                      onSave={(option) => {
                        onChange(option.value);
                      }}
                      label="Vendor Type"
                      placeholder="Select Vendor Type"
                      isRequired
                      errorMessage={error?.message}
                      value={{ label: value, value: value }}
                    />
                  );
                }}
              />
            </Box>
            <Box flex="1" marginRight="5">
              <Controller
                control={vendorPrimaryDetailsFormControl}
                name="registrationType"
                render={(props) => {
                  const {
                    field: { onChange, value },
                    fieldState: { error },
                  } = props;
                  return (
                    <SingleSelect
                      id={`${idPrefix}-primary-details-select-registration-type`}
                      options={vendorRegistrationTypeOptions}
                      onSave={(option) => {
                        onChange(option.value);
                      }}
                      label="Registration Type"
                      placeholder="Select Registration Type"
                      isRequired
                      errorMessage={error?.message}
                      value={{ label: value, value: value }}
                    />
                  );
                }}
              />
            </Box>
            <Box flex="1" marginRight="5" />
          </Inline>
          <Box width="fitContent" marginBottom="6">
            <Controller
              control={vendorPrimaryDetailsFormControl}
              name="registrationCert"
              render={(props) => {
                const {
                  field: { onChange, value },
                  fieldState: { error },
                } = props;
                return (
                  <>
                    <Text variation="c2" marginBottom="1">
                      Company Registration Certificate
                      <Text marginLeft="0.5" as="span" color="textError">
                        *
                      </Text>
                    </Text>
                    <UploadDocument
                      id={`${idPrefix}-primary-details-registration-cert`}
                      showDownloadButton={false}
                      onClickSelectDocument={(fileBlobValue, documentInfo) =>
                        onChange({
                          fileBlobValue: fileBlobValue,
                          newDocument: true,
                          ...documentInfo,
                        })
                      }
                      documentInfo={value}
                      rightIcon={(props, onClick) => (
                        <DeleteIcon
                          {...props}
                          id={`${idPrefix}-primary-details-delete-selected-registration-cert`}
                          onClick={() => {
                            onChange(undefined);
                            onClick();
                          }}
                          color="iconError"
                          cursor="pointer"
                        />
                      )}
                    />
                    {error?.message && (
                      <Text
                        marginTop="1"
                        borderRadius="md"
                        paddingY="0.5"
                        paddingX="1"
                        display="flex"
                        color="textError"
                        variation="c2"
                        bgColor="surfaceErrorLowest"
                      >
                        {error?.message}
                      </Text>
                    )}
                  </>
                );
              }}
            />
          </Box>
          <Inline marginBottom="6">
            <Box flex="1" marginRight="5">
              <Controller
                control={vendorPrimaryDetailsFormControl}
                name="branchIds"
                render={(props) => {
                  const {
                    field: { onChange, value },
                    fieldState: { error },
                  } = props;
                  return (
                    <>
                      <MultiSelect
                        id={`${idPrefix}-primary-details-select-branches`}
                        type="textInput"
                        label="Branches"
                        value={value}
                        options={vendorBranchOptions}
                        onSave={onChange}
                      />
                      {error?.message && (
                        <Text
                          marginTop="1"
                          borderRadius="md"
                          paddingY="0.5"
                          paddingX="1"
                          display="flex"
                          color="textError"
                          variation="c2"
                          bgColor="surfaceErrorLowest"
                        >
                          {error?.message}
                        </Text>
                      )}
                    </>
                  );
                }}
              />
            </Box>
            <Box flex="1" marginRight="5" />
            <Box flex="1" marginRight="5" />
          </Inline>
          <Inline>
            <Box flex="1" marginRight="5">
              <Controller
                control={vendorPrimaryDetailsFormControl}
                name="isEInvoicingEnabled"
                render={(props) => {
                  const {
                    field: { onChange, value },
                    fieldState: { error },
                  } = props;
                  return (
                    <>
                      <Text variation="b2">
                        E-invoicing
                        <Text marginLeft="0.5" as="span" color="textError">
                          *
                        </Text>
                      </Text>
                      <RadioGroup
                        value={
                          value !== undefined ? (value ? '1' : '0') : undefined
                        }
                        onChange={(val) => onChange(val === '1' ? true : false)}
                      >
                        <Inline gap="6">
                          <Radio value="1" id={`${idPrefix}-e-invoicing-yes`}>
                            <Text variation="b2" color="textMedium">
                              Yes
                            </Text>
                          </Radio>
                          <Radio value="0" id={`${idPrefix}-e-invoicing-no`}>
                            <Text variation="b2" color="textMedium">
                              No
                            </Text>
                          </Radio>
                        </Inline>
                      </RadioGroup>
                      {error?.message && (
                        <Text
                          marginTop="1"
                          borderRadius="md"
                          paddingY="0.5"
                          paddingX="1"
                          display="flex"
                          color="textError"
                          variation="c2"
                          bgColor="surfaceErrorLowest"
                        >
                          {error?.message}
                        </Text>
                      )}
                    </>
                  );
                }}
              />
            </Box>
            <Box flex="1" marginRight="5" />
            <Box flex="1" marginRight="5" />
          </Inline>
        </Box>
        <Box marginBottom="6">
          <Text variation="t3" marginBottom="3">
            Official Details
          </Text>
          <Inline marginBottom="6">
            <Box flex="1" marginRight="5">
              <Controller
                control={vendorPrimaryDetailsFormControl}
                name="panNumber"
                render={(props) => {
                  const {
                    field: { onChange, onBlur, value },
                    fieldState: { error },
                  } = props;
                  return (
                    <TextInput
                      id={`${idPrefix}-primary-details-enter-pan-number`}
                      label={'PAN Number'}
                      fullWidth
                      isRequired
                      placeholder="E.g. ABCDE1234F"
                      onChange={onChange}
                      onBlur={onBlur}
                      errorMessage={error?.message}
                      defaultValue={
                        value !== undefined ? String(value) : undefined
                      }
                      fieldInfo="Ten-character Income Tax code"
                    />
                  );
                }}
              />
            </Box>
            <Box flex="1" marginRight="5">
              <Controller
                control={vendorPrimaryDetailsFormControl}
                name="tanNumber"
                render={(props) => {
                  const {
                    field: { onChange, onBlur, value },
                    fieldState: { error },
                  } = props;
                  return (
                    <TextInput
                      id={`${idPrefix}-primary-details-enter-tan-number`}
                      label={'TAN Number'}
                      fullWidth
                      placeholder="E.g. RAJA99999B"
                      onChange={onChange}
                      onBlur={onBlur}
                      errorMessage={error?.message}
                      defaultValue={
                        value !== undefined ? String(value) : undefined
                      }
                      fieldInfo="Tax-related entity identifier"
                    />
                  );
                }}
              />
            </Box>
            <Box flex="1" marginRight="5">
              <Controller
                control={vendorPrimaryDetailsFormControl}
                name="cinNumber"
                render={(props) => {
                  const {
                    field: { onChange, onBlur, value },
                    fieldState: { error },
                  } = props;
                  return (
                    <TextInput
                      id={`${idPrefix}-primary-details-enter-entity-cin-number`}
                      label={'Entity CIN Number'}
                      fullWidth
                      placeholder="E.g. U67190TN2014PTC096078"
                      onChange={onChange}
                      onBlur={onBlur}
                      errorMessage={error?.message}
                      defaultValue={
                        value !== undefined ? String(value) : undefined
                      }
                      fieldInfo="Registered company ID"
                    />
                  );
                }}
              />
            </Box>
          </Inline>
          <Box width="fitContent" marginBottom="6">
            <Controller
              control={vendorPrimaryDetailsFormControl}
              name="pancard"
              render={(props) => {
                const {
                  field: { onChange, value },
                } = props;
                return (
                  <>
                    <Text variation="c2" marginBottom="1">
                      PAN Card
                    </Text>
                    <UploadDocument
                      id={`${idPrefix}-primary-details-pan-card`}
                      showDownloadButton={false}
                      onClickSelectDocument={(fileBlobValue, documentInfo) =>
                        onChange({
                          fileBlobValue: fileBlobValue,
                          newDocument: true,
                          ...documentInfo,
                        })
                      }
                      documentInfo={value}
                      rightIcon={(props, onClick) => (
                        <DeleteIcon
                          {...props}
                          id={`${idPrefix}-primary-details-delete-selected-pan-card`}
                          onClick={() => {
                            onChange(undefined);
                            onClick();
                          }}
                          color="iconError"
                          cursor="pointer"
                        />
                      )}
                    />
                  </>
                );
              }}
            />
          </Box>
          <Box marginBottom="6">
            <Controller
              control={vendorPrimaryDetailsFormControl}
              name="isDpiitCertified"
              render={(props) => {
                const {
                  field: { onChange, value },
                  fieldState: { error },
                } = props;
                return (
                  <>
                    <Inline marginBottom="3">
                      <Box flex="1" marginRight="5">
                        <Text variation="b2">
                          Is DPIIT Certified?
                          <Text marginLeft="0.5" as="span" color="textError">
                            *
                          </Text>
                        </Text>
                        <RadioGroup
                          value={
                            value !== undefined
                              ? value
                                ? '1'
                                : '0'
                              : undefined
                          }
                          onChange={(val) =>
                            onChange(val === '1' ? true : false)
                          }
                        >
                          <Inline gap="6">
                            <Radio
                              value="1"
                              id={`${idPrefix}-dpiit-certified-yes`}
                            >
                              <Text variation="b2" color="textMedium">
                                Yes
                              </Text>
                            </Radio>
                            <Radio
                              value="0"
                              id={`${idPrefix}-dpiit-certified-no`}
                            >
                              <Text variation="b2" color="textMedium">
                                No
                              </Text>
                            </Radio>
                          </Inline>
                        </RadioGroup>
                        {error?.message && (
                          <Text
                            marginTop="1"
                            borderRadius="md"
                            paddingY="0.5"
                            paddingX="1"
                            display="flex"
                            color="textError"
                            variation="c2"
                            bgColor="surfaceErrorLowest"
                          >
                            {error?.message}
                          </Text>
                        )}
                      </Box>
                      {value ? (
                        <Box flex="1" marginRight="5">
                          <Controller
                            control={vendorPrimaryDetailsFormControl}
                            name="dpiitNumber"
                            render={(props) => {
                              const {
                                field: { onChange, onBlur, value },
                                fieldState: { error },
                              } = props;
                              return (
                                <TextInput
                                  id={`${idPrefix}-primary-details-enter-dpiit-number`}
                                  label={'DPIIT Number'}
                                  fullWidth
                                  isRequired
                                  placeholder="Enter DPIIT Number"
                                  onChange={onChange}
                                  onBlur={onBlur}
                                  errorMessage={error?.message}
                                  defaultValue={
                                    value !== undefined
                                      ? String(value)
                                      : undefined
                                  }
                                />
                              );
                            }}
                          />
                        </Box>
                      ) : (
                        <Box flex="1" marginRight="5" />
                      )}
                      <Box flex="1" marginRight="5" />
                    </Inline>
                    {value && (
                      <Box width="fitContent">
                        <Controller
                          control={vendorPrimaryDetailsFormControl}
                          name="dpiitCert"
                          render={(props) => {
                            const {
                              field: { onChange, value },
                            } = props;
                            return (
                              <>
                                <Text variation="c2" marginBottom="1">
                                  DPIIT Certificate
                                </Text>
                                <UploadDocument
                                  id={`${idPrefix}-primary-details-dpiit-certificate`}
                                  showDownloadButton={false}
                                  onClickSelectDocument={(
                                    fileBlobValue,
                                    documentInfo
                                  ) =>
                                    onChange({
                                      fileBlobValue: fileBlobValue,
                                      newDocument: true,
                                      ...documentInfo,
                                    })
                                  }
                                  documentInfo={value}
                                  rightIcon={(props, onClick) => (
                                    <DeleteIcon
                                      {...props}
                                      id={`${idPrefix}-primary-details-delete-selected-dpiit-certificate`}
                                      onClick={() => {
                                        onClick();
                                        onChange(undefined);
                                      }}
                                      color="iconError"
                                      cursor="pointer"
                                    />
                                  )}
                                />
                              </>
                            );
                          }}
                        />
                      </Box>
                    )}
                  </>
                );
              }}
            />
          </Box>
          <Box marginBottom="6">
            <Controller
              control={vendorPrimaryDetailsFormControl}
              name="isMsmeRegistered"
              render={(props) => {
                const {
                  field: { onChange, value },
                  fieldState: { error },
                } = props;
                return (
                  <>
                    <Inline marginBottom="3">
                      <Box flex="1" marginRight="5">
                        <Text variation="b2">
                          Is MSME Registered?
                          <Text marginLeft="0.5" as="span" color="textError">
                            *
                          </Text>
                        </Text>
                        <RadioGroup
                          value={
                            value !== undefined
                              ? value
                                ? '1'
                                : '0'
                              : undefined
                          }
                          onChange={(val) =>
                            onChange(val === '1' ? true : false)
                          }
                        >
                          <Inline gap="6">
                            <Radio
                              value="1"
                              id={`${idPrefix}-msme-registered-yes`}
                            >
                              <Text variation="b2" color="textMedium">
                                Yes
                              </Text>
                            </Radio>
                            <Radio
                              value="0"
                              id={`${idPrefix}-msme-registered-no`}
                            >
                              <Text variation="b2" color="textMedium">
                                No
                              </Text>
                            </Radio>
                          </Inline>
                        </RadioGroup>
                        {error?.message && (
                          <Text
                            marginTop="1"
                            borderRadius="md"
                            paddingY="0.5"
                            paddingX="1"
                            display="flex"
                            color="textError"
                            variation="c2"
                            bgColor="surfaceErrorLowest"
                          >
                            {error?.message}
                          </Text>
                        )}
                      </Box>
                      {value ? (
                        <Box flex="1" marginRight="5">
                          <Controller
                            control={vendorPrimaryDetailsFormControl}
                            name="msmeNumber"
                            render={(props) => {
                              const {
                                field: { onChange, onBlur, value },
                                fieldState: { error },
                              } = props;
                              return (
                                <TextInput
                                  id={`${idPrefix}-primary-details-enter-msme-registration-number`}
                                  label={'MSME Registration Number'}
                                  fullWidth
                                  isRequired
                                  placeholder="E.g. UDYAM-AP-00-0123456"
                                  onChange={onChange}
                                  onBlur={onBlur}
                                  errorMessage={error?.message}
                                  defaultValue={
                                    value !== undefined
                                      ? String(value)
                                      : undefined
                                  }
                                />
                              );
                            }}
                          />
                        </Box>
                      ) : (
                        <Box flex="1" marginRight="5" />
                      )}
                      <Box flex="1" marginRight="5" />
                    </Inline>
                    {value && (
                      <Box width="fitContent">
                        <Controller
                          control={vendorPrimaryDetailsFormControl}
                          name="msmeCert"
                          render={(props) => {
                            const {
                              field: { onChange, value },
                            } = props;
                            return (
                              <>
                                <Text variation="c2" marginBottom="1">
                                  MSME Certificate
                                </Text>
                                <UploadDocument
                                  id={`${idPrefix}-primary-details-msme-certificate`}
                                  showDownloadButton={false}
                                  onClickSelectDocument={(
                                    fileBlobValue,
                                    documentInfo
                                  ) =>
                                    onChange({
                                      fileBlobValue: fileBlobValue,
                                      newDocument: true,
                                      ...documentInfo,
                                    })
                                  }
                                  documentInfo={value}
                                  rightIcon={(props, onClick) => (
                                    <DeleteIcon
                                      {...props}
                                      id={`${idPrefix}-primary-details-delete-selected-msme-certificate`}
                                      onClick={() => {
                                        onClick();
                                        onChange(undefined);
                                      }}
                                      color="iconError"
                                      cursor="pointer"
                                    />
                                  )}
                                />
                              </>
                            );
                          }}
                        />
                      </Box>
                    )}
                  </>
                );
              }}
            />
          </Box>
        </Box>
      </Box>
    );
  }, [
    vendorPrimaryDetailsFormControl,
    vendorRegistrationTypeOptions,
    vendorTypeOptions,
    vendorBranchOptions,
    scrollViewRef,
  ]);

  const rendorShowAddresses = useMemo(() => {
    return (
      <Stack
        width={vendorAddresses?.length === 0 ? 'full' : 'fitContent'}
        minHeight="screen"
        justifyContent={vendorAddresses?.length === 0 ? 'center' : undefined}
        alignItems={vendorAddresses?.length === 0 ? 'center' : undefined}
      >
        {vendorAddresses?.length > 0 ? (
          vendorAddresses.map((vendorAddress, index) => {
            let showDeleteIcon = true;
            if (index === 0 && vendorAddresses.length === 1) {
              showDeleteIcon = false;
            }
            return (
              <>
                <ConfirmationDialog
                  id={`${idPrefix}-delete-vendor-address`}
                  title="Delete Address"
                  description={`You want to delete this address:\n ${`${vendorAddress.registered_address.address_line_1} ${vendorAddress.registered_address.address_line_2} ${vendorAddress.registered_address.city}, ${vendorAddress.registered_address.state} ${vendorAddress.registered_address.pincode}`}`}
                  isVisible={isDeleteAddressModalVisible}
                  confirmLabel="Delete"
                  onCancel={() => {
                    currentAddressIndex.current = -1;
                    setIsDeleteAddressModalVisible(false);
                  }}
                  onConfirm={() => {
                    deleteVendorAddress(index);
                    currentAddressIndex.current = index;
                    setIsDeleteAddressModalVisible(false);
                  }}
                  toggleButtons
                />
                <Stack
                  backgroundColor="surfaceNeutralLowest"
                  paddingX="3"
                  paddingBottom="3"
                  rounded="sm"
                  marginBottom="6"
                >
                  <Inline
                    justifyContent="between"
                    paddingY="2"
                    borderBottomWidth="1"
                    borderColor="borderOutline"
                    alignItems="center"
                  >
                    <Stack>
                      <Text variation="t1">
                        {vendorAddress.contact_person_details.name}
                      </Text>
                      <Text variation="b2">
                        {vendorAddress.contact_person_details.designation}
                      </Text>
                    </Stack>
                    <Inline gap="4" alignItems="center">
                      {showDeleteIcon ? (
                        <DeleteIcon
                          height="4"
                          width="4"
                          color="iconError"
                          onClick={() => {
                            if (vendorAddresses.length === 1) {
                              toastQueue.add(
                                {
                                  title:
                                    'The vendor should have atleast one address',
                                  type: 'custom',
                                },
                                {
                                  timeout: 2000,
                                }
                              );
                              return;
                            }
                            currentAddressIndex.current = index;
                            setIsDeleteAddressModalVisible(true);
                          }}
                          cursor="pointer"
                        />
                      ) : null}
                      <Button
                        title="Edit"
                        leftIcon={(props) => <EditIcon {...props} />}
                        type="text"
                        onClick={() => {
                          currentAddressIndex.current = index;
                          modalState.open();
                        }}
                      />
                    </Inline>
                  </Inline>
                  <Stack marginTop="4">
                    <Inline>
                      <Stack style={{ width: 200 }}>
                        <Text variation="c2" color="textLow">
                          GST Number
                        </Text>
                        <Text variation="b2">
                          {vendorAddress.gst_number || '-'}
                        </Text>
                      </Stack>
                      <Stack marginLeft="5.5" style={{ width: 200 }}>
                        <Text variation="c2" color="textLow">
                          Mobile Number
                        </Text>
                        <Text variation="b2">
                          {vendorAddress.contact_person_details.mobile_number}
                        </Text>
                      </Stack>
                      <Stack marginLeft="5.5" style={{ width: 200 }}>
                        <Text variation="c2" color="textLow">
                          Email ID
                        </Text>
                        <Text variation="b2">
                          {vendorAddress.contact_person_details.email}
                        </Text>
                      </Stack>
                    </Inline>
                    <Inline marginTop="3">
                      <Stack style={{ width: 200 }}>
                        <Text variation="c2" color="textLow">
                          City
                        </Text>
                        <Text variation="b2">
                          {vendorAddress.registered_address.city}
                        </Text>
                      </Stack>
                      <Stack marginLeft="5.5" style={{ width: 400 }}>
                        <Text variation="c2" color="textLow">
                          Address
                        </Text>
                        <Text variation="b2">{`${vendorAddress.registered_address.address_line_1} ${vendorAddress.registered_address.address_line_2} ${vendorAddress.registered_address.city}, ${vendorAddress.registered_address.state} ${vendorAddress.registered_address.pincode}`}</Text>
                      </Stack>
                    </Inline>
                  </Stack>
                </Stack>
              </>
            );
          })
        ) : (
          <EmptyState
            title={'Add Vendor Address'}
            subText="The vendor must have atleast one address"
            renderButton={() => (
              <Button
                title="Add address"
                type="outlined"
                leftIcon={(props) => <PlusIcon {...props} />}
                onClick={() => {
                  modalState.open();
                }}
              />
            )}
          />
        )}
        {vendorAddresses.length > 0 ? (
          <Box backgroundColor="borderSeparator" style={{ height: 1 }} />
        ) : null}
        {vendorAddresses.length > 0 ? (
          <Box marginTop="4">
            <Button
              title="Add another address"
              type="outlined"
              leftIcon={(props) => <PlusIcon {...props} />}
              onClick={() => {
                modalState.open();
              }}
            />
          </Box>
        ) : null}
      </Stack>
    );
  }, [
    currentAddressIndex,
    deleteVendorAddress,
    isDeleteAddressModalVisible,
    modalState,
    vendorAddresses,
  ]);

  const renderShowPaymentDetails = useMemo(() => {
    return (
      <Stack minHeight="screen">
        {vendorAddresses?.map((vendorAddress, index) => {
          return (
            <Stack
              padding="3"
              rounded="sm"
              marginBottom="6"
              borderWidth="1"
              borderColor="borderOutline"
            >
              <Inline alignItems="center" justifyContent="between">
                <Stack>
                  <Text variation="t3">Address</Text>
                  <Text
                    variation="b2"
                    marginTop="1"
                  >{`${vendorAddress.registered_address.address_line_1} ${vendorAddress.registered_address.address_line_2} ${vendorAddress.registered_address.city}, ${vendorAddress.registered_address.state} ${vendorAddress.registered_address.pincode}`}</Text>
                </Stack>
                {vendorAddress.payment_details ? (
                  <Button
                    title="Edit"
                    leftIcon={(props) => <EditIcon {...props} />}
                    type="text"
                    onClick={() => {
                      currentAddressIndex.current = index;
                      modalState.open();
                    }}
                  />
                ) : null}
              </Inline>
              <Stack marginTop="4">
                <Text variation="t3">Payment Details</Text>
                {vendorAddress.payment_details ? (
                  <Stack marginTop="3">
                    <Inline
                      borderBottomWidth="1"
                      borderColor="borderSeparator"
                      paddingBottom="2"
                    >
                      <Stack style={{ width: 200 }}>
                        <Text variation="c2" color="textLow">
                          Bank Name
                        </Text>
                        <Text variation="b2" marginTop="0.5">
                          {vendorAddress.payment_details?.bank_name || '-'}
                        </Text>
                      </Stack>
                      <Stack style={{ width: 200 }} marginLeft="5">
                        <Text variation="c2" color="textLow">
                          Account Type
                        </Text>
                        <Text variation="b2" marginTop="0.5">
                          {vendorAddress.payment_details?.account_type || '-'}
                        </Text>
                      </Stack>
                      <Stack style={{ width: 200 }} marginLeft="5">
                        <Text variation="c2" color="textLow">
                          Swift Code
                        </Text>
                        <Text variation="b2" marginTop="0.5">
                          {vendorAddress.payment_details?.swift_code || '-'}
                        </Text>
                      </Stack>
                      <Stack style={{ width: 200 }} marginLeft="5">
                        <Text variation="c2" color="textLow">
                          MICR
                        </Text>
                        <Text variation="b2" marginTop="0.5">
                          {vendorAddress.payment_details?.micr || '-'}
                        </Text>
                      </Stack>
                    </Inline>
                    <Inline
                      borderBottomWidth="1"
                      borderColor="borderSeparator"
                      marginTop="2"
                      paddingBottom="2"
                    >
                      <Stack style={{ width: 200 }}>
                        <Text variation="c2" color="textLow">
                          Account Holder Name
                        </Text>
                        <Text variation="b2" marginTop="0.5">
                          {vendorAddress.payment_details?.account_holder_name ||
                            '-'}
                        </Text>
                      </Stack>
                      <Stack style={{ width: 200 }} marginLeft="5">
                        <Text variation="c2" color="textLow">
                          Account Number
                        </Text>
                        <Text variation="b2" marginTop="0.5">
                          {vendorAddress.payment_details?.account_number || '-'}
                        </Text>
                      </Stack>
                      <Stack style={{ width: 200 }} marginLeft="5">
                        <Text variation="c2" color="textLow">
                          IFSC
                        </Text>
                        <Text variation="b2" marginTop="0.5">
                          {vendorAddress.payment_details?.ifsc_code || '-'}
                        </Text>
                      </Stack>
                    </Inline>
                    <Stack marginTop="2">
                      <Text variation="c2" color="textLow" marginBottom="0.5">
                        Copy of Cancelled Cheque
                      </Text>
                      <Inline>
                        <UploadDocument
                          id={`${idPrefix}-cancelled-cheque-upload-button`}
                          type="secondary"
                          showDownloadButton={true}
                          documentInfo={{
                            imgSrc: vendorAddress.cancelledCheque?.imgSrc,
                            name: vendorAddress.cancelledCheque?.name,
                            size: vendorAddress.cancelledCheque?.size || 0,
                            type: vendorAddress.cancelledCheque?.type,
                          }}
                        />
                      </Inline>
                    </Stack>
                  </Stack>
                ) : (
                  <Button
                    title="Fill Details"
                    type="outlined"
                    onClick={() => {
                      currentAddressIndex.current = index;
                      modalState.open();
                    }}
                    marginTop="1"
                    width="fitContent"
                  />
                )}
              </Stack>
            </Stack>
          );
        })}
      </Stack>
    );
  }, [currentAddressIndex, modalState, vendorAddresses]);

  const renderVendorLinksForm = useMemo(() => {
    return (
      <Box key="links" height="screen">
        <Box display="flex" flexDirection="row" marginBottom="6">
          <Box flex="1" marginRight="5">
            <Controller
              control={vendorLinkFormControl}
              name="website"
              render={(props) => {
                const {
                  field: { onChange, onBlur, value },
                  fieldState: { error },
                } = props;
                return (
                  <TextInput
                    id={`${idPrefix}-link-details-enter-website-link`}
                    label={'Website Link'}
                    fullWidth
                    placeholder="Enter vendor website link"
                    onChange={onChange}
                    onBlur={onBlur}
                    errorMessage={error?.message}
                    defaultValue={
                      value !== undefined ? String(value) : undefined
                    }
                    removeMinWidth
                  />
                );
              }}
            />
          </Box>
          <Box flex="1" marginRight="5">
            <Controller
              control={vendorLinkFormControl}
              name="facebook"
              render={(props) => {
                const {
                  field: { onChange, onBlur, value },
                  fieldState: { error },
                } = props;
                return (
                  <TextInput
                    id={`${idPrefix}-link-details-enter-facebook-link`}
                    label={'Facebook Page Link'}
                    fullWidth
                    placeholder="Enter vendor facebook page link"
                    onChange={onChange}
                    onBlur={onBlur}
                    errorMessage={error?.message}
                    defaultValue={
                      value !== undefined ? String(value) : undefined
                    }
                    removeMinWidth
                  />
                );
              }}
            />
          </Box>
          <Box flex="1" marginRight="5">
            <Controller
              control={vendorLinkFormControl}
              name="instagram"
              render={(props) => {
                const {
                  field: { onChange, onBlur, value },
                  fieldState: { error },
                } = props;
                return (
                  <TextInput
                    id={`${idPrefix}-link-details-enter-instagram-link`}
                    label={'Instagram Page Link'}
                    fullWidth
                    placeholder="Enter vendor instagram page link"
                    onChange={onChange}
                    onBlur={onBlur}
                    errorMessage={error?.message}
                    defaultValue={
                      value !== undefined ? String(value) : undefined
                    }
                    removeMinWidth
                  />
                );
              }}
            />
          </Box>
        </Box>
        <Box display="flex" flexDirection="row" marginBottom="6">
          <Box flex="1" marginRight="5">
            <Controller
              control={vendorLinkFormControl}
              name="linkedIn"
              render={(props) => {
                const {
                  field: { onChange, onBlur, value },
                  fieldState: { error },
                } = props;
                return (
                  <TextInput
                    id={`${idPrefix}-link-details-enter-linkedin-link`}
                    label={'LinkedIn Profile Link'}
                    fullWidth
                    placeholder="Enter vendor LinkedIn profile link"
                    onChange={onChange}
                    onBlur={onBlur}
                    errorMessage={error?.message}
                    defaultValue={
                      value !== undefined ? String(value) : undefined
                    }
                    removeMinWidth
                  />
                );
              }}
            />
          </Box>
          <Box flex="1" marginRight="5">
            <Controller
              control={vendorLinkFormControl}
              name="twitter"
              render={(props) => {
                const {
                  field: { onChange, onBlur, value },
                  fieldState: { error },
                } = props;
                return (
                  <TextInput
                    id={`${idPrefix}-link-details-enter-twitter-link`}
                    label={'Twitter Page Link'}
                    fullWidth
                    placeholder="Enter vendor twitter page link"
                    onChange={onChange}
                    onBlur={onBlur}
                    errorMessage={error?.message}
                    defaultValue={
                      value !== undefined ? String(value) : undefined
                    }
                    removeMinWidth
                  />
                );
              }}
            />
          </Box>
          <Box flex="1" marginRight="5">
            <Controller
              control={vendorLinkFormControl}
              name="others"
              render={(props) => {
                const {
                  field: { onChange, onBlur, value },
                  fieldState: { error },
                } = props;
                return (
                  <TextInput
                    id={`${idPrefix}-link-details-enter-other-link`}
                    label={'Any Other Link'}
                    fullWidth
                    placeholder="Enter any other relevant link"
                    onChange={onChange}
                    onBlur={onBlur}
                    errorMessage={error?.message}
                    defaultValue={
                      value !== undefined ? String(value) : undefined
                    }
                    removeMinWidth
                  />
                );
              }}
            />
          </Box>
        </Box>
      </Box>
    );
  }, [vendorLinkFormControl]);

  const renderFormFillingStates = useMemo(() => {
    switch (formFillingStates[currentFormFillingStateIndex].stateId) {
      case 'primaryDetails':
        return renderVendorPrimaryDetailsForm;
      case 'addressDetails':
        return rendorShowAddresses;
      case 'paymentDetails':
        return renderShowPaymentDetails;
      case 'links':
        return renderVendorLinksForm;
      default:
        return null;
    }
  }, [
    currentFormFillingStateIndex,
    formFillingStates,
    renderVendorLinksForm,
    renderVendorPrimaryDetailsForm,
    rendorShowAddresses,
    renderShowPaymentDetails,
  ]);

  const submitButtonLabel = useMemo(() => {
    let label = 'Next';
    if (currentFormFillingStateIndex === formFillingStates?.length - 1) {
      label = 'Save Vendor';
    }
    return label;
  }, [currentFormFillingStateIndex, formFillingStates?.length]);

  const onClickCloseModal = () => {
    currentAddressIndex.current = -1;
    modalState.close();
  };

  return (
    <Box>
      <Modal
        isOpen={modalState.isOpen}
        title={`Add New Address`}
        status={'success'}
        onClose={modalState.close}
        size="full"
        placement="right"
        showDefaultHeader={false}
      >
        {currentFormFillingStateIndex === 1 ? (
          <AddOrEditVendorAddress
            onClickClose={onClickCloseModal}
            submitDetails={validatedVendorAddressDetails}
            vendorAddressDetails={
              currentAddressIndex.current >= 0
                ? vendorAddresses[currentAddressIndex.current]
                : undefined
            }
          />
        ) : (
          <AddOrEditVendorPaymentDetails
            onClickClose={onClickCloseModal}
            submitDetails={validatedVendorPaymentDetails}
            vendorAddressDetails={
              currentAddressIndex.current >= 0
                ? vendorAddresses[currentAddressIndex.current]
                : undefined
            }
            vendorAddresses={vendorAddresses.filter(
              (vendorAddress, index) =>
                currentAddressIndex.current !== index &&
                Boolean(vendorAddress.payment_details)
            )}
          />
        )}
      </Modal>
      <FullScreenHeader
        headingText={
          flowType === 'edit' ? 'Edit Vendor Profile' : 'Add New Vendor'
        }
        renderRight={renderFormFillingStatesIndicator}
        onClickCross={goBack}
        borderBottomWidth="1"
        borderColor="borderSeparator"
        position="sticky"
        top="0"
        zIndex="50"
      />
      <Stack
        paddingX="5"
        paddingTop="4"
        paddingBottom="4"
        height="fitContent"
        flex="1"
      >
        {flowType === 'edit' && renderVendorDetailsFormStateNavitgation}
        {renderFormFillingStates}
      </Stack>
      {flowType === 'edit' ? (
        <Inline
          justifyContent="end"
          backgroundColor="surfaceDefault"
          paddingY="2"
          paddingX="5"
          borderTopWidth="1"
          borderColor="borderSeparator"
          position="sticky"
          bottom="0"
          zIndex="50"
        >
          <Button
            id={`${idPrefix}-cancel-edit`}
            title={'Cancel'}
            type="outlined"
            leftIcon={(props) => <CrossIcon {...props} />}
            marginRight="2"
            onClick={goBack}
            state={createOrUpdateVendorApiLoading ? 'disabled' : undefined}
          />
          <Button
            id={`${idPrefix}-save-edit`}
            title={'Save'}
            leftIcon={(props) => <CheckIcon {...props} />}
            type="filled"
            state={createOrUpdateVendorApiLoading ? 'loading' : undefined}
            onClick={updateVendorDetails}
          />
        </Inline>
      ) : (
        <Box
          display="flex"
          justifyContent="end"
          backgroundColor="surfaceDefault"
          paddingY="2"
          paddingX="5"
          borderTopWidth="1"
          borderColor="borderSeparator"
          position="sticky"
          bottom="0"
          zIndex="50"
        >
          <Box marginRight="2">
            <Button
              id={`${idPrefix}-close-or-previous-form`}
              title={currentFormFillingStateIndex === 0 ? 'Cancel' : 'Previous'}
              type="outlined"
              leftIcon={(props) =>
                currentFormFillingStateIndex === 0 ? (
                  <CrossIcon {...props} />
                ) : (
                  <RightCaratIcon {...props} rotate="180" />
                )
              }
              onClick={selectPreviousFormState}
              state={createOrUpdateVendorApiLoading ? 'disabled' : undefined}
            />
          </Box>
          <Box>
            <Button
              id={`${idPrefix}-next-or-save-vendor`}
              title={submitButtonLabel}
              leftIcon={
                currentFormFillingStateIndex === formFillingStates.length - 1
                  ? (props) => <CheckIcon {...props} />
                  : undefined
              }
              rightIcon={
                currentFormFillingStateIndex !== formFillingStates.length - 1
                  ? (props) => (
                      <RightCaratIcon size="2.5" color="iconOnSurface" />
                    )
                  : undefined
              }
              type="filled"
              state={createOrUpdateVendorApiLoading ? 'loading' : undefined}
              onClick={validateFormAndNext}
            />
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default AddOrEditVendor;
