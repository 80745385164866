/* eslint-disable @typescript-eslint/no-explicit-any */
import { useState, useMemo } from 'react';
import { Controller, useForm } from 'react-hook-form';

import {
  Box,
  FullScreenHeader,
  TextInput,
  Stack,
  Text,
  Button,
  Inline,
  UploadDocument,
  DeleteIcon,
  Radio,
  RadioGroup,
  SpinnerIcon,
  Select,
} from '@nbfc-expense-tool/ui';
import { zodResolver } from '@hookform/resolvers/zod';
import {
  VendorAddressStateType,
  VendorPaymentDetailFormInputs,
  vendorPaymentDetailsFormSchema,
} from '@nbfc-expense-tool/data-store/dashboard';
import { debounce, getIfscDetails } from '@nbfc-expense-tool/data-store/utils';

type AddNewItemProps = {
  onClickClose: () => void;
  vendorAddressDetails?: VendorAddressStateType;
  submitDetails: (values: VendorPaymentDetailFormInputs) => void;
  vendorAddresses?: VendorAddressStateType[];
};

const idPrefix = 'add-or-edit-vendor';

export const AddOrEditVendorPaymentDetails: React.FC<AddNewItemProps> = ({
  onClickClose,
  submitDetails,
  vendorAddressDetails,
  vendorAddresses,
}) => {
  const isEditFlow = Boolean(vendorAddressDetails?.payment_details);
  const [isFetchingIfscForPaymentDetails, setIsFetchingIfscForPaymentDetails] =
    useState<boolean>(false);
  const [selectedVendorAddress, setSelectedVendorAddress] = useState<
    number | null
  >(null);

  const vendorAddressOptions = useMemo(() => {
    const vendorAddressOptionsList = vendorAddresses?.map(
      (vendorAddress, index) => {
        return {
          label: `${vendorAddress.payment_details?.account_holder_name}`,
          value: `${index}`,
        };
      }
    );
    return vendorAddressOptionsList;
  }, [vendorAddresses]);

  const { control, handleSubmit, getValues, setValue, clearErrors, setError } =
    useForm<VendorPaymentDetailFormInputs>({
      resolver: zodResolver(vendorPaymentDetailsFormSchema),
      defaultValues: {
        accountHolderName:
          vendorAddressDetails?.payment_details?.account_holder_name ||
          undefined,
        accountNumber:
          vendorAddressDetails?.payment_details?.account_number || undefined,
        accountType:
          vendorAddressDetails?.payment_details?.account_type || undefined,
        ifscCode: vendorAddressDetails?.payment_details?.ifsc_code || undefined,
        bankName: vendorAddressDetails?.payment_details?.bank_name || undefined,
        branchName:
          vendorAddressDetails?.payment_details?.branch_name || undefined,
        micrCode: vendorAddressDetails?.payment_details?.micr || undefined,
        swiftCode:
          vendorAddressDetails?.payment_details?.swift_code || undefined,
        cancelledChequeCopy: vendorAddressDetails?.cancelledCheque
          ? {
              imgSrc: vendorAddressDetails?.cancelledCheque?.imgSrc,
              name: vendorAddressDetails?.cancelledCheque?.name,
              size: vendorAddressDetails?.cancelledCheque?.size || 0,
              type: vendorAddressDetails?.cancelledCheque?.type,
              newDocument: false,
            }
          : undefined,
      },
    });

  const handleFetchIfscDetailsOnChange = debounce(async (e: string) => {
    if (e.length === 11) {
      setIsFetchingIfscForPaymentDetails(true);
      try {
        const res: any = await getIfscDetails(e);
        setValue('bankName', res.data.bank_name ?? '');
        setValue('branchName', res.data.bank_branch ?? '');
        setValue('micrCode', res.data.bank_micr ?? '');
        setValue('swiftCode', res.data.bank_swift ?? '');
        clearErrors('ifscCode');
      } catch (e: any) {
        setError('ifscCode', {
          type: 'manual',
          message: e.message ?? 'Something went wrong, Please try again',
        });
      } finally {
        setIsFetchingIfscForPaymentDetails(false);
      }
    }
  }, 200);

  const submitFormDetails = (values: VendorPaymentDetailFormInputs) => {
    const cancelledCheque = getValues('cancelledChequeCopy');
    values.cancelledChequeCopy = cancelledCheque;
    submitDetails({
      ...values,
    });
    onClickClose();
  };

  return (
    <Box>
      <FullScreenHeader
        headingText={
          isEditFlow ? 'Edit Payment Details' : 'Add Payment Details'
        }
        onClickCross={onClickClose}
        borderBottomWidth="1"
        borderColor="borderSeparator"
        shadowLevel="s1"
        position="sticky"
        top="0"
        zIndex="50"
      />
      <Stack
        paddingX="5"
        paddingTop="5"
        display="flex"
        flex="1"
        minHeight="screen"
        overflow="auto"
        paddingBottom="20"
      >
        <Stack marginBottom="3">
          <Text variation="t3">Address</Text>
          <Text
            variation="b2"
            marginTop="1"
          >{`${vendorAddressDetails?.registered_address.address_line_1} ${vendorAddressDetails?.registered_address.address_line_2} ${vendorAddressDetails?.registered_address.city} ${vendorAddressDetails?.registered_address.state} ${vendorAddressDetails?.registered_address.pincode}`}</Text>
        </Stack>
        <Box key="paymentDetails" height="screen">
          <Text variation="t3" marginBottom="3">
            Payment Details
          </Text>
          {!isEditFlow &&
          vendorAddressOptions?.length &&
          vendorAddresses?.length ? (
            <>
              <Text variation="c2">Autofill Details from</Text>
              <Box marginBottom="5" marginTop="0.5">
                <Select
                  id={`${idPrefix}-select-autofill-payment-detail`}
                  label="Select Payment Details"
                  value={selectedVendorAddress?.toString()}
                  actionBtnTitle="Select"
                  onSave={(option) => {
                    if (option?.value) {
                      setSelectedVendorAddress(Number(option?.value));
                      const autofillAddress =
                        vendorAddresses[Number(option?.value)];
                      if (autofillAddress.payment_details) {
                        setValue(
                          'accountHolderName',
                          autofillAddress.payment_details.account_holder_name
                        );
                        setValue(
                          'accountNumber',
                          autofillAddress.payment_details.account_number
                        );
                        setValue(
                          'accountType',
                          autofillAddress.payment_details.account_type
                        );
                        setValue(
                          'bankName',
                          autofillAddress.payment_details.bank_name
                        );
                        setValue(
                          'branchName',
                          autofillAddress.payment_details.branch_name
                        );
                        setValue(
                          'ifscCode',
                          autofillAddress.payment_details.ifsc_code
                        );
                        setValue(
                          'swiftCode',
                          autofillAddress.payment_details.swift_code
                        );
                        setValue(
                          'micrCode',
                          autofillAddress.payment_details.micr
                        );
                      }
                    }
                  }}
                  onCancel={() => setSelectedVendorAddress(null)}
                  options={vendorAddressOptions}
                />
              </Box>
            </>
          ) : null}
          <Box display="flex" flexDirection="row" marginBottom="6">
            <Box flex="1" marginRight="5">
              <Controller
                control={control}
                name="accountHolderName"
                render={(props) => {
                  const {
                    field: { onChange, onBlur, value },
                    fieldState: { error },
                  } = props;
                  return (
                    <TextInput
                      id={`${idPrefix}-payment-details-enter-account-holder-name`}
                      label={'Account Holder Name'}
                      fullWidth
                      isRequired
                      placeholder="Same as bank records"
                      onChange={onChange}
                      onBlur={onBlur}
                      errorMessage={error?.message}
                      value={value !== undefined ? String(value) : ''}
                      removeMinWidth
                    />
                  );
                }}
              />
            </Box>
            <Box flex="1" marginRight="5">
              <Controller
                control={control}
                name="accountNumber"
                render={(props) => {
                  const {
                    field: { onChange, onBlur, value },
                    fieldState: { error },
                  } = props;
                  return (
                    <TextInput
                      id={`${idPrefix}-payment-details-enter-account-number`}
                      label={'Account Number'}
                      fullWidth
                      isRequired
                      placeholder="Same as bank records"
                      onChange={onChange}
                      onBlur={onBlur}
                      errorMessage={error?.message}
                      value={value !== undefined ? String(value) : ''}
                      removeMinWidth
                    />
                  );
                }}
              />
            </Box>
            <Box flex="1" marginRight="5">
              <Controller
                control={control}
                name="accountType"
                render={(props) => {
                  const {
                    field: { onChange, value },
                    fieldState: { error },
                  } = props;
                  return (
                    <Box>
                      <Text variation="b2">
                        Account Type?
                        <Text marginLeft="0.5" as="span" color="textError">
                          *
                        </Text>
                      </Text>
                      <RadioGroup
                        aria-label="Choose a date"
                        value={value}
                        onChange={onChange}
                      >
                        <Inline>
                          <Radio
                            value="current"
                            id={`${idPrefix}-account-type-current`}
                          >
                            <Text variation="b2" color="textMedium">
                              Current
                            </Text>
                          </Radio>
                          <Radio
                            value="saving"
                            id={`${idPrefix}-account-type-saving`}
                          >
                            <Text variation="b2" color="textMedium">
                              Savings
                            </Text>
                          </Radio>
                        </Inline>
                      </RadioGroup>
                      {error?.message && (
                        <Text
                          marginTop="1"
                          borderRadius="md"
                          paddingY="0.5"
                          paddingX="1"
                          display="flex"
                          color="textError"
                          variation="c2"
                          bgColor="surfaceErrorLowest"
                        >
                          {error?.message}
                        </Text>
                      )}
                    </Box>
                  );
                }}
              />
            </Box>
          </Box>
          <Box display="flex" flexDirection="row" marginBottom="6">
            <Box flex="1" marginRight="5">
              <Controller
                control={control}
                name="ifscCode"
                render={(props) => {
                  const {
                    field: { onChange, onBlur, value },
                    fieldState: { error },
                  } = props;
                  return (
                    <TextInput
                      id={`${idPrefix}-payment-details-enter-ifsc-code`}
                      label={'IFSC Code'}
                      fullWidth
                      isRequired
                      maxLength={11}
                      placeholder="Must be 11 characters long"
                      rightIcon={
                        isFetchingIfscForPaymentDetails
                          ? () => <SpinnerIcon size="3" color="iconMedium" />
                          : undefined
                      }
                      onChange={(e) => {
                        handleFetchIfscDetailsOnChange(e);
                        onChange(e);
                      }}
                      onBlur={onBlur}
                      errorMessage={error?.message}
                      value={value !== undefined ? String(value) : ''}
                      removeMinWidth
                      fieldInfo="Bank branch identifier in India"
                      isDisabled={isFetchingIfscForPaymentDetails}
                    />
                  );
                }}
              />
            </Box>
            <Box flex="1" marginRight="5">
              <Controller
                control={control}
                name="bankName"
                render={(props) => {
                  const {
                    field: { onChange, onBlur, value },
                    fieldState: { error },
                  } = props;
                  return (
                    <TextInput
                      id={`${idPrefix}-payment-details-enter-bank-name`}
                      label={'Bank Name'}
                      fullWidth
                      isRequired
                      placeholder="Enter Bank name"
                      onChange={onChange}
                      onBlur={onBlur}
                      errorMessage={error?.message}
                      value={value !== undefined ? String(value) : ''}
                      removeMinWidth
                      isDisabled={isFetchingIfscForPaymentDetails}
                    />
                  );
                }}
              />
            </Box>
            <Box flex="1" marginRight="5">
              <Controller
                control={control}
                name="branchName"
                render={(props) => {
                  const {
                    field: { onChange, onBlur, value },
                    fieldState: { error },
                  } = props;
                  return (
                    <TextInput
                      id={`${idPrefix}-payment-details-enter-branch-name`}
                      label={'Branch Name'}
                      fullWidth
                      placeholder="Enter Bank's branch name"
                      onChange={onChange}
                      onBlur={onBlur}
                      errorMessage={error?.message}
                      value={value !== undefined ? String(value) : ''}
                      removeMinWidth
                      isDisabled={isFetchingIfscForPaymentDetails}
                    />
                  );
                }}
              />
            </Box>
          </Box>
          <Box display="flex" flexDirection="row" marginBottom="6">
            <Box flex="1" marginRight="5">
              <Controller
                control={control}
                name="micrCode"
                render={(props) => {
                  const {
                    field: { onChange, onBlur, value },
                    fieldState: { error },
                  } = props;
                  return (
                    <TextInput
                      id={`${idPrefix}-payment-details-enter-micr-code`}
                      label={'MICR'}
                      fullWidth
                      description="It's printed at the bottom of the cheque"
                      placeholder="Must be 9 characters long"
                      onChange={onChange}
                      onBlur={onBlur}
                      errorMessage={error?.message}
                      value={value !== undefined ? String(value) : ''}
                      removeMinWidth
                      fieldInfo="Bank cheque processing code."
                      isDisabled={isFetchingIfscForPaymentDetails}
                    />
                  );
                }}
              />
            </Box>
            <Box flex="1" marginRight="5">
              <Controller
                control={control}
                name="swiftCode"
                render={(props) => {
                  const {
                    field: { onChange, onBlur, value },
                    fieldState: { error },
                  } = props;
                  return (
                    <TextInput
                      id={`${idPrefix}-payment-details-enter-swift-code`}
                      label={'Swift Code'}
                      placeholder="Should be 8-11 characters long"
                      fullWidth
                      onChange={onChange}
                      onBlur={onBlur}
                      errorMessage={error?.message}
                      value={value !== undefined ? String(value) : ''}
                      removeMinWidth
                      fieldInfo="International bank ID"
                      isDisabled={isFetchingIfscForPaymentDetails}
                    />
                  );
                }}
              />
            </Box>
            <Box flex="1" marginRight="5" />
          </Box>
          <Box width="fitContent" marginBottom="4">
            <Controller
              control={control}
              name="cancelledChequeCopy"
              render={(props) => {
                const {
                  field: { onChange, value },
                  fieldState: { error },
                } = props;
                return (
                  <>
                    <Text variation="c2" marginBottom="1">
                      Copy of Cancelled Cheque
                      <Text marginLeft="0.5" as="span" color="textError">
                        *
                      </Text>
                    </Text>
                    <UploadDocument
                      id={`${idPrefix}-payment-details-cancelled-cheque`}
                      showDownloadButton={false}
                      onClickSelectDocument={(fileBlobValue, documentInfo) =>
                        onChange({
                          fileBlobValue: fileBlobValue,
                          newDocument: true,
                          ...documentInfo,
                        })
                      }
                      documentInfo={value}
                      handleTempUpload
                      rightIcon={(props, onClick) => (
                        <DeleteIcon
                          {...props}
                          id={`${idPrefix}-payment-details-delete-selected-cancelled-cheque`}
                          onClick={() => {
                            onClick();
                            onChange(undefined);
                          }}
                          color="iconError"
                          cursor="pointer"
                        />
                      )}
                    />
                    {error?.message && (
                      <Text
                        marginTop="1"
                        borderRadius="md"
                        paddingY="0.5"
                        paddingX="1"
                        display="flex"
                        color="textError"
                        variation="c2"
                        bgColor="surfaceErrorLowest"
                      >
                        {error?.message}
                      </Text>
                    )}
                  </>
                );
              }}
            />
          </Box>
        </Box>
      </Stack>
      <Box
        width={'auto'}
        display={'flex'}
        alignItems={'center'}
        justifyContent="end"
        backgroundColor="surfaceDefault"
        paddingY="2"
        paddingX="5"
        borderTopWidth="1"
        borderColor="borderSeparator"
        position="sticky"
        bottom="0"
      >
        <Box marginRight="2">
          <Button
            id={`${idPrefix}-cancel`}
            title="Cancel"
            type="outlined"
            onClick={onClickClose}
          />
        </Box>
        <Box>
          <Button
            id={`${idPrefix}-save`}
            onClick={handleSubmit(submitFormDetails)}
            title={'Save Details'}
            type="filled"
          />
        </Box>
      </Box>
    </Box>
  );
};
