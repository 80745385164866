/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  Box,
  DateSelect,
  EmptyState,
  FileIcon,
  Inline,
  MultiSelect,
  RightCaratIcon,
  SearchIcon,
  SkeletonRows,
  SpinnerIcon,
  Stack,
  Tabs,
  Text,
  TextInput,
  Date as DateAndTime,
  Avatar,
  Amount,
  Button,
  ReturnIcon,
  Header,
  Modal,
  useOverlayTriggerState,
  CrossIcon,
  UploadDocument,
  DocumentFileType,
  DeleteIcon,
} from '@nbfc-expense-tool/ui';
import {
  Link,
  Outlet,
  useLocation,
  useNavigate,
  useParams,
} from 'react-router-dom';
import { Item } from 'react-stately';
import InvoicesCreatedByYou from './CreatedByYou';
import InvoicesWaitingForYourApproval from './WaitingForApproval';
import TypedInvoicesPage from './TypedInvoices';
import InvoiceDetailsPage from './InvoiceDetails';
import AddNewInvoice from './AddNewInvoice';
import React, { useMemo, useState } from 'react';
import {
  AddNewInvoiceButton,
  UploadInvoiceButton,
} from '../../Invoices/AddNewInvoiceButton';
import {
  useBranchesForFilter,
  useInvoices,
  useInvoicesOverview,
  useVendorsForFilter,
} from '@nbfc-expense-tool/data-store/dashboard';
import { InvoiceStatusTag } from '../../Invoices';
import {
  debounce,
  invoiceOcrDetection,
} from '@nbfc-expense-tool/data-store/utils';
import EmptyInvoiceList from '../../Invoices/EmptyInvoiceList';
import { quantityOptions } from '../../Invoices/AddNewInvoiceItemForm';

const initialOverview = [
  {
    id: 'approved',
    label: 'Approved',
    value: 0,
  },
  {
    id: 'under_review',
    label: 'Under Review',
    value: 0,
  },
  {
    id: 'returned',
    label: 'Returned',
    value: 0,
  },
  {
    id: 'rejected',
    label: 'Rejected',
    value: 0,
  },
];

const idPrefix = 'all-invoices';

export function AllInvoices() {
  const navigate = useNavigate();
  const {
    invoices,
    loading,
    perPage,
    totalInvoices,
    canGoNext,
    canGoBack,
    syncingData,
    currentPage,
    params,
    hasAppliedFilters,
    resetFilters,
    handlePageChange,
    handleDateChange,
    handleParamChange,
    exportExcelReport,
    reportLoading,
  } = useInvoices();

  const {
    status: overviewStatus,
    overview,
    handleDateChange: handleDateChangeForOverview,
    handleParamChange: handleParamChangeForOverview,
    resetFilters: resetFiltersForOverview,
  } = useInvoicesOverview();

  const resetAllFilters = () => {
    resetFilters();
    resetFiltersForOverview();
  };

  const { status, branches } = useBranchesForFilter();
  const { status: vendorStatus, vendors } = useVendorsForFilter();
  const branchForFilters = useMemo(() => {
    return branches?.map((b) => {
      return {
        label: `${b.name} - ${b.branch_code}`,
        value: `${b.id}`,
      };
    });
  }, [branches]);

  const vendorForFilters = useMemo(() => {
    return vendors?.map((b) => {
      return {
        label: b.name,
        value: `${b.id}`,
      };
    });
  }, [vendors]);

  const onInvoiceClickHandler = (ticketNumber: string) => {
    navigate(`/home/invoices/${ticketNumber}?from=all-invoices`);
  };

  return !invoices?.length && !hasAppliedFilters ? (
    <Stack paddingTop="10" alignItems="center" justifyContent="center">
      {loading === 'in_progress' ? (
        <Inline gap="4">
          <SpinnerIcon size="3" color="iconMedium" />
          <Text variation="b2">Loading...</Text>
        </Inline>
      ) : (
        <EmptyInvoiceList />
      )}
    </Stack>
  ) : (
    <Stack paddingTop="3.5" gap="6">
      <Inline justifyContent="between">
        <Inline gap="4">
          <DateSelect
            id={`${idPrefix}-select-date`}
            value={params.dateFilter}
            onSave={(option) => {
              handleDateChange(option);
              handleDateChangeForOverview(option);
            }}
          />
          <MultiSelect
            id={`${idPrefix}-select-branch`}
            label="Branch"
            actionBtnTitle="Show Results"
            value={params.branches}
            options={branchForFilters}
            loadingOptions={status === 'in_progress'}
            onSave={(values) => {
              handleParamChange('branches', values);
              handleParamChangeForOverview('branches', values);
            }}
          />
          <MultiSelect
            id={`${idPrefix}-select-vendors`}
            label="Vendors"
            actionBtnTitle="Show Results"
            value={params.vendors}
            options={vendorForFilters}
            loadingOptions={vendorStatus === 'in_progress'}
            onSave={(values) => {
              handleParamChange('vendors', values);
              handleParamChangeForOverview('vendors', values);
            }}
          />
        </Inline>
        <Button
          id={`${idPrefix}-export-excel-button`}
          title="Export To Excel"
          onClick={() => {
            exportExcelReport();
          }}
          type="outlined"
          state={reportLoading ? 'loading' : undefined}
          leftIcon={(props) => <ReturnIcon {...props} />}
        />
      </Inline>

      <Box width="fitContent">
        {overviewStatus === 'success' && overview?.length ? (
          <Inline as="ul" padding="1" backgroundColor="surfaceDefault">
            {initialOverview.map(({ id, label, value }, i) => {
              const data = overview.find((item) => item.invoice_status === id);
              return (
                <Inline
                  as="li"
                  key={id}
                  alignItems="center"
                  style={{
                    listStyleType: 'none',
                  }}
                >
                  <Inline
                    id={`${idPrefix}-${id}-type`}
                    as={Link}
                    to={`${id}?from=all-invoices`}
                    key={id}
                    paddingY="2"
                    paddingX="4"
                    gap="3"
                    style={{ color: 'inherit' }}
                    textDecoration="none"
                  >
                    <Box
                      size="1.5"
                      rounded="full"
                      backgroundColor={
                        id === 'approved'
                          ? 'surfaceSuccess'
                          : id === 'under_review'
                          ? 'surfacePending'
                          : id === 'returned'
                          ? 'surfaceReturned'
                          : id === 'rejected'
                          ? 'surfaceRejected'
                          : 'transparent'
                      }
                      style={{ marginTop: 6 }}
                    />
                    <Stack gap="1">
                      <Inline gap="1" alignItems="center">
                        <Text
                          variation="b2"
                          color="textMedium"
                          textTransform="capitalize"
                        >
                          {label}
                        </Text>
                        <RightCaratIcon size="2" color="iconMedium" />
                      </Inline>
                      <Text variation="h3" as="h3">
                        {data?.total_count || value}
                      </Text>
                    </Stack>
                  </Inline>
                  {i !== initialOverview.length - 1 ? (
                    <Box
                      width="px"
                      style={{ height: 40 }}
                      backgroundColor="borderSeparator"
                    />
                  ) : null}
                </Inline>
              );
            })}
          </Inline>
        ) : null}
      </Box>
      <Box
        rounded="md"
        borderWidth="1"
        paddingTop="2.5"
        borderColor="borderSeparator"
        backgroundColor="surfaceDefault"
      >
        <Stack gap="6">
          <Inline paddingX="2.5" alignItems="center" justifyContent="between">
            <Inline style={{ width: 324 }}>
              <TextInput
                minHeight="5"
                id={`${idPrefix}-search-input`}
                aria-label="search"
                placeholder="Search by invoice number or amount"
                leftIcon={(props) => <SearchIcon {...props} marginRight="1" />}
                fullWidth
                onChange={debounce((value) => {
                  handleParamChange('q', value);
                  handleParamChangeForOverview('q', value);
                })}
              />
            </Inline>
            <Inline alignItems="center" gap="6">
              {totalInvoices ? (
                <Text color="textMedium" variation="c1">
                  Showing{' '}
                  {currentPage === 1 ? 1 : (currentPage - 1) * perPage + 1}-
                  {Math.min(currentPage * perPage, totalInvoices)} of{' '}
                  {totalInvoices}
                </Text>
              ) : null}
              <Inline gap="2">
                <Box
                  id={`${idPrefix}-show-previous-page`}
                  as="button"
                  disabled={!canGoBack}
                  backgroundColor="transparent"
                  onClick={() =>
                    canGoBack ? handlePageChange('previous') : undefined
                  }
                >
                  <RightCaratIcon
                    cursor="pointer"
                    size="2.5"
                    rotate="180"
                    color={canGoBack ? 'iconMedium' : 'iconLowest'}
                  />
                </Box>
                <Box
                  as="hr"
                  width="px"
                  height="2.5"
                  backgroundColor="borderSeparator"
                />
                <Box
                  id={`${idPrefix}-show-next-page`}
                  as="button"
                  disabled={!canGoNext}
                  backgroundColor="transparent"
                  onClick={() =>
                    canGoNext ? handlePageChange('next') : undefined
                  }
                >
                  <RightCaratIcon
                    cursor="pointer"
                    size="2.5"
                    color={canGoNext ? 'iconMedium' : 'iconLowest'}
                  />
                </Box>
              </Inline>
            </Inline>
          </Inline>
          <Box as="table" width="full" position="relative">
            <Box as="thead" bgColor="surfaceNeutralLowest">
              <Box as="tr">
                <Box
                  as="th"
                  position="sticky"
                  paddingY="1.5"
                  paddingX="2"
                  style={{
                    width: 82,
                  }}
                  top="0"
                  textAlign="left"
                  bgColor="surfaceNeutralLowest"
                >
                  <Text variation="c1">Invoice Date</Text>
                </Box>
                <Box
                  as="th"
                  position="sticky"
                  paddingY="1.5"
                  paddingX="2"
                  style={{
                    width: 88,
                  }}
                  top="0"
                  textAlign="left"
                  bgColor="surfaceNeutralLowest"
                >
                  <Text variation="c1">Creation Date</Text>
                </Box>
                <Box
                  as="th"
                  position="sticky"
                  paddingY="1.5"
                  paddingX="2"
                  style={{
                    width: 160,
                  }}
                  top="0"
                  textAlign="left"
                  bgColor="surfaceNeutralLowest"
                >
                  <Text variation="c1">Invoice Number</Text>
                </Box>
                <Box
                  as="th"
                  position="sticky"
                  paddingY="1.5"
                  paddingX="2"
                  style={{
                    width: 120,
                  }}
                  top="0"
                  textAlign="left"
                  bgColor="surfaceNeutralLowest"
                >
                  <Text variation="c1">Ticket ID</Text>
                </Box>
                <Box
                  as="th"
                  position="sticky"
                  paddingY="1.5"
                  paddingX="2"
                  style={{
                    maxWidth: 160,
                  }}
                  top="0"
                  textAlign="left"
                  bgColor="surfaceNeutralLowest"
                >
                  <Text variation="c1">Vendor</Text>
                </Box>
                <Box
                  as="th"
                  position="sticky"
                  paddingY="1.5"
                  paddingX="2"
                  style={{
                    width: 120,
                  }}
                  top="0"
                  bgColor="surfaceNeutralLowest"
                >
                  <Inline justifyContent="end">
                    <Text variation="c1">Amount</Text>
                  </Inline>
                </Box>
                <Box
                  as="th"
                  position="sticky"
                  paddingY="1.5"
                  paddingLeft="2"
                  paddingRight="3"
                  style={{
                    width: 120,
                  }}
                  top="0"
                  bgColor="surfaceNeutralLowest"
                  textAlign="left"
                >
                  <Text variation="c1">Status</Text>
                </Box>
              </Box>
            </Box>
            {syncingData ? (
              <SkeletonRows numOfRows={10} numOfCols={5} />
            ) : (
              <Box as="tbody">
                {invoices?.length ? (
                  invoices.map(
                    ({
                      id,
                      vendor,
                      grand_total_amount,
                      invoice_status,
                      ticket_number,
                      invoice_date,
                      invoice_number,
                      created_at,
                    }) => {
                      return (
                        <React.Fragment key={invoice_number}>
                          <Box
                            id={`${idPrefix}-list-${id}`}
                            as="tr"
                            borderTopWidth="1"
                            cursor="pointer"
                            tabIndex={-1}
                            backgroundColor={{
                              hover: 'surfacePrimaryLowest',
                            }}
                            onClick={() => onInvoiceClickHandler(ticket_number)}
                          >
                            <Box
                              as="td"
                              paddingX="2"
                              paddingY="1.5"
                              textAlign="left"
                              valign="top"
                            >
                              <Stack gap="1">
                                <DateAndTime
                                  variation="b2"
                                  date={invoice_date}
                                />
                              </Stack>
                            </Box>
                            <Box
                              as="td"
                              paddingX="2"
                              paddingY="1.5"
                              textAlign="left"
                              valign="top"
                            >
                              <Stack gap="1">
                                <DateAndTime variation="b2" date={created_at} />
                              </Stack>
                            </Box>
                            <Box
                              as="td"
                              paddingX="2"
                              paddingY="1.5"
                              valign="top"
                            >
                              <Text variation="b2" marginBottom="2.5">
                                {invoice_number}
                              </Text>
                            </Box>
                            <Box
                              as="td"
                              paddingX="2"
                              paddingY="1.5"
                              valign="top"
                            >
                              <Text variation="b2" marginBottom="2.5">
                                {ticket_number}
                              </Text>
                            </Box>
                            <Box
                              as="td"
                              paddingX="2"
                              paddingY="1.5"
                              valign="top"
                            >
                              <Inline gap="2" marginBottom="0.5">
                                <Avatar
                                  avatarSize="sm"
                                  id={vendor?.id.toString() || id.toString()}
                                  avatarText={
                                    vendor?.name?.length ? vendor?.name[0] : 'U'
                                  }
                                />
                                <Stack gap="1" flex="1">
                                  <Text variation="b2">{vendor?.name}</Text>
                                  <Text variation="c2" color="textMedium">
                                    {vendor?.vendor_type?.name}
                                  </Text>
                                </Stack>
                              </Inline>
                            </Box>
                            <Box
                              as="td"
                              paddingX="2"
                              paddingY="1.5"
                              className="whitespace-pre"
                              textAlign="right"
                              valign="top"
                            >
                              <Box marginBottom="2.5">
                                <Amount
                                  amount={Number(grand_total_amount || 0)}
                                  variation="t4"
                                />
                              </Box>
                            </Box>
                            <Box
                              as="td"
                              paddingLeft="2"
                              paddingRight="3"
                              paddingY="1.5"
                              className="whitespace-pre"
                              textAlign="left"
                              valign="top"
                            >
                              <Box marginBottom="2.5">
                                <Box width="fitContent">
                                  <InvoiceStatusTag status={invoice_status} />
                                </Box>
                              </Box>
                            </Box>
                          </Box>
                        </React.Fragment>
                      );
                    }
                  )
                ) : (
                  <Box as="tr">
                    <Box as="td" colSpan={8}>
                      <EmptyState
                        renderIcon={(props) => <FileIcon {...props} />}
                        title="No Invoices Found!"
                        subText={`Please try changing your applied filters!`}
                        renderButton={() => (
                          <Button
                            id={`${idPrefix}-empty-state-reset-filter-button`}
                            title="Reset Filters"
                            onClick={resetAllFilters}
                          />
                        )}
                      />
                    </Box>
                  </Box>
                )}
              </Box>
            )}
          </Box>
        </Stack>
      </Box>
    </Stack>
  );
}

export {
  InvoicesCreatedByYou,
  InvoicesWaitingForYourApproval,
  TypedInvoicesPage,
  InvoiceDetailsPage,
  AddNewInvoice,
};

const InvoiceTabOptions = [
  {
    title: 'Created By You',
    path: 'created-by-you',
  },
  {
    title: 'Waiting For Your Approval',
    path: 'waiting-for-your-approval',
  },
  {
    title: 'All Invoices',
    path: 'all-invoices',
  },
];

export function InvoicesLayout() {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { type, invoiceTicketNumber } = useParams();
  const state = useOverlayTriggerState({});
  const [document, setDocument] = useState<DocumentFileType | null>(null);
  const [invoiceUploadLoading, setInvoiceUploadLoading] = useState(false);
  const [uploadInvoiceError, setUploadInvoiceError] = useState<string>('');

  const activePath = useMemo(() => {
    const activePath = InvoiceTabOptions.find((option) =>
      pathname.includes(option.path)
    );
    return activePath?.path || InvoiceTabOptions[0].path;
  }, [pathname]);

  const option = useMemo(() => {
    return getLayoutOption(Boolean(invoiceTicketNumber || type));
  }, [invoiceTicketNumber, type]);

  return option === 'typed_invoices' ? (
    <Outlet />
  ) : (
    <Stack flex="1" paddingX="4" paddingY="5" gap="3">
      <Header
        headingText="Invoices"
        renderRight={() => {
          return (
            <Box display="flex" alignItems="center">
              <Box marginRight="2">
                <UploadInvoiceButton onClick={state.open} />
              </Box>
              <Box>
                <AddNewInvoiceButton />
              </Box>
            </Box>
          );
        }}
      />
      <Tabs selectedKey={activePath} onClick={(path: string) => navigate(path)}>
        {InvoiceTabOptions.map((option) => (
          <Item
            key={option.path}
            textValue={option.path}
            title={
              <Box textDecoration="none" style={{ color: 'inherit' }}>
                {option.title}
              </Box>
            }
          >
            <Outlet />
          </Item>
        ))}
      </Tabs>
      <Modal
        isOpen={state.isOpen}
        title="Upload Invoice"
        status="success"
        onClose={state.close}
        size="sm"
        showDefaultHeader={false}
      >
        <Stack>
          <Box
            paddingY="3"
            paddingX="4"
            borderBottomWidth="1"
            borderColor="borderSeparator"
            display="flex"
          >
            <Text variation="h3" flex="1">
              Upload Invoice
            </Text>
            <CrossIcon
              size="4"
              color="iconHigh"
              cursor="pointer"
              onClick={state.close}
            />
          </Box>
          <Box paddingX="3" paddingY="4">
            <UploadDocument
              id={`${idPrefix}`}
              documentInfo={document || undefined}
              showDownloadButton={false}
              onClickSelectDocument={(
                file: DocumentFileType,
                documentInfo?: DocumentFileType
              ) => {
                setUploadInvoiceError('');
                file.imgSrc = documentInfo?.imgSrc;
                setDocument(file);
              }}
              rightIcon={(props, onClick) => (
                <DeleteIcon
                  id={`${idPrefix}-upload-document-delete-icon`}
                  {...props}
                  onClick={() => {
                    setUploadInvoiceError('');
                    setDocument(null);
                    onClick();
                  }}
                  color="iconError"
                  cursor="pointer"
                />
              )}
            />
            {uploadInvoiceError.length ? (
              <Text
                marginTop="1"
                borderRadius="md"
                paddingY="0.5"
                paddingX="1"
                display="flex"
                color="textError"
                variation="c2"
                bgColor="surfaceErrorLowest"
              >
                {uploadInvoiceError}
              </Text>
            ) : null}
          </Box>
          <Box
            width={'auto'}
            display={'flex'}
            alignItems={'center'}
            justifyContent="end"
            backgroundColor="surfaceDefault"
            paddingY="2"
            paddingX="5"
            borderTopWidth="1"
            borderColor="borderSeparator"
            position="sticky"
            bottom="0"
          >
            <Box marginRight="2">
              <Button
                id={`${idPrefix}-cancel`}
                title="Cancel"
                type="outlined"
                onClick={state.close}
              />
            </Box>
            <Box>
              <Button
                id={`${idPrefix}-save`}
                onClick={() => {
                  if (document) {
                    const formData = new FormData();
                    formData.append('file', document as any, document.name);
                    setInvoiceUploadLoading(true);
                    invoiceOcrDetection(formData)
                      .then((res: any) => {
                        setInvoiceUploadLoading(false);
                        const invoiceDetails = res.data;
                        invoiceDetails['documents'] = [document];
                        const invoiceItems = res.data.items;
                        invoiceItems.forEach((invoiceItem: any) => {
                          invoiceItem.unit_type =
                            quantityOptions.find(
                              (item) => item.value === invoiceItem.unit_type
                            )?.value || 'items';
                          if (
                            Boolean(invoiceItem.sac_code) &&
                            Boolean(
                              invoiceItem?.sac_code_data?.expense_particular_id
                            ) &&
                            Boolean(
                              invoiceItem?.sac_code_data?.expense_particular
                                ?.head?.id
                            )
                          ) {
                            invoiceItem.expense_particular_id =
                              invoiceItem.sac_code_data.expense_particular_id;
                            invoiceItem.expense_head_id =
                              invoiceItem?.sac_code_data?.expense_particular?.head?.id;
                            delete invoiceItem.sac_code_data;
                          } else if (
                            Boolean(invoiceItem.hsn_code) &&
                            Boolean(
                              invoiceItem?.hsn_code_data?.expense_particular_id
                            ) &&
                            Boolean(
                              invoiceItem?.hsn_code_data?.expense_particular
                                ?.head?.id
                            )
                          ) {
                            invoiceItem.expense_particular_id =
                              invoiceItem.hsn_code_data.expense_particular_id;
                            invoiceItem.expense_head_id =
                              invoiceItem?.hsn_code_data?.expense_particular?.head?.id;
                            delete invoiceItem.hsn_code_data;
                          } else {
                            if (invoiceItem.is_complete) {
                              invoiceItem.is_complete = false;
                            }
                          }
                        });
                        if (!res.data.vendor_id) {
                          delete invoiceDetails.vendor_id;
                        }
                        navigate(`/add-new-invoice`, {
                          state: {
                            invoiceDetails,
                          },
                        });
                      })
                      .catch((err) => {
                        setUploadInvoiceError(
                          'Unable to scan details. Please try again'
                        );
                        setInvoiceUploadLoading(false);
                      });
                  }
                }}
                title="Proceed"
                type="filled"
                state={
                  invoiceUploadLoading
                    ? 'loading'
                    : !document
                    ? 'disabled'
                    : undefined
                }
              />
            </Box>
          </Box>
        </Stack>
      </Modal>
    </Stack>
  );
}

function getLayoutOption(
  paramsIncluded: boolean
): 'typed_invoices' | 'invoices' {
  return paramsIncluded ? 'typed_invoices' : 'invoices';
}
