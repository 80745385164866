import { useUser } from '@nbfc-expense-tool/data-store/auth';
import { checkForSinglePermission } from '@nbfc-expense-tool/data-store/permissions';
import { Button, ButtonProps } from '@nbfc-expense-tool/ui';

type BulkImportButtonProps = {
  label?: string;
  entityType: 'invoices';
} & ButtonProps;

const BulkMarkPaidButton: React.FC<BulkImportButtonProps> = ({
  label = 'Bulk Import',
  entityType,
  ...props
}) => {
  const { user } = useUser();

  if (
    !checkForSinglePermission(
      'markPaidInvoiceBulk',
      user?.roles[0]?.permissions || null
    )
  ) {
    return null;
  }

  return (
    <Button
      id={`bulk-mark-paid-${entityType}`}
      type="outlined"
      title={label}
      {...props}
    />
  );
};

export default BulkMarkPaidButton;
