// eslint-disable-next-line @nx/enforce-module-boundaries
import { toastQueue } from '@nbfc-expense-tool/ui';
import {
  ErrorResponse,
  bulkMarkPaidEntitiesApi,
  downloadImportEntitySampleFile,
  importEntitiesApi,
} from '@nbfc-expense-tool/data-store/utils';
import { useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';

const labels = {
  vendors: {
    singularLabel: 'Vendor',
    pluralLabel: 'Vendors',
  },
  branches: {
    singularLabel: 'Branch',
    pluralLabel: 'Branches',
  },
  employees: {
    singularLabel: 'Employee',
    pluralLabel: 'Employees',
  },
  invoices: {
    singularLabel: 'Invoice',
    pluralLabel: 'Invoices',
  },
};

export const useBulkImportEntities = (
  entityType: 'vendors' | 'branches' | 'employees'
) => {
  const navigate = useNavigate();

  const [entitiesToImportFile, setEntitiesToImportFile] = useState<
    File | undefined
  >(undefined);
  const [importEntitiesApiState, setImportEntitiesApiState] = useState<{
    status: 'idle' | 'loading' | 'success' | 'error';
    message?: string;
  }>({ status: 'idle' });
  const [isDownloadSampleFileApiLoading, setIsDownloadSampleFileApiLoading] =
    useState<boolean>(false);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const fileInputRef = useRef<any>(null);

  const clickFileInputField = () => {
    fileInputRef.current.click();
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const onPickFile = (event: React.ChangeEvent<HTMLInputElement>) => {
    setImportEntitiesApiState({ status: 'idle' });
    const selectedFile = event.target.files?.[0];
    setEntitiesToImportFile(selectedFile);
  };

  const importEntities = async () => {
    if (entitiesToImportFile) {
      setImportEntitiesApiState({ status: 'loading' });
      try {
        const formData = new FormData();
        formData.append(
          'file',
          entitiesToImportFile,
          entitiesToImportFile.name
        );

        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const response: any = await importEntitiesApi(entityType, formData);
        if (response.data) {
          if (response.data.count === 0) {
            setImportEntitiesApiState({
              status: 'error',
              message: `No ${labels[
                entityType
              ].singularLabel.toLowerCase()} details found in selected file`,
            });
          } else {
            setImportEntitiesApiState({
              status: 'success',
              message: `${response.data.count} ${labels[
                entityType
              ].singularLabel.toLowerCase()} details imported successfully`,
            });
          }
        }
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
      } catch (err: any) {
        setImportEntitiesApiState({
          status: 'error',
          message:
            err?.status === 422
              ? err.message ||
                'Required Fields are missing in selected file. Please refer sample file once'
              : 'Something went wrong, Please try again',
        });
      }
    }
  };

  const downloadSampleFile = async () => {
    setIsDownloadSampleFileApiLoading(true);
    try {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const data: any = await downloadImportEntitySampleFile(entityType, {
        responseType: 'blob',
        headers: {
          'Content-Type':
            'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
          Accept: '*/*',
        },
      });
      const linkElement = document.createElement('a');
      const url = URL.createObjectURL(data);
      linkElement.href = url;
      linkElement.download = `BulkImport${labels[entityType].pluralLabel}SampleFile.xlsx`;
      document.body.appendChild(linkElement);
      linkElement.click();
      setTimeout(function () {
        document.body.removeChild(linkElement);
        window.URL.revokeObjectURL(url);
      }, 0);
    } catch (err) {
      const error = err as ErrorResponse;
      toastQueue.add(
        {
          title: error.message || 'Something Went Wrong! Please try again',
          type: 'error',
        },
        {
          timeout: 2000,
        }
      );
    } finally {
      setIsDownloadSampleFileApiLoading(false);
    }
  };

  const navigateToEntitiesListing = () => {
    navigate(`/home/${entityType}`);
  };

  return {
    labels,
    entitiesToImportFile,
    importEntitiesApiState,
    isDownloadSampleFileApiLoading,
    fileInputRef,
    clickFileInputField,
    onPickFile,
    importEntities,
    downloadSampleFile,
    navigateToEntitiesListing,
  };
};

export const useBulkMarkPaidEntities = (entityType: 'invoices') => {
  const [entitiesToBeMarkedPaidFile, setEntitiesToMarkedPaidFile] = useState<
    File | undefined
  >(undefined);
  const [markPaidEntitiesApiState, setMarkPaidEntitiesApiState] = useState<{
    status: 'idle' | 'loading' | 'success' | 'error';
    message?: string;
  }>({ status: 'idle' });

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const fileInputRef = useRef<any>(null);

  const clickFileInputField = () => {
    fileInputRef.current.click();
  };

  const onPickFile = (event: React.ChangeEvent<HTMLInputElement>) => {
    setMarkPaidEntitiesApiState({ status: 'idle' });
    const selectedFile = event.target.files?.[0];
    setEntitiesToMarkedPaidFile(selectedFile);
  };

  const markPaidEntities = async () => {
    if (entitiesToBeMarkedPaidFile) {
      setMarkPaidEntitiesApiState({ status: 'loading' });
      try {
        const formData = new FormData();
        formData.append(
          'file',
          entitiesToBeMarkedPaidFile,
          entitiesToBeMarkedPaidFile.name
        );

        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const response: any = await bulkMarkPaidEntitiesApi(
          entityType,
          formData
        );
        if (response.data) {
          if (response.data.count === 0) {
            setMarkPaidEntitiesApiState({
              status: 'error',
              message: `No ${labels[
                entityType
              ].singularLabel.toLowerCase()} details found in selected file`,
            });
          } else {
            setMarkPaidEntitiesApiState({
              status: 'success',
              message: `${response.data.count} ${labels[
                entityType
              ].singularLabel.toLowerCase()} details marked paid successfully`,
            });
          }
        }
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
      } catch (err: any) {
        setMarkPaidEntitiesApiState({
          status: 'error',
          message:
            err?.status === 422
              ? err.message ||
                'Required Fields are missing in selected file. Please refer sample file once'
              : 'Something went wrong, Please try again',
        });
      }
    }
  };

  return {
    labels,
    entitiesToBeMarkedPaidFile,
    markPaidEntitiesApiState,
    fileInputRef,
    clickFileInputField,
    onPickFile,
    markPaidEntities,
  };
};
