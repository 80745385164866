/* eslint-disable @typescript-eslint/no-explicit-any */
import { useEffect, useState, useRef, useCallback } from 'react';
import {
  getBranchListing,
  getVendorsListing,
  createNewInvoice,
  CreateInvoicePaylod,
  uploadMediaToTemp,
  getExpenseHeadList,
  ErrorResponse,
  getGst,
  EditInvoicePayload,
  editInvoice,
  getTaxSlabList,
} from '@nbfc-expense-tool/data-store/utils';
import {
  Box,
  FullScreenHeader,
  Text,
  Button,
  Stack,
  TextArea,
  SingleSelect,
  UploadDocument,
  DocumentFileType,
  BaseOption,
  EmptyState,
  FileIcon,
  AddIcon,
  Modal,
  useOverlayTriggerState,
  EditIcon,
  DeleteIcon,
  Amount,
  toastQueue,
  InfoDialog,
} from '@nbfc-expense-tool/ui';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';
import { useAtom, useSetAtom } from 'jotai';
import { useForm, Controller, useWatch } from 'react-hook-form';
import { z } from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';
import {
  AddNewItem,
  GstPayloadType,
  quantityOptions,
} from '../../Invoices/AddNewInvoiceItemForm';
import {
  expenseHeadList,
  invoiceItems,
  InvoiceItemPayloadType,
  gstSlabsList,
} from '@nbfc-expense-tool/data-store/jotai-store';
import {
  FormTextInput,
  FormDateInput,
} from '@nbfc-expense-tool/data-store/forms';

axios.defaults.withCredentials = true;

const now = new Date();
// Create a copy of the current date
const minInvoiceDate = new Date();

// Subtract 3 months from the copy
minInvoiceDate.setMonth(now.getMonth() - 3);

const maxDueDate = new Date();
maxDueDate.setFullYear(now.getFullYear() + 1);

type FormInputs = {
  invoiceNumber: string;
  invoiceDate: string | null;
  dueDate: string | null;
  remarks: string;
  terms: string;
  irnNumber: string;
  branch: number;
  vendor: number;
  showIrnField: string;
  vendorAddressId: number;
};

const stringValidation = z
  .string({ required_error: 'This field cannot be empty' })
  .nonempty('This field cannot be empty');

const noIrnSchema = z.object({
  invoiceNumber: stringValidation,
  invoiceDate: z
    .string({ required_error: 'This field cannot be empty' })
    .nonempty(),
  dueDate: z.union([z.string().nullable(), z.undefined()]),
  remarks: z.union([z.string().nullable(), z.undefined()]),
  terms: z.union([z.string().nullable(), z.undefined()]),
  branch: z.number({ required_error: 'This field cannot be empty' }),
  vendor: z.number({ required_error: 'This field cannot be empty' }),
  vendorAddressId: z.number({ required_error: 'This field cannot be empty' }),
  showIrnField: z.literal('false'),
});

const irnSchema = z.object({
  invoiceNumber: stringValidation,
  invoiceDate: z
    .string({ required_error: 'This field cannot be empty' })
    .nonempty(),
  dueDate: z.union([z.string().nullable(), z.undefined()]),
  remarks: z.union([z.string().nullable(), z.undefined()]),
  terms: z.union([z.string().nullable(), z.undefined()]),
  branch: z.number({ required_error: 'This field cannot be empty' }),
  vendor: z.number({ required_error: 'This field cannot be empty' }),
  showIrnField: z.literal('true'),
  vendorAddressId: z.number({ required_error: 'This field cannot be empty' }),
  irnNumber: stringValidation,
});

const formSchema = z.discriminatedUnion('showIrnField', [
  irnSchema,
  noIrnSchema,
]);

const idPrefix = 'add-new-invoice';

const AddNewInvoice: React.FC = () => {
  const navigate = useNavigate();
  const { invoiceTicketNumber = '' } = useParams();
  const { pathname, state: navState } = useLocation();
  const isEditFlow = pathname.startsWith('/edit-invoice');
  const [branchOptions, setBranchOptions] = useState<BaseOption[]>([]);
  const [vendorOptions, setVendorOptions] = useState<BaseOption[]>([]);
  const [vendorAddressOptions, setVendorAddressOptions] = useState<
    BaseOption[]
  >([]);
  const {
    control,
    handleSubmit,
    setValue,
    clearErrors,
    trigger,
    formState: { isDirty, defaultValues },
    getValues,
    resetField,
  } = useForm<FormInputs>({
    resolver: zodResolver(formSchema),
    defaultValues: navState?.invoiceDetails
      ? {
          branch: navState?.invoiceDetails?.branch_id,
          invoiceDate: navState?.invoiceDetails?.invoice_date,
          vendor: navState?.invoiceDetails?.vendor_id,
          invoiceNumber: navState?.invoiceDetails?.invoice_number,
          irnNumber: navState?.invoiceDetails?.irn_number || '',
          dueDate: navState?.invoiceDetails?.due_date,
          showIrnField: navState?.invoiceDetails?.irn_number ? 'true' : 'false',
          remarks: navState?.invoiceDetails?.remarks || '',
          terms: navState?.invoiceDetails?.terms || '',
          vendorAddressId: navState?.invoiceDetails?.vendor_address_id,
        }
      : {
          showIrnField: 'false',
        },
  });
  const selectedVendorId = useWatch({ name: 'vendor', control });
  const selectedVendorAddressId = useWatch({
    name: 'vendorAddressId',
    control,
  });
  const showIrnField = useWatch({ name: 'showIrnField', control });
  const selectedBranchId = useWatch({ name: 'branch', control });
  const selectedInvoiceDate = useWatch({ name: 'invoiceDate', control });
  const selectedDueDate = useWatch({ name: 'dueDate', control });
  const [documents, setDocuments] = useState<DocumentFileType[]>([]);
  const [createInvoiceLoading, setCreateInvoiceLoading] =
    useState<boolean>(false);
  const state = useOverlayTriggerState({});
  const setExpenseHeadList = useSetAtom(expenseHeadList);
  const [invoiceItemsList, setInvoiceItemsList] = useAtom(invoiceItems);
  const setGstSlabOptions = useSetAtom(gstSlabsList);
  const [selectedItemIndex, setSelectedItemIndex] = useState<number | null>(
    null
  );
  const invoiceItemsClone = invoiceItemsList;
  const isItemAdded = invoiceItemsList.length > 0;
  const [isInvoiceItemEdited, setIsInvoiceItemEdited] = useState(false);
  const deletedMediaIdsRef = useRef<number[]>([]);
  const [isMediaChangedInEditFlow, setIsMediaChanged] = useState(false);
  const [isFormEdited, setIsFormEdited] = useState(false);
  const defaultItemsLength = useRef<number>(0);
  const uploadedMediaIdsRef = useRef<any[]>([]);
  const isInitialGstCalculationDone = useRef(false);
  const [isVendorGstRegistered, setIsVendorGstRegistered] = useState(false);
  const [isCompletedForAllItems, setIsCompletedForAllItems] = useState(true);
  const [addInvoiceErrorMessage, setAddInvoiceErrorMessage] =
    useState<string>('');

  const isScanInvoiceFlow = Boolean(navState?.invoiceDetails) && !isEditFlow;

  useEffect(() => {
    if (uploadedMediaIdsRef.current.length !== documents.length) {
      setIsMediaChanged(true);
    } else {
      let isMediaChanged = false;
      if (documents.length > 0) {
        documents.forEach((item: DocumentFileType, index) => {
          if (item.id !== uploadedMediaIdsRef.current[index].id) {
            isMediaChanged = true;
          }
        });
      }
      setIsMediaChanged(isMediaChanged);
    }
  }, [documents]);

  useEffect(() => {
    if (selectedVendorId) {
      const selectedVendor: any = vendorOptions?.find(
        (item) => item.value === String(selectedVendorId)
      );
      const showIrnField = Boolean(selectedVendor?.is_einvoicing_enabled);
      if (showIrnField) {
        setValue('showIrnField', 'true');
      } else {
        setValue('showIrnField', 'false');
      }
      const vendorsAddressData = selectedVendor?.vendor_addresses?.map(
        (item: any) => {
          return {
            ...item,
            label: item.registered_address?.city
              ? `${item.registered_address?.city}, ${item.registered_address?.state}`
              : `${item.registered_address?.state}`,
            value: `${item.id}`,
          };
        }
      );
      if (vendorsAddressData?.length === 1) {
        setValue('vendorAddressId', vendorsAddressData[0]?.id);
      } else {
        resetField('vendorAddressId');
      }
      setVendorAddressOptions(vendorsAddressData);
    }
  }, [resetField, selectedVendorId, setValue, vendorOptions]);

  useEffect(() => {
    if (selectedVendorAddressId) {
      const selectedVendorAddress: any = vendorAddressOptions?.find(
        (item) => item.value === String(selectedVendorAddressId)
      );
      setIsVendorGstRegistered(
        Boolean(selectedVendorAddress?.is_gst_registered)
      );
    }
  }, [
    setValue,
    selectedVendorId,
    vendorOptions,
    selectedVendorAddressId,
    vendorAddressOptions,
  ]);

  useEffect(() => {
    if (isEditFlow) {
      if (defaultValues?.branch !== selectedBranchId) {
        setIsFormEdited(true);
        return;
      }
      if (defaultValues?.vendor !== selectedVendorId) {
        setIsFormEdited(true);
        return;
      }
      if (defaultValues?.invoiceDate !== selectedInvoiceDate) {
        setIsFormEdited(true);
        return;
      }
      if (defaultValues?.dueDate !== selectedDueDate) {
        setIsFormEdited(true);
        return;
      }
      if (defaultValues?.vendorAddressId !== selectedVendorAddressId) {
        setIsFormEdited(true);
        return;
      }
      setIsFormEdited(false);
    }
  }, [
    defaultValues?.branch,
    defaultValues?.dueDate,
    defaultValues?.invoiceDate,
    defaultValues?.vendor,
    getValues,
    isEditFlow,
    selectedBranchId,
    selectedDueDate,
    selectedInvoiceDate,
    selectedVendorId,
    selectedVendorAddressId,
    defaultValues?.vendorAddressId,
  ]);

  useEffect(() => {
    if (navState?.invoiceDetails && isEditFlow) {
      const { invoice_items = [], documents } = navState.invoiceDetails;
      setInvoiceItemsList(invoice_items);
      uploadedMediaIdsRef.current = documents;
      defaultItemsLength.current = invoice_items.length;
      if (documents && documents.length > 0) {
        const documentsClone = documents.map((item: any) => {
          return {
            name: item.file_name,
            size: item.size,
            type: item.mime_type,
            imgSrc: item.url,
            id: item.id,
          };
        });
        setDocuments(documentsClone);
      }
    }
  }, [isEditFlow, navState?.invoiceDetails, setInvoiceItemsList]);

  useEffect(() => {
    if (
      navState?.invoiceDetails &&
      isScanInvoiceFlow &&
      !isInitialGstCalculationDone.current
    ) {
      const { items = [], documents } = navState.invoiceDetails;
      const index_array: number[] = [];
      const requests: Promise<any>[] = [];
      items.forEach(
        (invoiceItem: InvoiceItemPayloadType, itemIndex: number) => {
          if (
            Boolean(invoiceItem.expense_head_id) &&
            Boolean(invoiceItem.expense_particular_id) &&
            selectedBranchId &&
            selectedVendorId &&
            selectedVendorAddressId
          ) {
            const requestPayload = {
              vendor_address_id: selectedVendorAddressId,
              vendor_id: selectedVendorId,
              branch_id: selectedBranchId,
              expense_head_id: invoiceItem.expense_head_id,
              expense_particular_id: invoiceItem.expense_particular_id,
            };
            index_array.push(itemIndex);

            requests.push(getGst(requestPayload));
            if (requests.length > 0) {
              Promise.all(requests)
                .then((res: any) => {
                  isInitialGstCalculationDone.current = true;
                  res.forEach((gstDetailsResponse: any, index: number) => {
                    const gstDetails: GstPayloadType = gstDetailsResponse.data;
                    const currentInvoiceItem = items[index_array[index]];
                    const totalTaxableAmount =
                      Number(currentInvoiceItem.total_price) || 0;
                    const totalGstPercentage = gstDetails
                      ? gstDetails.is_igst
                        ? gstDetails.igst_percent
                        : gstDetails.cgst_percent + gstDetails.sgst_percent
                      : 0;
                    const totalGstAmount =
                      gstDetails && !gstDetails.is_gst_rcm && totalTaxableAmount
                        ? (totalTaxableAmount * totalGstPercentage) / 100
                        : 0;
                    const tdsAmount = gstDetails.tds_percent
                      ? gstDetails.tds_percent * totalTaxableAmount
                      : 0;
                    const totalAmountIncludingTaxes =
                      totalGstAmount + totalTaxableAmount;
                    items[index_array[index]] = {
                      ...currentInvoiceItem,
                      sgst_percent: gstDetails.sgst_percent,
                      cgst_percent: gstDetails.cgst_percent,
                      igst_percent: gstDetails.igst_percent,
                      tds_percent: gstDetails.tds_percent,
                      gst_amount: totalGstAmount,
                      tds_amount: tdsAmount,
                      total_amount: totalAmountIncludingTaxes,
                    };
                  });
                })
                .catch((err) => null);
            }
          }
          if (items.length > 0) {
            setInvoiceItemsList(items);
          }
        }
      );
      setInvoiceItemsList(items);
      if (Array.isArray(documents)) {
        setDocuments(documents);
      }
      trigger(['branch', 'invoiceDate', 'invoiceNumber', 'vendor']);
    }
  }, [
    isScanInvoiceFlow,
    navState?.invoiceDetails,
    selectedBranchId,
    selectedVendorAddressId,
    selectedVendorId,
    setInvoiceItemsList,
    setValue,
    trigger,
  ]);

  useEffect(() => {
    if (invoiceItemsList && invoiceItemsList.length > 0) {
      let isCompletedForAllItemsFlag = true;
      invoiceItemsList.forEach((invoiceItem) => {
        if (invoiceItem.is_complete === false) {
          isCompletedForAllItemsFlag = false;
        }
      });
      setIsCompletedForAllItems(isCompletedForAllItemsFlag);
    }
  }, [invoiceItemsList]);

  useEffect(() => {
    getTaxSlabList()
      .then((res: any) => {
        const taxSlabs = res?.data?.gst_slabs
          ? res.data.gst_slabs.map((item: any) => {
              return {
                value: String(item.id),
                label: String(item.title),
              };
            })
          : [];
        setGstSlabOptions(taxSlabs);
      })
      .catch(() => null);
  }, [setGstSlabOptions]);

  useEffect(() => {
    return () => {
      setInvoiceItemsList([]);
    };
  }, [setInvoiceItemsList]);

  useEffect(() => {
    const selectedVendor: any = vendorOptions.find(
      (item) => item.value === String(selectedVendorId)
    );
    const showIrnField = Boolean(selectedVendor?.is_einvoicing_enabled);
    const isGstRegistered = Boolean(selectedVendor?.is_gst_registered);
    if (showIrnField) {
      setValue('showIrnField', 'true');
    } else {
      setValue('showIrnField', 'false');
    }
    setIsVendorGstRegistered(isGstRegistered);
  }, [setValue, selectedVendorId, vendorOptions]);

  const submitDetails = (values: FormInputs) => {
    setCreateInvoiceLoading(true);
    if (isEditFlow) {
      const editInvoicePayload: EditInvoicePayload = {};
      if (values.branch !== defaultValues?.branch) {
        editInvoicePayload['branch_id'] = values.branch;
      }
      if (values.vendor !== defaultValues?.vendor) {
        editInvoicePayload['vendor_id'] = values.vendor;
      }
      if (
        values.invoiceDate !== defaultValues?.invoiceDate &&
        values.invoiceDate
      ) {
        editInvoicePayload['invoice_date'] = values.invoiceDate;
      }
      if (values.dueDate !== defaultValues?.dueDate && values.dueDate) {
        editInvoicePayload['due_date'] = values.dueDate;
      }
      if (values.invoiceNumber !== defaultValues?.invoiceNumber) {
        editInvoicePayload['invoice_number'] = values.invoiceNumber;
      }
      if (values.vendorAddressId !== defaultValues?.vendorAddressId) {
        editInvoicePayload['vendor_address_id'] = values.vendorAddressId;
      }
      if (values.irnNumber !== defaultValues?.irnNumber) {
        if (values?.irnNumber && values.showIrnField === 'true') {
          editInvoicePayload['irn_number'] = values.irnNumber;
        } else {
          editInvoicePayload['irn_number'] = null;
        }
      }
      if (values.remarks !== defaultValues?.remarks) {
        if (values?.remarks) {
          editInvoicePayload['remarks'] = values.remarks;
        } else {
          editInvoicePayload['remarks'] = null;
        }
      }
      if (values.terms !== defaultValues?.terms) {
        if (values?.terms) {
          editInvoicePayload['terms'] = values.terms;
        } else {
          editInvoicePayload['terms'] = null;
        }
      }
      let gstAmount = 0;
      let subTotalAmount = 0;
      let grandTotalAmount = 0;
      let totalTdsAmount = 0;
      invoiceItemsList.forEach((invoiceIem) => {
        gstAmount = gstAmount + Number(invoiceIem.gst_amount);
        subTotalAmount = subTotalAmount + Number(invoiceIem.total_price);
        grandTotalAmount = grandTotalAmount + Number(invoiceIem.total_amount);
        totalTdsAmount = totalTdsAmount + Number(invoiceIem.tds_amount);
        delete invoiceIem.is_complete;
      });
      editInvoicePayload['gst_amount'] = gstAmount;
      editInvoicePayload['sub_total_amount'] = subTotalAmount;
      editInvoicePayload['grand_total_amount'] = grandTotalAmount;
      editInvoicePayload['tds_amount'] = totalTdsAmount;
      editInvoicePayload['items'] = invoiceItemsList;
      if (isMediaChangedInEditFlow) {
        if (deletedMediaIdsRef.current.length > 0) {
          editInvoicePayload['document_delete_ids'] =
            deletedMediaIdsRef.current;
        }
        const requests: Promise<any>[] = [];
        documents.forEach((document: any) => {
          if (!document.id) {
            const formData = new FormData();
            formData.append('file', document, document.name);
            requests.push(uploadMediaToTemp(formData));
          }
        });
        if (requests.length > 0) {
          Promise.all(requests)
            .then((res: any) => {
              const documentIds: string[] = [];
              res.forEach((responseItem: any) => {
                documentIds.push(responseItem.data.id);
              });
              editInvoicePayload['document_upload_ids'] = documentIds;
              editInvoice(invoiceTicketNumber, editInvoicePayload)
                .then(() => {
                  toastQueue.add(
                    {
                      title: 'Invoice edited successfully',
                      type: 'success',
                    },
                    {
                      timeout: 2000,
                    }
                  );
                  navigate('/home/invoices/created-by-you');
                  setCreateInvoiceLoading(false);
                })
                .catch((err) => {
                  const error = err as ErrorResponse;
                  setAddInvoiceErrorMessage(
                    error.message || 'Something Went Wrong! Please try again'
                  );
                  setCreateInvoiceLoading(false);
                });
            })
            .catch((err) => {
              setCreateInvoiceLoading(false);
              const error = err as ErrorResponse;
              setAddInvoiceErrorMessage(
                error.message || 'Something Went Wrong! Please try again'
              );
            });
        } else {
          editInvoice(invoiceTicketNumber, editInvoicePayload)
            .then(() => {
              toastQueue.add(
                {
                  title: 'Invoice edited successfully',
                  type: 'success',
                },
                {
                  timeout: 2000,
                }
              );
              setCreateInvoiceLoading(false);
              navigate('/home/invoices/created-by-you');
            })
            .catch((err) => {
              const error = err as ErrorResponse;
              setAddInvoiceErrorMessage(
                error.message || 'Something Went Wrong! Please try again'
              );
              setCreateInvoiceLoading(false);
            });
        }
      } else {
        editInvoice(invoiceTicketNumber, editInvoicePayload)
          .then(() => {
            toastQueue.add(
              {
                title: 'Invoice edited successfully',
                type: 'success',
              },
              {
                timeout: 2000,
              }
            );
            setCreateInvoiceLoading(false);
            navigate('/home/invoices/created-by-you');
          })
          .catch((err) => {
            const error = err as ErrorResponse;
            setAddInvoiceErrorMessage(
              error.message || 'Something Went Wrong! Please try again'
            );
            setCreateInvoiceLoading(false);
          });
      }
    } else {
      let gstAmount = 0;
      let subTotalAmount = 0;
      let grandTotalAmount = 0;
      let totalTdsAmount = 0;
      invoiceItemsList.forEach((invoiceIem) => {
        gstAmount = gstAmount + invoiceIem.gst_amount;
        subTotalAmount = subTotalAmount + invoiceIem.total_price;
        grandTotalAmount = grandTotalAmount + invoiceIem.total_amount;
        totalTdsAmount = totalTdsAmount + invoiceIem.tds_amount;
        delete invoiceIem.is_complete;
      });
      const createInvoicePayload: CreateInvoicePaylod = {
        invoice_number: values.invoiceNumber,
        invoice_date: values.invoiceDate || '',
        due_date: values.dueDate || '',
        vendor_id: values.vendor,
        branch_id: values.branch,
        gst_amount: gstAmount,
        sub_total_amount: subTotalAmount,
        grand_total_amount: grandTotalAmount,
        document_upload_ids: [],
        tds_amount: totalTdsAmount,
        items: invoiceItemsList,
        vendor_address_id: values.vendorAddressId,
      };
      if (values.irnNumber) {
        createInvoicePayload['irn_number'] = values.irnNumber;
      }
      if (values.remarks) {
        createInvoicePayload['remarks'] = values.remarks;
      }
      if (values.terms) {
        createInvoicePayload['terms'] = values.terms;
      }
      if (documents.length > 0) {
        const requests: Promise<any>[] = [];
        documents.forEach((document: any) => {
          const formData = new FormData();
          formData.append('file', document, document.name);
          requests.push(uploadMediaToTemp(formData));
        });
        Promise.all(requests)
          .then((res: any) => {
            const documentIds: string[] = [];
            res.forEach((responseItem: any) => {
              documentIds.push(responseItem.data.id);
            });
            createInvoicePayload['document_upload_ids'] = documentIds;
            createNewInvoice(createInvoicePayload)
              .then(() => {
                toastQueue.add(
                  {
                    title: 'Invoice added successfully',
                    type: 'success',
                  },
                  {
                    timeout: 2000,
                  }
                );
                navigate('/home/invoices/created-by-you');
                setCreateInvoiceLoading(false);
              })
              .catch((err) => {
                const error = err as ErrorResponse;
                setAddInvoiceErrorMessage(
                  error.message || 'Something Went Wrong! Please try again'
                );
                setCreateInvoiceLoading(false);
              });
          })
          .catch((err) => {
            setCreateInvoiceLoading(false);
            const error = err as ErrorResponse;
            setAddInvoiceErrorMessage(
              error.message || 'Something Went Wrong! Please try again'
            );
          });
      } else {
        createNewInvoice(createInvoicePayload)
          .then(() => {
            toastQueue.add(
              {
                title: 'Invoice added successfully',
                type: 'success',
              },
              {
                timeout: 2000,
              }
            );
            setCreateInvoiceLoading(false);
            navigate('/home/invoices/created-by-you');
          })
          .catch((err) => {
            const error = err as ErrorResponse;
            setAddInvoiceErrorMessage(
              error.message || 'Something Went Wrong! Please try again'
            );
            setCreateInvoiceLoading(false);
          });
      }
    }
  };

  useEffect(() => {
    getBranchListing()
      .then((res: any) => {
        const branchesData = res.data.map((item: any) => {
          return {
            ...item,
            label: `${item.name} - ${item.branch_code}`,
            value: `${item.id}`,
          };
        });
        if (branchesData.length === 1 && !isEditFlow) {
          setValue('branch', Number(branchesData[0].value));
          clearErrors('branch');
        }
        setBranchOptions(branchesData);
      })
      .catch(() => null);
  }, [clearErrors, isEditFlow, setValue]);

  useEffect(() => {
    getExpenseHeadList()
      .then((res: any) => {
        const expenseHeadResp = res.data.map((item: any) => {
          return {
            ...item,
            value: String(item.id),
            label: item.name,
          };
        });
        setExpenseHeadList(expenseHeadResp);
      })
      .catch((err) => null);
  }, [setExpenseHeadList]);

  useEffect(() => {
    if (selectedBranchId) {
      resetField('vendor');
      resetField('vendorAddressId');
      setVendorOptions((prevState: BaseOption[]) => {
        if (prevState.length > 0) {
          return [];
        }
        return prevState;
      });
      getVendorsListing({ branch_id: [String(selectedBranchId)] })
        .then((res: any) => {
          const vendorsData = res.data.map((item: any) => {
            return {
              ...item,
              label: item.name,
              value: `${item.id}`,
            };
          });
          setVendorOptions(vendorsData);
        })
        .catch(() => {
          setVendorOptions([]);
        });
    }
  }, [resetField, selectedBranchId]);

  const onSelectDocument = (file: DocumentFileType) => {
    const documentsClone = [...documents];
    documentsClone.push(file);
    setDocuments(documentsClone);
  };

  const onClickDeleteMedia = (index: 0 | 1) => {
    if (isEditFlow) {
      if (documents && documents[index] && documents[index].id) {
        deletedMediaIdsRef.current.push(Number(documents[index].id));
      }
    }
    if (index === 1) {
      documents.pop();
      setDocuments([...documents]);
    } else {
      documents.shift();
      setDocuments([...documents]);
    }
  };

  const onItemEdited = () => {
    if (isEditFlow) {
      setIsInvoiceItemEdited(true);
    }
  };

  const triggerAddItemError = useCallback(
    (index: number | null = null) => {
      const requiredFields: ('branch' | 'vendor' | 'vendorAddressId')[] = [];
      if (!selectedBranchId) {
        requiredFields.push('branch');
      }
      if (!selectedVendorId) {
        requiredFields.push('vendor');
      }
      if (!selectedVendorAddressId) {
        requiredFields.push('vendorAddressId');
      }
      if (requiredFields.length > 0) {
        trigger(requiredFields);
        toastQueue.add(
          {
            title: 'Please fill all the mandatory details',
            type: 'error',
          },
          {
            timeout: 2000,
          }
        );
      } else {
        setSelectedItemIndex(index);
        state.open();
      }
    },
    [
      selectedBranchId,
      selectedVendorAddressId,
      selectedVendorId,
      state,
      trigger,
    ]
  );

  return (
    <Box>
      <Modal
        isOpen={state.isOpen}
        title={`Add Entry`}
        placement="right"
        status={'success'}
        onClose={state.close}
        size="lg"
        showDefaultHeader={false}
      >
        <AddNewItem
          onClickClose={state.close}
          selectedVendorId={selectedVendorId}
          selectedBranchId={selectedBranchId}
          editItemIndex={selectedItemIndex}
          onEditItem={onItemEdited}
          isVendorGstRegistered={isVendorGstRegistered}
          isScanInvoiceFlow={isScanInvoiceFlow}
          selectedVendorAddressId={selectedVendorAddressId}
        />
      </Modal>
      <FullScreenHeader
        headingText={isEditFlow ? 'Edit Invoice' : 'Add Invoice'}
        onClickCross={() => navigate(-1)}
        borderBottomWidth="1"
        borderColor="borderSeparator"
        position="sticky"
        top="0"
        zIndex="50"
      />
      <Stack paddingX="5" flex="1" overflowY="scroll" paddingBottom="10">
        <Text variation="t3" marginTop="4">
          Basic Details
        </Text>
        <Box display="flex" marginTop="3" alignItems="start">
          <Box style={{ width: 324 }}>
            <FormTextInput
              control={control}
              id={`${idPrefix}-enter-invoice-number`}
              name="invoiceNumber"
              label="Invoice Number"
              isRequired
              description="Max. 16 characters, alphanumeric incl. ‘/’ & ‘Space’`"
              fullWidth
              placeholder="Number from original invoice by vendor"
              maxLength={16}
              isControlled
            />
          </Box>
          <FormDateInput
            id={`${idPrefix}-enter-invoice-date`}
            control={control}
            name="invoiceDate"
            label="Invoice Date"
            marginLeft="5"
            isRequired={false}
            isControlled
            datePickerProps={{
              placeholderText: 'Select Invoice Date',
              maxDate: new Date(now),
              minDate: minInvoiceDate,
            }}
            onChangeDate={(date) => {
              setValue('invoiceDate', date?.toISOString() || null);
              if (date?.toISOString()) {
                clearErrors('invoiceDate');
              }
            }}
          />
          <FormDateInput
            id={`${idPrefix}-enter-due-date`}
            control={control}
            name="dueDate"
            label="Due Date"
            marginLeft="5"
            isRequired={false}
            onChangeDate={(date) => {
              setValue('dueDate', date?.toISOString() || null);
              if (date?.toISOString()) {
                clearErrors('dueDate');
              }
            }}
            isControlled
            datePickerProps={{
              placeholderText: 'Select due date for payment',
              minDate: now,
              maxDate: maxDueDate,
            }}
          />
        </Box>
        <Box display="flex" marginTop="6" alignItems="start">
          <Box style={{ width: 324 }}>
            <Controller
              control={control}
              name="branch"
              render={(props) => {
                const {
                  field: { onChange, value },
                  fieldState: { error },
                } = props;
                return (
                  <SingleSelect
                    id={`${idPrefix}-select-branch`}
                    options={branchOptions}
                    onSave={(option) => {
                      clearErrors('branch');
                      onChange(Number(option.value));
                    }}
                    label="Branch"
                    isRequired
                    value={value?.toString()}
                    errorMessage={error?.message}
                  />
                );
              }}
            />
          </Box>
          <Box style={{ width: 324 }} marginLeft="5">
            <Controller
              control={control}
              name="vendor"
              render={(props) => {
                const {
                  field: { onChange, value },
                  fieldState: { error },
                } = props;
                return (
                  <SingleSelect
                    id={`${idPrefix}-select-vendor`}
                    options={vendorOptions}
                    onSave={(option) => {
                      clearErrors('vendor');
                      onChange(Number(option.value));
                      if (
                        selectedBranchId &&
                        selectedVendorId &&
                        selectedVendorAddressId &&
                        isItemAdded &&
                        Number(option.value) !== selectedVendorId
                      ) {
                        const invoiceItems: InvoiceItemPayloadType[] = [];
                        const requests: Promise<any>[] = [];
                        invoiceItemsClone.forEach(
                          (invoiceItem: InvoiceItemPayloadType) => {
                            const requestPayload = {
                              vendor_address_id: selectedVendorAddressId,
                              vendor_id: Number(option.value),
                              branch_id: selectedBranchId,
                              tds_id: invoiceItem.tds_id,
                              gst_id: invoiceItem.gst_id,
                            };
                            requests.push(getGst(requestPayload));
                          }
                        );
                        if (requests.length > 0) {
                          Promise.all(requests)
                            .then((res: any) => {
                              res.forEach(
                                (gstDetailsResponse: any, index: number) => {
                                  const gstDetails: GstPayloadType =
                                    gstDetailsResponse.data;
                                  const currentInvoiceItem =
                                    invoiceItemsClone[index];
                                  const totalTaxableAmount =
                                    Number(currentInvoiceItem.total_price) || 0;
                                  const totalGstPercentage = gstDetails
                                    ? gstDetails.is_igst
                                      ? gstDetails.igst_percent
                                      : gstDetails.cgst_percent +
                                        gstDetails.sgst_percent
                                    : 0;
                                  const totalGstAmount =
                                    gstDetails &&
                                    !gstDetails.is_gst_rcm &&
                                    totalTaxableAmount
                                      ? (totalTaxableAmount *
                                          totalGstPercentage) /
                                        100
                                      : 0;
                                  const tdsAmount = gstDetails.tds_percent
                                    ? gstDetails.tds_percent *
                                      totalTaxableAmount
                                    : 0;
                                  const totalAmountIncludingTaxes =
                                    totalGstAmount + totalTaxableAmount;
                                  invoiceItems.push({
                                    ...currentInvoiceItem,
                                    sgst_percent: gstDetails.sgst_percent,
                                    cgst_percent: gstDetails.cgst_percent,
                                    igst_percent: gstDetails.igst_percent,
                                    tds_percent: gstDetails.tds_percent,
                                    gst_amount: totalGstAmount,
                                    tds_amount: tdsAmount,
                                    total_amount: totalAmountIncludingTaxes,
                                  });
                                }
                              );
                              if (invoiceItems.length > 0) {
                                setInvoiceItemsList(invoiceItems);
                              }
                            })
                            .catch((err) => null);
                        }
                      }
                    }}
                    label="Vendor"
                    isRequired
                    value={value?.toString()}
                    errorMessage={error?.message}
                  />
                );
              }}
            />
          </Box>
          <Box style={{ width: 324 }} marginLeft="5">
            <Controller
              control={control}
              name="vendorAddressId"
              render={(props) => {
                const {
                  field: { onChange, value },
                  fieldState: { error },
                } = props;
                return (
                  <SingleSelect
                    id={`${idPrefix}-select-vendor-address`}
                    options={vendorAddressOptions}
                    onSave={(option) => {
                      clearErrors('vendorAddressId');
                      onChange(Number(option.value));
                      if (
                        selectedBranchId &&
                        selectedVendorId &&
                        selectedVendorAddressId &&
                        isItemAdded &&
                        Number(option.value) !== selectedVendorAddressId
                      ) {
                        const invoiceItems: InvoiceItemPayloadType[] = [];
                        const requests: Promise<any>[] = [];
                        invoiceItemsClone.forEach(
                          (invoiceItem: InvoiceItemPayloadType) => {
                            const requestPayload = {
                              vendor_address_id: Number(option.value),
                              vendor_id: selectedVendorId,
                              branch_id: selectedBranchId,
                              tds_id: invoiceItem.tds_id,
                              gst_id: invoiceItem.gst_id,
                            };
                            requests.push(getGst(requestPayload));
                          }
                        );
                        if (requests.length > 0) {
                          Promise.all(requests)
                            .then((res: any) => {
                              res.forEach(
                                (gstDetailsResponse: any, index: number) => {
                                  const gstDetails: GstPayloadType =
                                    gstDetailsResponse.data;
                                  const currentInvoiceItem =
                                    invoiceItemsClone[index];
                                  const totalTaxableAmount =
                                    Number(currentInvoiceItem.total_price) || 0;
                                  const totalGstPercentage = gstDetails
                                    ? gstDetails.is_igst
                                      ? gstDetails.igst_percent
                                      : gstDetails.cgst_percent +
                                        gstDetails.sgst_percent
                                    : 0;
                                  const totalGstAmount =
                                    gstDetails &&
                                    !gstDetails.is_gst_rcm &&
                                    totalTaxableAmount
                                      ? (totalTaxableAmount *
                                          totalGstPercentage) /
                                        100
                                      : 0;
                                  const tdsAmount = gstDetails.tds_percent
                                    ? gstDetails.tds_percent *
                                      totalTaxableAmount
                                    : 0;
                                  const totalAmountIncludingTaxes =
                                    totalGstAmount + totalTaxableAmount;
                                  invoiceItems.push({
                                    ...currentInvoiceItem,
                                    sgst_percent: gstDetails.sgst_percent,
                                    cgst_percent: gstDetails.cgst_percent,
                                    igst_percent: gstDetails.igst_percent,
                                    tds_percent: gstDetails.tds_percent,
                                    gst_amount: totalGstAmount,
                                    tds_amount: tdsAmount,
                                    total_amount: totalAmountIncludingTaxes,
                                  });
                                }
                              );
                              if (invoiceItems.length > 0) {
                                setInvoiceItemsList(invoiceItems);
                              }
                            })
                            .catch((err) => null);
                        }
                      }
                    }}
                    label="Vendor Address"
                    isRequired
                    value={value?.toString()}
                    errorMessage={error?.message}
                  />
                );
              }}
            />
          </Box>
        </Box>
        {showIrnField === 'true' ? (
          <Box
            style={{ width: 324 }}
            display="flex"
            marginTop="6"
            alignItems="start"
          >
            <FormTextInput
              id={`${idPrefix}-enter-irn-number`}
              control={control}
              name="irnNumber"
              label="IRN Number"
              fullWidth
              placeholder="Enter IRN Number"
              isRequired
              maxLength={255}
              fieldInfo="E-invoicing GST reference"
              isControlled
            />
          </Box>
        ) : null}
        <Text variation="t3" marginTop="6">
          Invoice Items
        </Text>
        <Stack marginTop="2" display="flex" maxWidth="fitContent">
          <Box
            backgroundColor="surfaceNeutralLowest"
            paddingX="2"
            paddingY="1.5"
            display="flex"
            width="full"
            gap="8"
            maxWidth="fitContent"
          >
            <Box style={{ width: 232 }} textAlign="left">
              <Text variation="c1">Item Name & Description</Text>
            </Box>
            <Box style={{ width: 100 }} textAlign="left">
              <Text variation="c1">Qty.</Text>
            </Box>
            <Box style={{ width: 100 }} textAlign="right">
              <Text variation="c1">Rate</Text>
            </Box>
            <Box style={{ width: 120 }} textAlign="right">
              <Text variation="c1">Taxable Amt</Text>
            </Box>
            <Box style={{ width: 100 }} textAlign="right">
              <Text variation="c1">GST</Text>
            </Box>
            <Box style={{ width: 120 }} textAlign="right">
              <Text variation="c1">Total Amount</Text>
            </Box>
            <Box style={{ width: 72 }} textAlign="right">
              <Text variation="c1">Actions</Text>
            </Box>
          </Box>
          <Stack
            borderBottomWidth={invoiceItemsList.length === 0 ? '1' : undefined}
            borderColor={
              invoiceItemsList.length === 0 ? 'borderSeparator' : undefined
            }
          >
            {invoiceItemsList.length === 0 ? (
              <EmptyState
                componentSize="sm"
                title="No Invoice Items!"
                subText="Add items to create invoice"
                renderIcon={(props) => <FileIcon {...props} />}
                renderButton={() => (
                  <Button
                    id={`${idPrefix}-empty-state-add-invoice-item`}
                    type="outlined"
                    title="Add Item"
                    leftIcon={(props) => <AddIcon {...props} />}
                    onClick={() => {
                      triggerAddItemError();
                    }}
                  />
                )}
              />
            ) : (
              <>
                {invoiceItemsList.map((item, index) => {
                  const totalTaxableAmount = Number(item.total_price);
                  let totalGstPercentage = 0;
                  if (item.igst_percent && Number(item.igst_percent)) {
                    totalGstPercentage =
                      totalGstPercentage + Number(item.igst_percent);
                  } else if (
                    item.cgst_percent &&
                    Number(item.cgst_percent) &&
                    item.sgst_percent &&
                    Number(item.sgst_percent)
                  ) {
                    totalGstPercentage =
                      Number(item.cgst_percent) + Number(item.sgst_percent);
                  }
                  const totalGstAmount =
                    totalTaxableAmount * (totalGstPercentage / 100);
                  const grandTotalAmount = totalTaxableAmount + totalGstAmount;
                  const isItemErrored =
                    isScanInvoiceFlow && item.is_complete === false;
                  return (
                    <Box
                      paddingY="2.5"
                      paddingX="2"
                      borderBottomWidth="1"
                      borderColor="borderSeparator"
                      display="flex"
                      gap="8"
                      alignItems="start"
                    >
                      <Stack
                        style={{ width: 232 }}
                        display="flex"
                        textAlign="left"
                      >
                        {isItemErrored ? (
                          <Text variation="c2" color="textError">
                            Mandatory fields missing
                          </Text>
                        ) : null}
                        <Text variation="b2">{item.name}</Text>
                        <Text variation="c2" color="textLow">
                          {item.description}
                        </Text>
                      </Stack>
                      <Box style={{ width: 100 }} textAlign="left">
                        <Text variation="b2">
                          {item.unit_number}
                          <Text
                            variation="b2"
                            as="span"
                            color="textLow"
                            marginLeft="1"
                          >
                            {quantityOptions.find(
                              (quantityItem) =>
                                quantityItem.value === item.unit_type
                            )?.label || 'Items'}
                          </Text>
                        </Text>
                      </Box>
                      <Box style={{ width: 100 }} textAlign="right">
                        <Amount
                          amount={Number(item.unit_price) || 0}
                          variation="b2"
                        />
                      </Box>
                      <Box style={{ width: 120 }} textAlign="right">
                        <Amount
                          amount={Number(totalTaxableAmount) || 0}
                          variation="t4"
                        />
                      </Box>
                      <Stack style={{ width: 100 }} textAlign="right">
                        <Amount
                          amount={Number(totalGstAmount) || 0}
                          variation="b2"
                          textAlign="right"
                        />
                        <Text variation="c2" color="textLow" textAlign="right">
                          {totalGstPercentage}%
                        </Text>
                      </Stack>
                      <Box style={{ width: 120 }} textAlign="right">
                        <Amount
                          amount={Number(grandTotalAmount) || 0}
                          variation="t4"
                          textAlign="right"
                        />
                      </Box>
                      <Box
                        style={{ width: 72 }}
                        display="flex"
                        justifyContent="end"
                        alignItems="center"
                      >
                        <EditIcon
                          id={`${idPrefix}-edit-invoice-item`}
                          size="3"
                          color="iconPrimary"
                          marginRight="2.5"
                          cursor="pointer"
                          onClick={() => {
                            triggerAddItemError(index);
                          }}
                        />
                        <DeleteIcon
                          id={`${idPrefix}-delete-invoice-item`}
                          size="3"
                          color="iconError"
                          cursor="pointer"
                          onClick={() => {
                            invoiceItemsList.splice(index, 1);
                            setInvoiceItemsList([...invoiceItemsList]);
                          }}
                        />
                      </Box>
                    </Box>
                  );
                })}
              </>
            )}
          </Stack>
          {invoiceItemsList.length > 0 ? (
            <Box marginTop="3">
              <Button
                id={`${idPrefix}-add-invoice-item`}
                type="outlined"
                title="Add Item"
                leftIcon={(props) => <AddIcon {...props} />}
                onClick={() => {
                  triggerAddItemError();
                }}
              />
            </Box>
          ) : null}
        </Stack>
        <Text variation="t3" marginTop="6">
          Documents
        </Text>
        <Box
          display="flex"
          alignItems="center"
          marginTop="3"
          width={documents.length === 0 ? '1/2' : undefined}
          gap="8"
        >
          <UploadDocument
            id={`${idPrefix}`}
            documentInfo={documents.length > 0 ? documents[0] : undefined}
            showDownloadButton={false}
            onClickSelectDocument={(file: DocumentFileType) => {
              onSelectDocument(file);
            }}
            rightIcon={(props, onClick) => (
              <DeleteIcon
                id={`${idPrefix}-upload-document-delete-icon`}
                {...props}
                onClick={() => {
                  onClickDeleteMedia(0);
                  onClick();
                }}
                color="iconError"
                cursor="pointer"
              />
            )}
          />
          {documents.length > 0 ? (
            <UploadDocument
              id={`${idPrefix}`}
              documentInfo={documents.length > 1 ? documents[1] : undefined}
              showDownloadButton={false}
              onClickSelectDocument={(file: DocumentFileType) => {
                onSelectDocument(file);
              }}
              rightIcon={(props, onClick) => (
                <DeleteIcon
                  {...props}
                  onClick={() => {
                    onClickDeleteMedia(1);
                    onClick();
                  }}
                  color="iconError"
                  cursor="pointer"
                />
              )}
            />
          ) : null}
        </Box>
        <Box display="flex" marginTop="6">
          <Box style={{ width: 432 }}>
            <Controller
              control={control}
              name="remarks"
              render={(props) => {
                const {
                  field: { onChange, onBlur, value },
                } = props;
                return (
                  <TextArea
                    id={`${idPrefix}-add-remark`}
                    label="Remarks"
                    placeholder="Add remark here"
                    fullWidth
                    onChange={onChange}
                    onBlur={onBlur}
                    value={value !== undefined ? String(value) : undefined}
                  />
                );
              }}
            />
          </Box>
          <Box style={{ width: 432 }}>
            <Controller
              control={control}
              name="terms"
              render={(props) => {
                const {
                  field: { onChange, onBlur, value },
                } = props;
                return (
                  <TextArea
                    id={`${idPrefix}-add-terms`}
                    label="Terms"
                    marginLeft="5"
                    placeholder="Add terms here"
                    fullWidth
                    onChange={onChange}
                    onBlur={onBlur}
                    value={value !== undefined ? String(value) : undefined}
                  />
                );
              }}
            />
          </Box>
        </Box>
      </Stack>
      <InfoDialog
        id={`${idPrefix}-error-modal`}
        isVisible={Boolean(addInvoiceErrorMessage)}
        title={isEditFlow ? 'Edit Invoice Error' : 'Add Invoice Error'}
        text=""
        description={addInvoiceErrorMessage}
        onConfirm={() => {
          setAddInvoiceErrorMessage('');
        }}
        confirmLabel="Ok, Got it"
        toggleButtons
        alignment="left"
        onClose={() => setAddInvoiceErrorMessage('')}
      />
      <Box
        width={'auto'}
        display={'flex'}
        alignItems={'center'}
        justifyContent="end"
        backgroundColor="surfaceDefault"
        paddingY="2"
        paddingX="5"
        borderTopWidth="1"
        borderColor="borderSeparator"
        position="sticky"
        bottom="0"
        zIndex="50"
      >
        <Box marginRight="2">
          <Button
            id={`${idPrefix}-cancel`}
            title="Cancel"
            type="outlined"
            state={createInvoiceLoading ? 'disabled' : undefined}
            onClick={() => {
              navigate('/home/invoices/created-by-you');
            }}
          />
        </Box>
        <Box>
          <Button
            id={`${idPrefix}-save-invoice`}
            onClick={handleSubmit(submitDetails)}
            title={
              isEditFlow
                ? 'Save Changes'
                : createInvoiceLoading
                ? 'Saving Invoice'
                : 'Save Invoice'
            }
            type="filled"
            state={
              invoiceItemsList.length === 0 ||
              (isEditFlow &&
                !isInvoiceItemEdited &&
                !isMediaChangedInEditFlow &&
                !isFormEdited &&
                !isDirty &&
                defaultItemsLength.current === invoiceItemsList.length) ||
              (isScanInvoiceFlow && !isCompletedForAllItems)
                ? 'disabled'
                : createInvoiceLoading
                ? 'loading'
                : undefined
            }
          />
        </Box>
      </Box>
    </Box>
  );
};

export default AddNewInvoice;
