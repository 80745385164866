/* eslint-disable @typescript-eslint/no-explicit-any */
import { useRef } from 'react';
import { Controller, FieldErrors, useForm } from 'react-hook-form';

import {
  Box,
  FullScreenHeader,
  TextInput,
  Stack,
  Text,
  SingleSelect,
  Button,
  Inline,
  Checkbox,
  UploadDocument,
  DeleteIcon,
  Radio,
  RadioGroup,
  toastQueue,
} from '@nbfc-expense-tool/ui';
import { zodResolver } from '@hookform/resolvers/zod';
import {
  vendorAddressDetailsFormSchema,
  VendorAdressDetailFormInputs,
  selectStateOptions,
  VendorAddressStateType,
} from '@nbfc-expense-tool/data-store/dashboard';

type AddNewItemProps = {
  onClickClose: () => void;
  onEditItem?: () => void;
  vendorAddressDetails?: VendorAddressStateType;
  submitDetails: (values: VendorAdressDetailFormInputs) => void;
};

const idPrefix = 'add-or-edit-vendor';

export const AddOrEditVendorAddress: React.FC<AddNewItemProps> = ({
  onClickClose,
  submitDetails,
  vendorAddressDetails,
}) => {
  const isEditFlow = Boolean(vendorAddressDetails);
  const scrollViewRef = useRef<HTMLDivElement>(null);
  const registeredAddressRef = useRef<HTMLDivElement>(null);
  const businessAddressRef = useRef<HTMLDivElement>(null);

  const { control, handleSubmit, getValues } =
    useForm<VendorAdressDetailFormInputs>({
      resolver: zodResolver(vendorAddressDetailsFormSchema),
      defaultValues: {
        contactPersonDetails: {
          name: vendorAddressDetails?.contact_person_details?.name || undefined,
          designation:
            vendorAddressDetails?.contact_person_details?.designation ||
            undefined,
          mobile:
            vendorAddressDetails?.contact_person_details?.mobile_number ||
            undefined,
          phone:
            vendorAddressDetails?.contact_person_details?.phone_number ||
            undefined,
          email:
            vendorAddressDetails?.contact_person_details?.email || undefined,
        },
        applicantDetails: {
          sameAsContactPerson:
            JSON.stringify(vendorAddressDetails?.applicant_details) ===
            JSON.stringify(vendorAddressDetails?.contact_person_details),
          name: vendorAddressDetails?.applicant_details?.name || undefined,
          designation:
            vendorAddressDetails?.applicant_details?.designation || undefined,
          mobile:
            vendorAddressDetails?.applicant_details?.mobile_number || undefined,
          phone:
            vendorAddressDetails?.applicant_details?.phone_number || undefined,
          email: vendorAddressDetails?.applicant_details?.email || undefined,
        },
        registeredAddress: {
          addressLine1:
            vendorAddressDetails?.registered_address?.address_line_1 ||
            undefined,
          addressLine2:
            vendorAddressDetails?.registered_address?.address_line_2 ||
            undefined,
          pincode:
            vendorAddressDetails?.registered_address?.pincode || undefined,
          city: vendorAddressDetails?.registered_address?.city || undefined,
          state: vendorAddressDetails?.registered_address?.state || undefined,
          phone:
            vendorAddressDetails?.registered_address?.phone_number || undefined,
          email: vendorAddressDetails?.registered_address?.email || undefined,
        },
        businessAddress: {
          sameAsRegisteredAddress:
            JSON.stringify(vendorAddressDetails?.business_address) ===
            JSON.stringify(vendorAddressDetails?.registered_address),
          addressLine1:
            vendorAddressDetails?.business_address?.address_line_1 || undefined,
          addressLine2:
            vendorAddressDetails?.business_address?.address_line_2 || undefined,
          pincode: vendorAddressDetails?.business_address?.pincode || undefined,
          city: vendorAddressDetails?.business_address?.city || undefined,
          state: vendorAddressDetails?.business_address?.state || undefined,
          phone:
            vendorAddressDetails?.business_address?.phone_number || undefined,
          email: vendorAddressDetails?.business_address?.email || undefined,
        },
        addressProofDoc: vendorAddressDetails?.addressProofDocument
          ? {
              imgSrc: vendorAddressDetails?.addressProofDocument?.imgSrc,
              name: vendorAddressDetails?.addressProofDocument?.name,
              size: vendorAddressDetails?.addressProofDocument?.size || 0,
              type: vendorAddressDetails?.addressProofDocument?.type,
              newDocument: false,
            }
          : undefined,
        isGstRegistered: vendorAddressDetails?.is_gst_registered || false,
        gstNumber: vendorAddressDetails?.gst_number || undefined,
        gstCert: vendorAddressDetails?.gstCertificate
          ? {
              imgSrc: vendorAddressDetails.gstCertificate?.imgSrc,
              name: vendorAddressDetails.gstCertificate?.name,
              size: vendorAddressDetails.gstCertificate?.size || 0,
              type: vendorAddressDetails.gstCertificate?.type,
              newDocument: false,
            }
          : undefined,
        officeStatus: vendorAddressDetails?.office_status || undefined,
      },
    });

  const submitFormDetails = (values: VendorAdressDetailFormInputs) => {
    const addressProofDoc = getValues('addressProofDoc');
    const gstCert = getValues('gstCert');
    values.addressProofDoc = addressProofDoc;
    values.gstCert = gstCert;
    submitDetails({
      ...values,
    });
    onClickClose();
  };

  const showMissingDetailsErrorToast = (
    msg = 'Please fill the mandatory details correctly'
  ) => {
    toastQueue.add(
      {
        title: msg,
        type: 'error',
      },
      {
        timeout: 2000,
      }
    );
  };

  const scrollToTop = () => {
    scrollViewRef.current?.firstElementChild?.scrollIntoView({
      behavior: 'smooth',
    });
  };

  const scrollToBottom = () => {
    scrollViewRef.current?.lastElementChild?.scrollIntoView({
      behavior: 'smooth',
    });
  };

  const scrollBasedOnAddressDetailsFieldError = (
    err: FieldErrors<VendorAdressDetailFormInputs>
  ) => {
    if (err.contactPersonDetails || err.applicantDetails) {
      scrollToTop();
    } else if (err.registeredAddress) {
      registeredAddressRef?.current?.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
      });
    } else if (err.businessAddress) {
      businessAddressRef?.current?.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
      });
    } else {
      scrollToBottom();
    }
    showMissingDetailsErrorToast();
  };

  return (
    <Box>
      <FullScreenHeader
        headingText={isEditFlow ? 'Edit Address' : 'Add Address'}
        onClickCross={onClickClose}
        borderBottomWidth="1"
        borderColor="borderSeparator"
        shadowLevel="s1"
        position="sticky"
        top="0"
        zIndex="50"
      />
      <Stack
        paddingX="5"
        paddingTop="5"
        display="flex"
        flex="1"
        minHeight="screen"
        overflow="auto"
        paddingBottom="20"
      >
        <Box key="addressDetails" ref={scrollViewRef}>
          <Box marginBottom="6">
            <Text variation="t3" marginBottom="3">
              Contact Person Details
            </Text>
            <Inline marginBottom="6">
              <Box flex="1" marginRight="5">
                <Controller
                  control={control}
                  name="contactPersonDetails.name"
                  render={(props) => {
                    const {
                      field: { onChange, onBlur, value },
                      fieldState: { error },
                    } = props;
                    return (
                      <TextInput
                        id={`${idPrefix}-address-details-enter-vendor-name`}
                        label={'Person Name'}
                        fullWidth
                        isRequired
                        placeholder="Enter Full Name"
                        onChange={onChange}
                        onBlur={onBlur}
                        errorMessage={error?.message}
                        defaultValue={
                          value !== undefined ? String(value) : undefined
                        }
                      />
                    );
                  }}
                />
              </Box>
              <Box flex="1" marginRight="5">
                <Controller
                  control={control}
                  name="contactPersonDetails.designation"
                  render={(props) => {
                    const {
                      field: { onChange, onBlur, value },
                      fieldState: { error },
                    } = props;
                    return (
                      <TextInput
                        id={`${idPrefix}-address-details-enter-designation`}
                        label={'Designation'}
                        fullWidth
                        isRequired
                        placeholder="Enter designation in company"
                        onChange={onChange}
                        onBlur={onBlur}
                        errorMessage={error?.message}
                        defaultValue={
                          value !== undefined ? String(value) : undefined
                        }
                      />
                    );
                  }}
                />
              </Box>
              <Box flex="1" marginRight="5" />
            </Inline>
            <Inline>
              <Box flex="1" marginRight="5">
                <Controller
                  control={control}
                  name="contactPersonDetails.mobile"
                  render={(props) => {
                    const {
                      field: { onChange, onBlur, value },
                      fieldState: { error },
                    } = props;
                    return (
                      <TextInput
                        id={`${idPrefix}-address-details-enter-mobile-number`}
                        label={'Mobile Number'}
                        fullWidth
                        isRequired
                        placeholder="Enter 10 digit mobile number"
                        leftIcon={() => (
                          <Text variation="b2" color="textHigh">
                            +91
                          </Text>
                        )}
                        onChange={onChange}
                        onBlur={onBlur}
                        errorMessage={error?.message}
                        defaultValue={
                          value !== undefined ? String(value) : undefined
                        }
                      />
                    );
                  }}
                />
              </Box>
              <Box flex="1" marginRight="5">
                <Controller
                  control={control}
                  name="contactPersonDetails.phone"
                  render={(props) => {
                    const {
                      field: { onChange, onBlur, value },
                      fieldState: { error },
                    } = props;
                    return (
                      <TextInput
                        id={`${idPrefix}-address-details-enter-phone-number`}
                        label={'Phone Number'}
                        fullWidth
                        placeholder="Enter Phone Number with code"
                        onChange={onChange}
                        onBlur={onBlur}
                        errorMessage={error?.message}
                        defaultValue={
                          value !== undefined ? String(value) : undefined
                        }
                      />
                    );
                  }}
                />
              </Box>
              <Box flex="1" marginRight="5">
                <Controller
                  control={control}
                  name="contactPersonDetails.email"
                  render={(props) => {
                    const {
                      field: { onChange, onBlur, value },
                      fieldState: { error },
                    } = props;
                    return (
                      <TextInput
                        id={`${idPrefix}-address-details-enter-email`}
                        label={'Email Id'}
                        fullWidth
                        isRequired
                        placeholder="E.g. abc@xyz.com"
                        onChange={onChange}
                        onBlur={onBlur}
                        errorMessage={error?.message}
                        defaultValue={
                          value !== undefined ? String(value) : undefined
                        }
                      />
                    );
                  }}
                />
              </Box>
            </Inline>
          </Box>
          <Box marginBottom="6">
            <Text variation="t3" marginBottom="2">
              Applicant Details
            </Text>
            <Controller
              control={control}
              name="applicantDetails.sameAsContactPerson"
              render={(props) => {
                const {
                  field: { onChange, value },
                } = props;
                const isSelected = value;
                return (
                  <>
                    <Inline marginBottom={value ? '0' : '3'}>
                      <Checkbox isSelected={isSelected} onChange={onChange} />
                      <Text variation="b2" marginLeft="1.5">
                        Same as contact person details
                      </Text>
                    </Inline>
                    {!value && (
                      <>
                        <Inline marginBottom="6">
                          <Box flex="1" marginRight="5">
                            <Controller
                              control={control}
                              name="applicantDetails.name"
                              render={(props) => {
                                const {
                                  field: { onChange, onBlur, value },
                                  fieldState: { error },
                                } = props;
                                return (
                                  <TextInput
                                    id={`${idPrefix}-address-details-enter-applicant-vendor-name`}
                                    label={'Person Name'}
                                    fullWidth
                                    isRequired
                                    placeholder="Enter Full Name"
                                    onChange={onChange}
                                    onBlur={onBlur}
                                    errorMessage={error?.message}
                                    defaultValue={
                                      value !== undefined
                                        ? String(value)
                                        : undefined
                                    }
                                  />
                                );
                              }}
                            />
                          </Box>
                          <Box flex="1" marginRight="5">
                            <Controller
                              control={control}
                              name="applicantDetails.designation"
                              render={(props) => {
                                const {
                                  field: { onChange, onBlur, value },
                                  fieldState: { error },
                                } = props;
                                return (
                                  <TextInput
                                    id={`${idPrefix}-address-details-enter-applicant-designation`}
                                    label={'Designation'}
                                    fullWidth
                                    isRequired
                                    placeholder="Enter designation in company"
                                    onChange={onChange}
                                    onBlur={onBlur}
                                    errorMessage={error?.message}
                                    defaultValue={
                                      value !== undefined
                                        ? String(value)
                                        : undefined
                                    }
                                  />
                                );
                              }}
                            />
                          </Box>
                          <Box flex="1" marginRight="5" />
                        </Inline>
                        <Inline>
                          <Box flex="1" marginRight="5">
                            <Controller
                              control={control}
                              name="applicantDetails.mobile"
                              render={(props) => {
                                const {
                                  field: { onChange, onBlur, value },
                                  fieldState: { error },
                                } = props;
                                return (
                                  <TextInput
                                    id={`${idPrefix}-address-details-enter-applicant-mobile-number`}
                                    label={'Mobile Number'}
                                    fullWidth
                                    isRequired
                                    placeholder="Enter 10 digit mobile number"
                                    leftIcon={() => (
                                      <Text variation="b2" color="textHigh">
                                        +91
                                      </Text>
                                    )}
                                    onChange={onChange}
                                    onBlur={onBlur}
                                    errorMessage={error?.message}
                                    defaultValue={
                                      value !== undefined
                                        ? String(value)
                                        : undefined
                                    }
                                  />
                                );
                              }}
                            />
                          </Box>
                          <Box flex="1" marginRight="5">
                            <Controller
                              control={control}
                              name="applicantDetails.phone"
                              render={(props) => {
                                const {
                                  field: { onChange, onBlur, value },
                                  fieldState: { error },
                                } = props;
                                return (
                                  <TextInput
                                    id={`${idPrefix}-address-details-enter-applicant-phone-number`}
                                    label={'Phone Number'}
                                    fullWidth
                                    placeholder="Enter Phone Number with code"
                                    onChange={onChange}
                                    onBlur={onBlur}
                                    errorMessage={error?.message}
                                    defaultValue={
                                      value !== undefined
                                        ? String(value)
                                        : undefined
                                    }
                                  />
                                );
                              }}
                            />
                          </Box>
                          <Box flex="1" marginRight="5">
                            <Controller
                              control={control}
                              name="applicantDetails.email"
                              render={(props) => {
                                const {
                                  field: { onChange, onBlur, value },
                                  fieldState: { error },
                                } = props;
                                return (
                                  <TextInput
                                    id={`${idPrefix}-address-details-enter-applicant-email`}
                                    label={'Email Id'}
                                    fullWidth
                                    isRequired
                                    placeholder="E.g. abc@xyz.com"
                                    onChange={onChange}
                                    onBlur={onBlur}
                                    errorMessage={error?.message}
                                    defaultValue={
                                      value !== undefined
                                        ? String(value)
                                        : undefined
                                    }
                                  />
                                );
                              }}
                            />
                          </Box>
                        </Inline>
                      </>
                    )}
                  </>
                );
              }}
            />
          </Box>
          <Box marginBottom="6" ref={registeredAddressRef}>
            <Text variation="t3" marginBottom="3">
              Registered Address
            </Text>
            <Inline marginBottom="6">
              <Box flex="1" marginRight="5">
                <Controller
                  control={control}
                  name="registeredAddress.addressLine1"
                  render={(props) => {
                    const {
                      field: { onChange, onBlur, value },
                      fieldState: { error },
                    } = props;
                    return (
                      <TextInput
                        id={`${idPrefix}-address-details-enter-address-line-1`}
                        label={'Address Line 1'}
                        fullWidth
                        isRequired
                        placeholder="Apartments, Room No., Building Name"
                        onChange={onChange}
                        onBlur={onBlur}
                        errorMessage={error?.message}
                        defaultValue={
                          value !== undefined ? String(value) : undefined
                        }
                      />
                    );
                  }}
                />
              </Box>
              <Box flex="1" marginRight="5">
                <Controller
                  control={control}
                  name="registeredAddress.addressLine2"
                  render={(props) => {
                    const {
                      field: { onChange, onBlur, value },
                      fieldState: { error },
                    } = props;
                    return (
                      <TextInput
                        id={`${idPrefix}-address-details-enter-address-line-2`}
                        label={'Address Line 2'}
                        fullWidth
                        placeholder="Street, Area, Locality"
                        onChange={onChange}
                        onBlur={onBlur}
                        errorMessage={error?.message}
                        defaultValue={
                          value !== undefined ? String(value) : undefined
                        }
                      />
                    );
                  }}
                />
              </Box>
              <Box flex="1" marginRight="5">
                <Controller
                  control={control}
                  name="registeredAddress.pincode"
                  render={(props) => {
                    const {
                      field: { onChange, onBlur, value },
                      fieldState: { error },
                    } = props;
                    return (
                      <TextInput
                        id={`${idPrefix}-address-details-enter-pincode`}
                        label={'Pincode'}
                        fullWidth
                        isRequired
                        placeholder="Enter Pincode"
                        onChange={onChange}
                        onBlur={onBlur}
                        errorMessage={error?.message}
                        defaultValue={
                          value !== undefined ? String(value) : undefined
                        }
                      />
                    );
                  }}
                />
              </Box>
            </Inline>
            <Inline marginBottom="6">
              <Box flex="1" marginRight="5">
                <Controller
                  control={control}
                  name="registeredAddress.city"
                  render={(props) => {
                    const {
                      field: { onChange, onBlur, value },
                      fieldState: { error },
                    } = props;
                    return (
                      <TextInput
                        id={`${idPrefix}-address-details-enter-city`}
                        label={'City'}
                        fullWidth
                        isRequired
                        placeholder="Enter City"
                        onChange={onChange}
                        onBlur={onBlur}
                        errorMessage={error?.message}
                        defaultValue={
                          value !== undefined ? String(value) : undefined
                        }
                      />
                    );
                  }}
                />
              </Box>
              <Box flex="1" marginRight="5">
                <Controller
                  control={control}
                  name="registeredAddress.state"
                  render={(props) => {
                    const {
                      field: { onChange, value },
                      fieldState: { error },
                    } = props;
                    return (
                      <SingleSelect
                        id={`${idPrefix}-address-details-select-state`}
                        options={selectStateOptions}
                        onSave={(option) => {
                          onChange(option.value);
                        }}
                        label="State"
                        placeholder="Select State"
                        isRequired
                        errorMessage={error?.message}
                        value={{ label: value, value: value }}
                      />
                    );
                  }}
                />
              </Box>
              <Box flex="1" marginRight="5">
                <Controller
                  control={control}
                  name="registeredAddress.phone"
                  render={(props) => {
                    const {
                      field: { onChange, onBlur, value },
                      fieldState: { error },
                    } = props;
                    return (
                      <TextInput
                        id={`${idPrefix}-address-details-enter-phone-number`}
                        label={'Phone Number'}
                        fullWidth
                        placeholder="Enter Phone Number with code"
                        onChange={onChange}
                        onBlur={onBlur}
                        errorMessage={error?.message}
                        defaultValue={
                          value !== undefined ? String(value) : undefined
                        }
                      />
                    );
                  }}
                />
              </Box>
            </Inline>
            <Controller
              control={control}
              name="registeredAddress.email"
              render={(props) => {
                const {
                  field: { onChange, onBlur, value },
                  fieldState: { error },
                } = props;
                return (
                  <TextInput
                    id={`${idPrefix}-address-details-email`}
                    label={'Email Id'}
                    placeholder="E.g. abc@xyz.com"
                    onChange={onChange}
                    onBlur={onBlur}
                    errorMessage={error?.message}
                    defaultValue={
                      value !== undefined ? String(value) : undefined
                    }
                  />
                );
              }}
            />
          </Box>
          <Box marginBottom="6" ref={businessAddressRef}>
            <Text variation="t3" marginBottom="3">
              Business Address
            </Text>
            <Controller
              control={control}
              name="businessAddress.sameAsRegisteredAddress"
              render={(props) => {
                const {
                  field: { onChange, value },
                } = props;
                const isSelected = value;
                return (
                  <>
                    <Inline marginBottom={value ? '0' : '3'}>
                      <Checkbox isSelected={isSelected} onChange={onChange} />
                      <Text variation="b2" marginLeft="1.5">
                        Same as registered address
                      </Text>
                    </Inline>
                    {!value && (
                      <>
                        <Inline marginBottom="6">
                          <Box flex="1" marginRight="5">
                            <Controller
                              control={control}
                              name="businessAddress.addressLine1"
                              render={(props) => {
                                const {
                                  field: { onChange, onBlur, value },
                                  fieldState: { error },
                                } = props;
                                return (
                                  <TextInput
                                    id={`${idPrefix}-business-address-details-enter-address-line-1`}
                                    label={'Address Line 1'}
                                    fullWidth
                                    isRequired
                                    placeholder="Apartments, Room No., Building Name"
                                    onChange={onChange}
                                    onBlur={onBlur}
                                    errorMessage={error?.message}
                                    defaultValue={
                                      value !== undefined
                                        ? String(value)
                                        : undefined
                                    }
                                  />
                                );
                              }}
                            />
                          </Box>
                          <Box flex="1" marginRight="5">
                            <Controller
                              control={control}
                              name="businessAddress.addressLine2"
                              render={(props) => {
                                const {
                                  field: { onChange, onBlur, value },
                                  fieldState: { error },
                                } = props;
                                return (
                                  <TextInput
                                    id={`${idPrefix}-business-address-details-enter-address-line-2`}
                                    label={'Address Line 2'}
                                    fullWidth
                                    placeholder="Street, Area, Locality"
                                    onChange={onChange}
                                    onBlur={onBlur}
                                    errorMessage={error?.message}
                                    defaultValue={
                                      value !== undefined
                                        ? String(value)
                                        : undefined
                                    }
                                  />
                                );
                              }}
                            />
                          </Box>
                          <Box flex="1" marginRight="5">
                            <Controller
                              control={control}
                              name="businessAddress.pincode"
                              render={(props) => {
                                const {
                                  field: { onChange, onBlur, value },
                                  fieldState: { error },
                                } = props;
                                return (
                                  <TextInput
                                    id={`${idPrefix}-business-address-details-enter-pincode`}
                                    label={'Pincode'}
                                    fullWidth
                                    isRequired
                                    placeholder="Enter Pincode"
                                    onChange={onChange}
                                    onBlur={onBlur}
                                    errorMessage={error?.message}
                                    defaultValue={
                                      value !== undefined
                                        ? String(value)
                                        : undefined
                                    }
                                  />
                                );
                              }}
                            />
                          </Box>
                        </Inline>
                        <Inline marginBottom="6">
                          <Box flex="1" marginRight="5">
                            <Controller
                              control={control}
                              name="businessAddress.city"
                              render={(props) => {
                                const {
                                  field: { onChange, onBlur, value },
                                  fieldState: { error },
                                } = props;
                                return (
                                  <TextInput
                                    id={`${idPrefix}-business-address-details-enter-city`}
                                    label={'City'}
                                    fullWidth
                                    isRequired
                                    placeholder="Enter City"
                                    onChange={onChange}
                                    onBlur={onBlur}
                                    errorMessage={error?.message}
                                    defaultValue={
                                      value !== undefined
                                        ? String(value)
                                        : undefined
                                    }
                                  />
                                );
                              }}
                            />
                          </Box>
                          <Box flex="1" marginRight="5">
                            <Controller
                              control={control}
                              name="businessAddress.state"
                              render={(props) => {
                                const {
                                  field: { onChange, value },
                                  fieldState: { error },
                                } = props;
                                return (
                                  <SingleSelect
                                    id={`${idPrefix}-business-address-details-select-state`}
                                    options={selectStateOptions}
                                    onSave={(option) => {
                                      onChange(option.value);
                                    }}
                                    label="State"
                                    placeholder="Select State"
                                    isRequired
                                    errorMessage={error?.message}
                                    value={{ label: value, value: value }}
                                  />
                                );
                              }}
                            />
                          </Box>
                          <Box flex="1" marginRight="5">
                            <Controller
                              control={control}
                              name="businessAddress.phone"
                              render={(props) => {
                                const {
                                  field: { onChange, onBlur, value },
                                  fieldState: { error },
                                } = props;
                                return (
                                  <TextInput
                                    id={`${idPrefix}-business-address-details-enter-phone-number`}
                                    label={'Phone Number'}
                                    fullWidth
                                    placeholder="Enter Phone Number with code"
                                    onChange={onChange}
                                    onBlur={onBlur}
                                    errorMessage={error?.message}
                                    defaultValue={
                                      value !== undefined
                                        ? String(value)
                                        : undefined
                                    }
                                  />
                                );
                              }}
                            />
                          </Box>
                        </Inline>
                        <Controller
                          control={control}
                          name="businessAddress.email"
                          render={(props) => {
                            const {
                              field: { onChange, onBlur, value },
                              fieldState: { error },
                            } = props;
                            return (
                              <TextInput
                                id={`${idPrefix}-business-address-details-enter-email`}
                                label={'Email Id'}
                                placeholder="E.g. abc@xyz.com"
                                onChange={onChange}
                                onBlur={onBlur}
                                errorMessage={error?.message}
                                defaultValue={
                                  value !== undefined
                                    ? String(value)
                                    : undefined
                                }
                              />
                            );
                          }}
                        />
                      </>
                    )}
                  </>
                );
              }}
            />
          </Box>
          <Box width="fitContent" marginBottom="6">
            <Controller
              control={control}
              name="addressProofDoc"
              render={(props) => {
                const {
                  field: { onChange, value },
                  fieldState: { error },
                } = props;
                return (
                  <>
                    <Text variation="c2" marginBottom="1">
                      Address Proof
                      <Text marginLeft="0.5" as="span" color="textError">
                        *
                      </Text>
                    </Text>
                    <UploadDocument
                      id={`${idPrefix}-business-address-details-proof`}
                      showDownloadButton={false}
                      onClickSelectDocument={(fileBlobValue, documentInfo) =>
                        onChange({
                          fileBlobValue: fileBlobValue,
                          newDocument: true,
                          ...documentInfo,
                        })
                      }
                      documentInfo={value}
                      handleTempUpload
                      rightIcon={(props, onClick) => (
                        <DeleteIcon
                          {...props}
                          id={`${idPrefix}-business-address-details-proof-delete`}
                          onClick={() => {
                            onClick();
                            onChange(undefined);
                          }}
                          color="iconError"
                          cursor="pointer"
                        />
                      )}
                    />
                    {error?.message && (
                      <Text
                        marginTop="1"
                        borderRadius="md"
                        paddingY="0.5"
                        paddingX="1"
                        display="flex"
                        color="textError"
                        variation="c2"
                        bgColor="surfaceErrorLowest"
                      >
                        {error?.message}
                      </Text>
                    )}
                  </>
                );
              }}
            />
          </Box>
          <Box marginBottom="6">
            <Controller
              control={control}
              name="isGstRegistered"
              render={(props) => {
                const {
                  field: { onChange, value },
                  fieldState: { error },
                } = props;
                return (
                  <>
                    <Inline marginBottom="3">
                      <Box flex="1" marginRight="5">
                        <Text variation="b2">
                          Is GST Registered?
                          <Text marginLeft="0.5" as="span" color="textError">
                            *
                          </Text>
                        </Text>
                        <RadioGroup
                          value={
                            value !== undefined
                              ? value
                                ? '1'
                                : '0'
                              : undefined
                          }
                          onChange={(val) =>
                            onChange(val === '1' ? true : false)
                          }
                        >
                          <Inline gap="6">
                            <Radio
                              value="1"
                              id={`${idPrefix}-gst-registered-yes`}
                            >
                              <Text variation="b2" color="textMedium">
                                Yes
                              </Text>
                            </Radio>
                            <Radio
                              value="0"
                              id={`${idPrefix}-gst-registered-no`}
                            >
                              <Text variation="b2" color="textMedium">
                                No
                              </Text>
                            </Radio>
                          </Inline>
                        </RadioGroup>
                        {error?.message && (
                          <Text
                            marginTop="1"
                            borderRadius="md"
                            paddingY="0.5"
                            paddingX="1"
                            display="flex"
                            color="textError"
                            variation="c2"
                            bgColor="surfaceErrorLowest"
                          >
                            {error?.message}
                          </Text>
                        )}
                      </Box>
                      {value ? (
                        <Box flex="1" marginRight="5">
                          <Controller
                            control={control}
                            name="gstNumber"
                            render={(props) => {
                              const {
                                field: { onChange, onBlur, value },
                                fieldState: { error },
                              } = props;
                              return (
                                <TextInput
                                  id={`${idPrefix}-primary-details-enter-gst-number`}
                                  label={'GST Number'}
                                  fullWidth
                                  isRequired
                                  placeholder="E.g. 22AAAAA0000A1Z5"
                                  onChange={onChange}
                                  onBlur={onBlur}
                                  errorMessage={error?.message}
                                  defaultValue={
                                    value !== undefined
                                      ? String(value)
                                      : undefined
                                  }
                                />
                              );
                            }}
                          />
                        </Box>
                      ) : (
                        <Box flex="1" marginRight="5" />
                      )}
                      <Box flex="1" marginRight="5" />
                    </Inline>
                    {value && (
                      <Box width="fitContent">
                        <Controller
                          control={control}
                          name="gstCert"
                          render={(props) => {
                            const {
                              field: { onChange, value },
                            } = props;
                            return (
                              <>
                                <Text variation="c2" marginBottom="1">
                                  GST Certificate
                                </Text>
                                <UploadDocument
                                  id={`${idPrefix}-primary-details-gst-certificate`}
                                  showDownloadButton={false}
                                  onClickSelectDocument={(
                                    fileBlobValue,
                                    documentInfo
                                  ) =>
                                    onChange({
                                      fileBlobValue: fileBlobValue,
                                      newDocument: true,
                                      ...documentInfo,
                                    })
                                  }
                                  documentInfo={value}
                                  handleTempUpload
                                  rightIcon={(props, onClick) => (
                                    <DeleteIcon
                                      {...props}
                                      id={`${idPrefix}-primary-details-delete-selected-gst-certificate`}
                                      onClick={() => {
                                        onClick();
                                        onChange(undefined);
                                      }}
                                      color="iconError"
                                      cursor="pointer"
                                    />
                                  )}
                                />
                              </>
                            );
                          }}
                        />
                      </Box>
                    )}
                  </>
                );
              }}
            />
          </Box>
          <Box>
            <Controller
              control={control}
              name="officeStatus"
              render={(props) => {
                const {
                  field: { onChange, value },
                  fieldState: { error },
                } = props;
                return (
                  <Inline>
                    <Box flex="1" marginRight="5">
                      <Text variation="b2">
                        Office Status?
                        <Text marginLeft="0.5" as="span" color="textError">
                          *
                        </Text>
                      </Text>
                      <RadioGroup value={value} onChange={onChange}>
                        <Inline gap="6">
                          <Radio
                            value="owned"
                            id={`${idPrefix}-office-status-owned`}
                          >
                            <Text variation="b2" color="textMedium">
                              Owned
                            </Text>
                          </Radio>
                          <Radio
                            value="rented"
                            id={`${idPrefix}-office-status-rented`}
                          >
                            <Text variation="b2" color="textMedium">
                              Rented
                            </Text>
                          </Radio>
                        </Inline>
                      </RadioGroup>
                      {error?.message && (
                        <Text
                          marginTop="1"
                          borderRadius="md"
                          paddingY="0.5"
                          paddingX="1"
                          display="flex"
                          color="textError"
                          variation="c2"
                          bgColor="surfaceErrorLowest"
                        >
                          {error?.message}
                        </Text>
                      )}
                    </Box>
                    <Box flex="1" marginRight="5" />
                    <Box flex="1" marginRight="5" />
                  </Inline>
                );
              }}
            />
          </Box>
        </Box>
      </Stack>
      <Box
        width={'auto'}
        display={'flex'}
        alignItems={'center'}
        justifyContent="end"
        backgroundColor="surfaceDefault"
        paddingY="2"
        paddingX="5"
        borderTopWidth="1"
        borderColor="borderSeparator"
        position="sticky"
        bottom="0"
      >
        <Box marginRight="2">
          <Button
            id={`${idPrefix}-cancel`}
            title="Cancel"
            type="outlined"
            onClick={onClickClose}
          />
        </Box>
        <Box>
          <Button
            id={`${idPrefix}-save`}
            onClick={handleSubmit(
              submitFormDetails,
              scrollBasedOnAddressDetailsFieldError
            )}
            title={'Save Details'}
            type="filled"
          />
        </Box>
      </Box>
    </Box>
  );
};
