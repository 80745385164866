/* eslint-disable @typescript-eslint/no-explicit-any */
import { useRef, useState, useEffect } from 'react';

import { Box } from '../box/box';
import { Stack } from '../stack/stack';
import {
  AddFileIcon,
  DownloadIcon,
  FileIcon,
  PlusIcon,
  ThreeDotsVerticalIcon,
} from '../icons/icons';
import { Text } from '../text/text';
import { Button } from '../button/button';
import { Inline } from '../inline/inline';
import { IconParams } from '../../types';
import { uploadMediaToTemp } from '@nbfc-expense-tool/data-store/utils';

type UploadDocumentProps = {
  showMenu?: boolean;
  type?: 'primary' | 'secondary' | 'addOnlyButton';
  showDownloadButton?: boolean;
  documentInfo?: DocumentFileType;
  onClickSelectDocument?: (
    fileBlobValue: any,
    documentInfo?: DocumentFileType
  ) => void;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  rightIcon?: ({ color, size }: IconParams, onClick: any) => React.ReactElement;
  id: string;
  handleTempUpload?: boolean;
};

export type DocumentFileType = {
  name?: string;
  size?: number;
  type?: string;
  imgSrc?: string;
  id?: number;
  uploadId?: number;
};

const emptyObject: DocumentFileType = {};

export const UploadDocument: React.FC<UploadDocumentProps> = ({
  showMenu = false,
  type = 'primary',
  documentInfo = emptyObject,
  showDownloadButton = true,
  onClickSelectDocument,
  rightIcon,
  id,
  handleTempUpload = false,
}) => {
  const fileInputRef = useRef<any>(null);
  const [imgSource, setImageSource] = useState<string>(
    documentInfo?.imgSrc || ''
  );
  const [documentFile, setDocumentFile] = useState<DocumentFileType | null>(
    documentInfo?.imgSrc ? documentInfo : null
  );
  const [tempUploadLoading, setTempUploadLoading] = useState(false);

  const handleButtonClick = () => {
    fileInputRef.current?.click();
  };

  useEffect(() => {
    if (documentInfo) {
      setDocumentFile(documentInfo);
      if (documentInfo.imgSrc) {
        setImageSource(documentInfo.imgSrc);
      }
    } else {
      setDocumentFile(null);
      setImageSource('');
    }
  }, [documentInfo]);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleFileChangeWithTempUplaod = (event: any) => {
    setTempUploadLoading(true);
    const selectedFile = event.target.files[0];
    const formData = new FormData();
    formData.append('file', selectedFile as any, selectedFile.name);
    uploadMediaToTemp(formData)
      .then((res: any) => {
        setTempUploadLoading(false);
        setDocumentFile(selectedFile);
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const reader: any = new FileReader();
        reader.readAsDataURL(selectedFile);
        reader.onloadend = function (e: any) {
          if (reader.result) {
            setImageSource(reader.result);
            onClickSelectDocument &&
              onClickSelectDocument(selectedFile, {
                name: selectedFile.name,
                size: selectedFile.size,
                type: selectedFile.type,
                imgSrc: reader.result,
                uploadId: res.data.id,
              });
          } else {
            onClickSelectDocument &&
              onClickSelectDocument(selectedFile, {
                name: selectedFile.name,
                size: selectedFile.size,
                type: selectedFile.type,
                uploadId: res.data.id,
              });
          }
        };
      })
      .catch(() => {
        setTempUploadLoading(false);
      });
  };

  const handleFileChange = (event: any) => {
    const selectedFile = event.target.files[0];
    setDocumentFile(selectedFile);
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const reader: any = new FileReader();
    reader.readAsDataURL(selectedFile);
    reader.onloadend = function (e: any) {
      if (reader.result) {
        setImageSource(reader.result);
        onClickSelectDocument &&
          onClickSelectDocument(selectedFile, {
            name: selectedFile.name,
            size: selectedFile.size,
            type: selectedFile.type,
            imgSrc: reader.result,
          });
      } else {
        onClickSelectDocument &&
          onClickSelectDocument(selectedFile, {
            name: selectedFile.name,
            size: selectedFile.size,
            type: selectedFile.type,
          });
      }
    };
  };

  const clearCurrentImage = () => {
    setImageSource('');
    setDocumentFile(null);
  };

  if (imgSource && type !== 'addOnlyButton') {
    if (type === 'secondary') {
      return (
        <Inline alignItems="center">
          {!documentFile?.type?.includes('image') ? (
            <Inline
              as="a"
              href={documentFile?.imgSrc}
              target="_blank"
              download={documentFile?.name}
              alignItems="center"
              justifyContent="center"
              style={{ height: 48, width: 48 }}
              borderRadius="md"
              borderWidth="1"
              borderColor="borderOutline"
            >
              <FileIcon size="4" color="iconLow" />
            </Inline>
          ) : (
            <Box
              as="a"
              href={documentFile?.imgSrc}
              target="_blank"
              download={documentFile?.name}
            >
              <Box
                as="img"
                src={imgSource}
                alt="Test"
                style={{ height: 48, width: 48 }}
                borderRadius="md"
                borderWidth="1"
                borderColor="borderOutline"
              />
            </Box>
          )}
          <Stack display="flex" marginLeft="2" flex="1">
            <Text variation="t4">{documentFile?.name}</Text>
            <Inline marginTop="1">
              {documentFile?.type && (
                <>
                  <Text variation="c2" color="textMedium">
                    {documentFile?.type}
                  </Text>
                  <Text variation="c2" marginX="1" color="textMedium">
                    •
                  </Text>
                </>
              )}
              {documentFile?.size ? (
                <Text variation="c2" color="textMedium">
                  {documentFile?.size / 1024} KB
                </Text>
              ) : null}
            </Inline>
          </Stack>
          {showDownloadButton ? (
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              padding="1"
              marginLeft="2"
              as="a"
              href={documentFile?.imgSrc}
              target="_blank"
              download={documentFile?.name}
            >
              <DownloadIcon size="3" color="iconPrimary" />
            </Box>
          ) : null}
          {rightIcon && (
            <Box alignItems="center" display="flex" marginLeft="2">
              {rightIcon(
                {
                  color: 'iconMedium',
                  size: '3',
                },
                clearCurrentImage
              )}
            </Box>
          )}
        </Inline>
      );
    }
    return (
      <Box
        display="flex"
        alignItems="center"
        paddingRight="3"
        style={{ height: 124, minWidth: 400 }}
        flex="1"
        rounded="md"
        borderWidth="1"
        borderColor="borderOutline"
        paddingY="0"
        id={id}
      >
        {!documentFile?.type?.includes('image') ? (
          <Inline
            as="a"
            href={documentFile?.imgSrc}
            target="_blank"
            download={documentFile?.name}
            alignItems="center"
            justifyContent="center"
            style={{ height: 124, width: 180 }}
            borderRightWidth="1"
            borderColor="borderOutline"
          >
            <FileIcon size="6" color="iconLow" />
          </Inline>
        ) : (
          <Box
            as="a"
            href={documentFile?.imgSrc}
            target="_blank"
            download={documentFile?.name}
          >
            <Box
              as="img"
              src={imgSource}
              alt={documentFile?.name || `Preview_${documentFile?.imgSrc}`}
              style={{ height: 124, width: 180 }}
              borderRightWidth="1"
              borderColor="borderOutline"
            />
          </Box>
        )}
        <Stack display="flex" marginLeft="4.5" paddingY="3" flex="1">
          <Text variation="t4">{documentFile?.name}</Text>
          <Inline>
            {documentFile?.type && (
              <>
                <Text variation="c2" color="textMedium">
                  {getFileType(documentFile?.type)}
                </Text>
                <Text variation="c2" marginX="1" color="textMedium">
                  •
                </Text>
              </>
            )}
            {documentFile?.size ? (
              <Text variation="c2" color="textMedium">
                {documentFile?.size / 1024} KB
              </Text>
            ) : null}
          </Inline>
        </Stack>
        {rightIcon ? (
          <Box alignItems="center" display="flex" marginLeft="4.5">
            {rightIcon(
              {
                color: 'iconMedium',
                size: '3',
              },
              clearCurrentImage
            )}
          </Box>
        ) : showDownloadButton ? (
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            padding="1"
            marginLeft="4.5"
            as="a"
            href={documentFile?.imgSrc}
            target="_blank"
            download={documentFile?.name}
          >
            <DownloadIcon size="3" color="iconPrimary" />
          </Box>
        ) : null}
        {showMenu ? (
          <ThreeDotsVerticalIcon size="3" color="iconMedium" marginLeft="4.5" />
        ) : null}
      </Box>
    );
  }

  if (type === 'addOnlyButton') {
    return (
      <>
        <Button
          id={`${id}-upload-document-add-document-button`}
          title="Add Document"
          type="outlined"
          leftIcon={(props) => <PlusIcon {...props} />}
          onClick={handleButtonClick}
          state={tempUploadLoading ? 'loading' : undefined}
        />
        <Box
          id={`${id}-upload-document-input`}
          as="input"
          type="file"
          ref={fileInputRef}
          onChange={
            handleTempUpload ? handleFileChangeWithTempUplaod : handleFileChange
          }
          display="none"
          accept=".png, .jpg, .jpeg, .pdf"
        />
      </>
    );
  }
  return (
    <Box
      display="flex"
      backgroundColor="surfaceNeutralLowest"
      padding="3"
      rounded="md"
      borderStyle="dashed"
      borderWidth="1"
      borderColor="borderOutline"
      alignItems="center"
      flex="1"
      style={{ height: 76, minWidth: 400 }}
    >
      <AddFileIcon color="iconPrimary" size="6" />
      <Stack display="flex" marginLeft="3" flex="1">
        <Text variation="t4">Upload File or Drag & Drop</Text>
        <Text variation="c2" color="textMedium">
          JPEG, PNG or PDF (upto 10MB)
        </Text>
      </Stack>
      <Box marginLeft="3">
        <Button
          id={`${id}-upload-document-select-file-button`}
          title="Select File"
          type="outlined"
          onClick={handleButtonClick}
          state={tempUploadLoading ? 'loading' : undefined}
        />
      </Box>
      <Box
        as="input"
        type="file"
        ref={fileInputRef}
        onChange={
          handleTempUpload ? handleFileChangeWithTempUplaod : handleFileChange
        }
        display="none"
        accept=".png, .jpg, .jpeg, .pdf"
      />
    </Box>
  );
};

function getFileType(type?: string): string {
  const extractedType = type?.split('/')[1];
  switch (extractedType) {
    case 'pdf':
      return 'PDF';
    case 'png':
      return 'PNG';
    case 'jpg':
    case 'jpeg':
      return 'JPG';
    default:
      return type || 'unknown';
  }
}
