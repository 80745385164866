import {
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from 'react-router-dom';
// eslint-disable-next-line @nx/enforce-module-boundaries
import {
  BaseOption,
  toastQueue,
  useOverlayTriggerState,
} from '@nbfc-expense-tool/ui';
import {
  useCallback,
  useEffect,
  useReducer,
  useRef,
  useState,
  useMemo,
} from 'react';
import { z } from 'zod';
import { FieldErrors, useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  getInactiveVendors,
  getUnApprovedVendorsList,
  usePagination,
  vendorListForFilters,
  VendorsRequestPayload,
  getActiveVendors,
  getVendorTypes,
  CreateOrUpdateVendorPayload,
  createNewVendor,
  IndianStatesAndUTs,
  getBranchListing,
  uploadMediaToTemp,
  vendorDetails,
  updateVendor,
  deactivateVendorApi,
  reactivateVendorApi,
  approveVendor,
  ErrorResponse,
  rejectVendor,
  getActiveVendorsReport,
  getInActiveVendorsReport,
  getUnApprovedVendorsReport,
  isPanNumberCorrect,
  VendorAddressPayloadType,
} from '@nbfc-expense-tool/data-store/utils';
import { VendorDetails, VendorListItem } from './types';
import { checkForSinglePermission } from '@nbfc-expense-tool/data-store/permissions';
import { useUser } from '@nbfc-expense-tool/data-store/auth';
import { phoneValidationSchema } from '@nbfc-expense-tool/data-store/util-general';
import { parsePhoneNumber } from 'libphonenumber-js';

type VENDOR_FOR_FILTER = {
  id: number;
  name: string;
  legal_name: string;
  is_einvoicing_enabled: boolean;
};

type VendorsForFilterResponse = {
  data: VENDOR_FOR_FILTER[];
};

type VendorForFilterState = {
  status: 'in_progress' | 'success' | 'failed';
  error?: string | Error | null;
  vendors?: VENDOR_FOR_FILTER[];
};

type VENDORS_LIST_FOR_FILTER_TYPES =
  | { type: 'FETCHING_VENDORS' }
  | { type: 'FETCHED_VENDORS'; payload: VendorsForFilterResponse }
  | { type: 'FETCHING_VENDORS_FAILED'; payload: Error }
  | { type: 'FETCHING_VENDORS_WITH_FILTERS' };

const initialVendorForFilterList: VendorForFilterState = {
  status: 'in_progress',
  error: null,
  vendors: undefined,
};

const reducer = (
  state: VendorForFilterState,
  action: VENDORS_LIST_FOR_FILTER_TYPES
): VendorForFilterState => {
  switch (action.type) {
    case 'FETCHING_VENDORS':
      return {
        ...state,
        ...initialVendorForFilterList,
      };
    case 'FETCHED_VENDORS':
      return {
        ...state,
        status: 'success',
        error: null,
        vendors: action.payload.data,
      };
    default:
      return state;
  }
};

export function useVendorsForFilter() {
  const [state, dispatch] = useReducer(reducer, initialVendorForFilterList);

  const getVendorForFilters = useCallback(async () => {
    const response = await vendorListForFilters<VendorsForFilterResponse>();
    dispatch({ type: 'FETCHED_VENDORS', payload: response });
  }, []);

  useEffect(() => {
    getVendorForFilters();
  }, [getVendorForFilters]);

  return {
    status: state.status,
    vendors: state.vendors,
  };
}

type VendorsListingResponseType = {
  data: VendorListItem[];
  current_page: number;
  total: number;
  per_page?: number;
};

type VendorsListState = {
  status: 'in_progress' | 'success' | 'failed';
  error?: string | Error | null;
  vendors?: VendorListItem[];
  totalVendors?: number;
  perPage?: number;
  currentPage?: number;
  syncingWithFilers?: boolean;
};

const initialVendorList: VendorsListState = {
  status: 'in_progress',
  error: null,
  vendors: undefined,
  syncingWithFilers: false,
};

type VENDORS_LIST_TYPES =
  | { type: 'FETCHING_VENDORS' }
  | { type: 'FETCHED_VENDORS'; payload: VendorsListingResponseType }
  | { type: 'FETCHING_VENDORS_FAILED'; payload: Error }
  | { type: 'FETCHING_VENDORS_WITH_FILTERS' };

const vendorListingReducer = (
  state: VendorsListState,
  action: VENDORS_LIST_TYPES
): VendorsListState => {
  switch (action.type) {
    case 'FETCHING_VENDORS':
      return {
        ...state,
        ...initialVendorList,
      };
    case 'FETCHING_VENDORS_WITH_FILTERS':
      return {
        ...state,
        error: null,
        syncingWithFilers: true,
      };
    case 'FETCHED_VENDORS':
      return {
        ...state,
        status: 'success',
        error: null,
        syncingWithFilers: false,
        currentPage: action.payload.current_page,
        totalVendors: action.payload.total,
        vendors: action.payload.data,
        perPage: action.payload.per_page,
      };
    case 'FETCHING_VENDORS_FAILED':
      return {
        ...state,
        status: 'failed',
        error: action.payload,
        syncingWithFilers: false,
      };
    default:
      return state;
  }
};

type VendorFilters = {
  branches?: string[];
  type?: string[];
  registrationType?: string[];
  q?: string;
};

const initialVendorFilters: VendorFilters = {};

export function useInactiveVendors() {
  const [state, dispatch] = useReducer(vendorListingReducer, initialVendorList);
  const [shouldApiCall, setShouldApiCall] = useState<boolean>(true);
  const [reportLoading, setReportLoading] = useState<boolean>(false);

  const {
    current,
    lastPage,
    canGoBack,
    canGoNext,
    perPage,
    nextPage,
    previousPage,
    resetPagination,
  } = usePagination({
    perPage: 10,
    totalItems: state.totalVendors || 0,
  });

  const { getValues, setValue } = useForm<VendorFilters>({
    defaultValues: initialVendorFilters,
  });

  const params = getValues();

  const exportExcel = useCallback(async () => {
    try {
      const { branches, type, registrationType, q } = params;
      const constructedPayload: VendorsRequestPayload = {};
      if (registrationType?.length) {
        constructedPayload['company_reg_type'] = registrationType;
      }
      if (branches?.length) {
        constructedPayload['branch_id'] = branches;
      }
      if (type?.length) {
        constructedPayload['vendor_type_id'] = type;
      }
      if (q?.length) {
        constructedPayload['q'] = q;
      }
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const data: any = await getInActiveVendorsReport(constructedPayload, {
        responseType: 'blob',
        headers: {
          'Content-Type':
            'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
          Accept: '*/*',
        },
      });
      const linkElement = document.createElement('a');
      const url = URL.createObjectURL(data);
      linkElement.href = url;
      linkElement.download = `InActiveVendors.xlsx`;
      document.body.appendChild(linkElement);
      linkElement.click();
      setTimeout(function () {
        document.body.removeChild(linkElement);
        window.URL.revokeObjectURL(url);
      }, 0);
      setReportLoading(false);
    } catch (err) {
      const error = err as ErrorResponse;
      toastQueue.add(
        {
          title: error.message || 'Something Went Wrong! Please try again',
          type: 'error',
        },
        {
          timeout: 2000,
        }
      );
      setReportLoading(false);
    }
  }, [params]);

  const getInactiveVendorsList = useCallback(async () => {
    try {
      const { q, branches, type, registrationType } = params;
      const constructedPayload: VendorsRequestPayload = {};
      if (registrationType?.length) {
        constructedPayload['company_reg_type'] = registrationType;
      }
      if (branches?.length) {
        constructedPayload['branch_id'] = branches;
      }
      if (type?.length) {
        constructedPayload['vendor_type_id'] = type;
      }
      if (current) {
        constructedPayload['page'] = current;
      }
      if (q?.length) {
        constructedPayload['q'] = q;
      }
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const response: any = await getInactiveVendors(constructedPayload);
      dispatch({ type: 'FETCHED_VENDORS', payload: response });
    } catch (err) {
      const error = err as Error;
      dispatch({ type: 'FETCHING_VENDORS_FAILED', payload: error });
    }
  }, [current, params]);

  useEffect(() => {
    if (shouldApiCall) {
      getInactiveVendorsList();
      setShouldApiCall(false);
    }
  }, [getInactiveVendorsList, shouldApiCall]);

  const handlePageChange = (type: 'next' | 'previous' | 'reset') => {
    switch (type) {
      case 'next':
        nextPage();
        setShouldApiCall(true);
        dispatch({ type: 'FETCHING_VENDORS_WITH_FILTERS' });
        break;
      case 'previous':
        previousPage();
        setShouldApiCall(true);
        dispatch({ type: 'FETCHING_VENDORS_WITH_FILTERS' });
    }
  };

  function handleParamChange(key: keyof VendorFilters, value: string[]) {
    switch (key) {
      case 'branches':
        resetPagination();
        setShouldApiCall(true);
        setValue('branches', value);
        dispatch({ type: 'FETCHING_VENDORS_WITH_FILTERS' });
        break;
      case 'type':
        resetPagination();
        setShouldApiCall(true);
        setValue('type', value);
        dispatch({ type: 'FETCHING_VENDORS_WITH_FILTERS' });
        break;
      case 'registrationType':
        resetPagination();
        setShouldApiCall(true);
        setValue('registrationType', value);
        dispatch({ type: 'FETCHING_VENDORS_WITH_FILTERS' });
        break;
      case 'q':
        resetPagination();
        setShouldApiCall(true);
        setValue('q', value[0]);
        dispatch({ type: 'FETCHING_VENDORS_WITH_FILTERS' });
        break;
    }
  }

  function resetFilters() {
    resetPagination();
    setValue('branches', undefined);
    setValue('type', undefined);
    setValue('registrationType', undefined);
    setShouldApiCall(true);
    dispatch({ type: 'FETCHING_VENDORS' });
    setValue('q', undefined);
  }

  const hasAppliedFilters = useMemo(() => {
    const { type, branches, registrationType, q } = params;
    return Boolean(
      branches?.length || type?.length || registrationType?.length || q?.length
    );
  }, [params]);

  return {
    error: state.error,
    currentPage: current,
    lastPage,
    canGoBack,
    canGoNext,
    perPage,
    params,
    hasAppliedFilters,
    loading: state.status,
    vendors: state.vendors,
    totalVendors: state.totalVendors,
    syncingData: state.syncingWithFilers,

    nextPage,
    previousPage,
    resetFilters,
    handlePageChange,
    handleParamChange,
    exportExcel,
    reportLoading,
  };
}

export function useUnapprovedVendors() {
  const [state, dispatch] = useReducer(vendorListingReducer, initialVendorList);
  const [shouldApiCall, setShouldApiCall] = useState<boolean>(true);
  const [reportLoading, setReportLoading] = useState<boolean>(false);

  const {
    current,
    lastPage,
    canGoBack,
    canGoNext,
    perPage,
    nextPage,
    previousPage,
    resetPagination,
  } = usePagination({
    perPage: 10,
    totalItems: state.totalVendors || 0,
  });

  const { getValues, setValue } = useForm<VendorFilters>({
    defaultValues: initialVendorFilters,
  });

  const params = getValues();

  const exportExcel = useCallback(async () => {
    try {
      const { branches, type, registrationType, q } = params;
      const constructedPayload: VendorsRequestPayload = {};
      if (registrationType?.length) {
        constructedPayload['company_reg_type'] = registrationType;
      }
      if (branches?.length) {
        constructedPayload['branch_id'] = branches;
      }
      if (type?.length) {
        constructedPayload['vendor_type_id'] = type;
      }
      if (q?.length) {
        constructedPayload['q'] = q;
      }
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const data: any = await getUnApprovedVendorsReport(constructedPayload, {
        responseType: 'blob',
        headers: {
          'Content-Type':
            'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
          Accept: '*/*',
        },
      });
      const linkElement = document.createElement('a');
      const url = URL.createObjectURL(data);
      linkElement.href = url;
      linkElement.download = `UnApprovedVendors.xlsx`;
      document.body.appendChild(linkElement);
      linkElement.click();
      setTimeout(function () {
        document.body.removeChild(linkElement);
        window.URL.revokeObjectURL(url);
      }, 0);
      setReportLoading(false);
    } catch (err) {
      const error = err as ErrorResponse;
      toastQueue.add(
        {
          title: error.message || 'Something Went Wrong! Please try again',
          type: 'error',
        },
        {
          timeout: 2000,
        }
      );
      setReportLoading(false);
    }
  }, [params]);

  const getInactiveVendorsList = useCallback(async () => {
    try {
      const { q, branches, type, registrationType } = params;
      const constructedPayload: VendorsRequestPayload = {};
      if (registrationType?.length) {
        constructedPayload['company_reg_type'] = registrationType;
      }
      if (branches?.length) {
        constructedPayload['branch_id'] = branches;
      }
      if (type?.length) {
        constructedPayload['vendor_type_id'] = type;
      }
      if (current) {
        constructedPayload['page'] = current;
      }
      if (q?.length) {
        constructedPayload['q'] = q;
      }
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const response: any = await getUnApprovedVendorsList(constructedPayload);
      dispatch({ type: 'FETCHED_VENDORS', payload: response });
    } catch (err) {
      const error = err as Error;
      dispatch({ type: 'FETCHING_VENDORS_FAILED', payload: error });
    }
  }, [current, params]);

  useEffect(() => {
    if (shouldApiCall) {
      getInactiveVendorsList();
      setShouldApiCall(false);
    }
  }, [getInactiveVendorsList, shouldApiCall]);

  const handlePageChange = (type: 'next' | 'previous' | 'reset') => {
    switch (type) {
      case 'next':
        nextPage();
        setShouldApiCall(true);
        dispatch({ type: 'FETCHING_VENDORS_WITH_FILTERS' });
        break;
      case 'previous':
        previousPage();
        setShouldApiCall(true);
        dispatch({ type: 'FETCHING_VENDORS_WITH_FILTERS' });
    }
  };

  function handleParamChange(key: keyof VendorFilters, value: string[]) {
    switch (key) {
      case 'branches':
        resetPagination();
        setShouldApiCall(true);
        setValue('branches', value);
        dispatch({ type: 'FETCHING_VENDORS_WITH_FILTERS' });
        break;
      case 'type':
        resetPagination();
        setShouldApiCall(true);
        setValue('type', value);
        dispatch({ type: 'FETCHING_VENDORS_WITH_FILTERS' });
        break;
      case 'registrationType':
        resetPagination();
        setShouldApiCall(true);
        setValue('registrationType', value);
        dispatch({ type: 'FETCHING_VENDORS_WITH_FILTERS' });
        break;
      case 'q':
        resetPagination();
        setShouldApiCall(true);
        setValue('q', value[0]);
        dispatch({ type: 'FETCHING_VENDORS_WITH_FILTERS' });
        break;
    }
  }

  function resetFilters() {
    resetPagination();
    setValue('branches', undefined);
    setValue('type', undefined);
    setValue('registrationType', undefined);
    setShouldApiCall(true);
    dispatch({ type: 'FETCHING_VENDORS' });
    setValue('q', undefined);
  }

  const hasAppliedFilters = useMemo(() => {
    const { type, branches, registrationType, q } = params;
    return Boolean(
      branches?.length || type?.length || registrationType?.length || q?.length
    );
  }, [params]);

  return {
    error: state.error,
    currentPage: current,
    lastPage,
    canGoBack,
    canGoNext,
    perPage,
    params,
    hasAppliedFilters,
    loading: state.status,
    vendors: state.vendors,
    totalVendors: state.totalVendors,
    syncingData: state.syncingWithFilers,

    nextPage,
    previousPage,
    resetFilters,
    handlePageChange,
    handleParamChange,
    exportExcel,
    reportLoading,
  };
}

type DateFilter = {
  label: string;
  value: string;
  dates?: [Date, Date];
};

type ActiveVendorFilters = {
  dateFilter?: DateFilter;
  branches?: string[];
  type?: string[];
  registrationType?: string[];
  q?: string;
};

const initialActiveVendorFilters: ActiveVendorFilters = {
  dateFilter: {
    label: 'All Time',
    value: 'all',
  },
};

export function useActiveVendors(mandatoryFilters: ActiveVendorFilters = {}) {
  const [state, dispatch] = useReducer(vendorListingReducer, initialVendorList);
  const [shouldApiCall, setShouldApiCall] = useState<boolean>(true);
  const [reportLoading, setReportLoading] = useState<boolean>(false);

  const {
    current,
    lastPage,
    canGoBack,
    canGoNext,
    perPage,
    nextPage,
    previousPage,
    resetPagination,
  } = usePagination({
    perPage: 10,
    totalItems: state.totalVendors || 0,
  });

  const { getValues, setValue } = useForm<ActiveVendorFilters>({
    defaultValues: initialActiveVendorFilters,
  });

  const params = getValues();

  const exportExcel = useCallback(async () => {
    try {
      const { branches, type, registrationType, dateFilter, q } = params;
      const constructedPayload: VendorsRequestPayload = {};
      if (dateFilter?.dates) {
        constructedPayload['created_after'] = dateFilter.dates[0].toISOString();
        constructedPayload['created_before'] =
          dateFilter.dates[1].toISOString();
      }
      if (registrationType?.length) {
        constructedPayload['company_reg_type'] = registrationType;
      }
      if (mandatoryFilters.branches?.length) {
        constructedPayload['branch_id'] = mandatoryFilters.branches;
      } else if (branches?.length) {
        constructedPayload['branch_id'] = branches;
      }

      if (type?.length) {
        constructedPayload['vendor_type_id'] = type;
      }
      if (q?.length) {
        constructedPayload['q'] = q;
      }
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const data: any = await getActiveVendorsReport(constructedPayload, {
        responseType: 'blob',
        headers: {
          'Content-Type':
            'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
          Accept: '*/*',
        },
      });
      const linkElement = document.createElement('a');
      const url = URL.createObjectURL(data);
      linkElement.href = url;
      linkElement.download = `ActiveVendors.xlsx`;
      document.body.appendChild(linkElement);
      linkElement.click();
      setTimeout(function () {
        document.body.removeChild(linkElement);
        window.URL.revokeObjectURL(url);
      }, 0);
      setReportLoading(false);
    } catch (err) {
      const error = err as ErrorResponse;
      toastQueue.add(
        {
          title: error.message || 'Something Went Wrong! Please try again',
          type: 'error',
        },
        {
          timeout: 2000,
        }
      );
      setReportLoading(false);
    }
  }, [params, mandatoryFilters.branches]);

  const getInactiveVendorsList = useCallback(async () => {
    try {
      const { branches, type, registrationType, dateFilter, q } = params;
      const constructedPayload: VendorsRequestPayload = {};
      if (current) {
        constructedPayload['page'] = current;
      }
      if (dateFilter?.dates) {
        constructedPayload['created_after'] = dateFilter.dates[0].toISOString();
        constructedPayload['created_before'] =
          dateFilter.dates[1].toISOString();
      }
      if (registrationType?.length) {
        constructedPayload['company_reg_type'] = registrationType;
      }
      if (mandatoryFilters.branches?.length) {
        constructedPayload['branch_id'] = mandatoryFilters.branches;
      } else if (branches?.length) {
        constructedPayload['branch_id'] = branches;
      }

      if (type?.length) {
        constructedPayload['vendor_type_id'] = type;
      }
      if (q?.length) {
        constructedPayload['q'] = q;
      }
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const response: any = await getActiveVendors(constructedPayload);
      dispatch({ type: 'FETCHED_VENDORS', payload: response });
    } catch (err) {
      const error = err as Error;
      dispatch({ type: 'FETCHING_VENDORS_FAILED', payload: error });
    }
  }, [current, mandatoryFilters.branches, params]);

  useEffect(() => {
    if (shouldApiCall) {
      getInactiveVendorsList();
      setShouldApiCall(false);
    }
  }, [getInactiveVendorsList, shouldApiCall]);

  const handlePageChange = (type: 'next' | 'previous' | 'reset') => {
    switch (type) {
      case 'next':
        nextPage();
        setShouldApiCall(true);
        dispatch({ type: 'FETCHING_VENDORS_WITH_FILTERS' });
        break;
      case 'previous':
        previousPage();
        setShouldApiCall(true);
        dispatch({ type: 'FETCHING_VENDORS_WITH_FILTERS' });
    }
  };

  function handleParamChange(key: keyof VendorFilters, value: string[]) {
    switch (key) {
      case 'branches':
        resetPagination();
        setShouldApiCall(true);
        setValue('branches', value);
        dispatch({ type: 'FETCHING_VENDORS_WITH_FILTERS' });
        break;
      case 'type':
        resetPagination();
        setShouldApiCall(true);
        setValue('type', value);
        dispatch({ type: 'FETCHING_VENDORS_WITH_FILTERS' });
        break;
      case 'registrationType':
        resetPagination();
        setShouldApiCall(true);
        setValue('registrationType', value);
        dispatch({ type: 'FETCHING_VENDORS_WITH_FILTERS' });
        break;
      case 'q':
        resetPagination();
        setShouldApiCall(true);
        setValue('q', value[0]);
        dispatch({ type: 'FETCHING_VENDORS_WITH_FILTERS' });
        break;
    }
  }

  function resetFilters() {
    resetPagination();
    setValue('branches', undefined);
    setValue('type', undefined);
    setValue('registrationType', undefined);
    setShouldApiCall(true);
    dispatch({ type: 'FETCHING_VENDORS' });
    setValue('q', undefined);
  }

  function handleDateChange(values: DateFilter) {
    resetPagination();
    setShouldApiCall(true);
    setValue('dateFilter', values);
    dispatch({ type: 'FETCHING_VENDORS_WITH_FILTERS' });
  }

  const hasAppliedFilters = useMemo(() => {
    const { type, branches, registrationType, q, dateFilter } = params;
    return Boolean(
      branches?.length ||
        type?.length ||
        registrationType?.length ||
        q?.length ||
        dateFilter?.dates
    );
  }, [params]);

  return {
    error: state.error,
    currentPage: current,
    lastPage,
    canGoBack,
    canGoNext,
    perPage,
    params,
    hasAppliedFilters,
    loading: state.status,
    vendors: state.vendors,
    totalVendors: state.totalVendors,
    syncingData: state.syncingWithFilers,

    nextPage,
    previousPage,
    resetFilters,
    handlePageChange,
    handleParamChange,
    handleDateChange,
    exportExcel,
    reportLoading,
  };
}

export type VENDOR_TYPES_FOR_FILTER = {
  id: number;
  name: string;
};

type VendorTypesForFilterResponse = {
  data: VENDOR_TYPES_FOR_FILTER[];
};

type VendorTypesForFilterState = {
  status: 'in_progress' | 'success' | 'failed';
  error?: string | Error | null;
  vendorTypes?: VENDOR_TYPES_FOR_FILTER[];
};

type VENDOR_TYPES_LIST_FOR_FILTER_TYPES =
  | { type: 'FETCHING_VENDOR_TYPES' }
  | { type: 'FETCHED_VENDOR_TYPES'; payload: VendorTypesForFilterResponse }
  | { type: 'FETCHING_VENDOR_TYPES_FAILED'; payload: Error };

const initialVendorTypesForFilterList: VendorTypesForFilterState = {
  status: 'in_progress',
  error: null,
  vendorTypes: undefined,
};

const vendorTypesListReducer = (
  state: VendorTypesForFilterState,
  action: VENDOR_TYPES_LIST_FOR_FILTER_TYPES
): VendorTypesForFilterState => {
  switch (action.type) {
    case 'FETCHING_VENDOR_TYPES':
      return {
        ...state,
        ...initialVendorTypesForFilterList,
      };
    case 'FETCHED_VENDOR_TYPES':
      return {
        ...state,
        status: 'success',
        error: null,
        vendorTypes: action.payload.data,
      };
    default:
      return state;
  }
};

export function useVendorTypesForFilter() {
  const [state, dispatch] = useReducer(
    vendorTypesListReducer,
    initialVendorTypesForFilterList
  );

  const getVendorTypesForFilters = useCallback(async () => {
    const response: any = await getVendorTypes();
    dispatch({ type: 'FETCHED_VENDOR_TYPES', payload: response });
  }, []);

  useEffect(() => {
    getVendorTypesForFilters();
  }, [getVendorTypesForFilters]);

  return {
    status: state.status,
    vendorTypes: state.vendorTypes,
  };
}

type VendorDetailsState = {
  status: 'in_progress' | 'success' | 'failed';
  error?: string | Error | null;
  vendor?: VendorDetails;
};

const initialVendorDetails: VendorDetailsState = {
  status: 'in_progress',
  error: null,
  vendor: undefined,
};

type VENDOR_DETAILS_TYPES =
  | { type: 'FETCHING_VENDOR' }
  | { type: 'FETCHED_VENDOR'; payload: VendorDetails }
  | { type: 'FETCHING_VENDOR_FAILED'; payload: Error };

const vendorDetailsReducer = (
  state: VendorDetailsState,
  action: VENDOR_DETAILS_TYPES
): VendorDetailsState => {
  switch (action.type) {
    case 'FETCHING_VENDOR':
      return {
        ...state,
        ...initialVendorDetails,
      };
    case 'FETCHED_VENDOR':
      return {
        ...state,
        status: 'success',
        error: null,
        vendor: action.payload,
      };
    case 'FETCHING_VENDOR_FAILED':
      return {
        ...state,
        status: 'failed',
        error: action.payload,
      };
    default:
      return state;
  }
};

export function useVendorDetails(vendorId: string) {
  const [state, dispatch] = useReducer(
    vendorDetailsReducer,
    initialVendorDetails
  );
  const { user } = useUser();
  const navigate = useNavigate();

  const [shouldApiCall, setShouldApiCall] = useState<boolean>(true);
  const [
    isReactivateConfirmationDialogVisible,
    setIsReactivateConfirmationDialogVisible,
  ] = useState<boolean>(false);
  const [isReactivateVendorApiLoading, setIsReactivateVendorApiLoading] =
    useState<boolean>(false);

  const hasReactivateVendorPermission = useMemo(() => {
    return checkForSinglePermission(
      'createVendor',
      user?.roles[0]?.permissions || null
    );
  }, [user?.roles]);

  const getInvoiceDetails = useCallback(async () => {
    try {
      const response = await vendorDetails<{ data: VendorDetails }>(vendorId);
      dispatch({ type: 'FETCHED_VENDOR', payload: response.data });
    } catch (e) {
      const error = e as Error;
      dispatch({ type: 'FETCHING_VENDOR_FAILED', payload: error });
    }
  }, [vendorId]);

  useEffect(() => {
    if (shouldApiCall) {
      setShouldApiCall(false);
      getInvoiceDetails();
    }
  }, [shouldApiCall, getInvoiceDetails]);

  function reload() {
    setShouldApiCall(true);
  }

  const closeReactivateVendorConfirmationDialog = () => {
    setIsReactivateConfirmationDialogVisible(false);
  };

  const openReactivateVendorConfirmationDialog = () => {
    setIsReactivateConfirmationDialogVisible(true);
  };

  const reactivateVendor = async () => {
    closeReactivateVendorConfirmationDialog();
    setIsReactivateVendorApiLoading(true);
    try {
      await reactivateVendorApi(state.vendor?.id as number);
      toastQueue.add(
        {
          title: `Vendor ${state.vendor?.name} reactivated successfully`,
          type: 'success',
        },
        {
          timeout: 2000,
        }
      );
      navigate(-2);
    } catch {
      toastQueue.add(
        {
          title: 'Something went wrong, Please try again',
          type: 'error',
        },
        {
          timeout: 2000,
        }
      );
    } finally {
      setIsReactivateVendorApiLoading(false);
    }
    return;
  };

  return {
    ...state,
    hasReactivateVendorPermission,
    reload,
    reactivateVendor,
    openReactivateVendorConfirmationDialog,
    closeReactivateVendorConfirmationDialog,
    isReactivateConfirmationDialogVisible,
    isReactivateVendorApiLoading,
  };
}

export type AddOrEditVendorFormStates =
  | 'primaryDetails'
  | 'addressDetails'
  | 'paymentDetails'
  | 'links';

type DocumentFileType = {
  name?: string;
  size?: number;
  type?: string;
  imgSrc?: string;
  fileBlobValue?: Blob;
  newDocument: boolean;
  uploadId?: number;
};

type VendorPrimaryDetailFormInputs = {
  legalName: string;
  displayName: string;
  vendorType: string;
  registrationType: string;
  registrationCert: DocumentFileType;
  branchIds: string[];
  isEInvoicingEnabled: boolean;
  panNumber: string;
  tanNumber: string;
  cinNumber: string;
  isDpiitCertified: boolean;
  dpiitNumber: string;
  dpiitCert?: DocumentFileType;
  isGstRegistered: boolean;
  gstNumber: string;
  gstCert?: DocumentFileType;
  isMsmeRegistered: boolean;
  msmeNumber: string;
  msmeCert?: DocumentFileType;
  pancard?: DocumentFileType;
};

export type VendorAdressDetailFormInputs = {
  contactPersonDetails: {
    name: string;
    designation: string;
    mobile: string;
    phone: string;
    email: string;
  };
  applicantDetails: {
    sameAsContactPerson: boolean;
    name: string;
    designation: string;
    mobile: string;
    phone: string;
    email: string;
  };
  registeredAddress: {
    addressLine1: string;
    addressLine2: string;
    pincode: string;
    city: string;
    state: string;
    phone: string;
    email?: string;
  };
  businessAddress: {
    sameAsRegisteredAddress: boolean;
    addressLine1: string;
    addressLine2: string;
    pincode: string;
    city: string;
    state: string;
    phone: string;
    email?: string;
  };
  addressProofDoc: DocumentFileType;
  isGstRegistered: boolean;
  gstNumber: string;
  gstCert?: DocumentFileType;
  officeStatus: 'owned' | 'rented';
};

export type VendorPaymentDetailFormInputs = {
  accountHolderName: string;
  accountNumber: string;
  accountType: 'saving' | 'current';
  bankName: string;
  branchName: string;
  ifscCode: string;
  micrCode: string;
  swiftCode: string;
  cancelledChequeCopy: DocumentFileType;
};

type VendorLinkFormInputs = {
  website?: string;
  facebook?: string;
  instagram?: string;
  linkedIn?: string;
  twitter?: string;
  others?: string;
};

export const selectStateOptions = IndianStatesAndUTs.map((stateOrUt) => {
  return {
    label: stateOrUt,
    value: stateOrUt,
  };
});

export const vendorRegistrationTypeOptions = [
  {
    label: 'Cooperative',
    value: 'co_operative',
  },
  {
    label: 'HUF',
    value: 'huf',
  },
  {
    label: 'Individual',
    value: 'individual',
  },
  {
    label: 'LLP',
    value: 'llp',
  },
  {
    label: 'One Person Company',
    value: 'one_person_company',
  },
  {
    label: 'Partnership',
    value: 'partnership',
  },
  {
    label: 'Private Limited',
    value: 'private_limited',
  },
  {
    label: 'Sole Proprietorship',
    value: 'proprietorship',
  },
  {
    label: 'Public Limited',
    value: 'limited_co',
  },
  {
    label: 'Public Sector Unit',
    value: 'public_sector_unit',
  },
  {
    label: 'Unlimited Company',
    value: 'unlimited_company',
  },
  {
    label: 'Government Body',
    value: 'government_body',
  },
];

export const statusFilterOptions = [
  {
    value: 'approved',
    label: 'Approved',
  },
  {
    value: 'under_review',
    label: 'Under Review',
  },
  {
    value: 'returned',
    label: 'Returned',
  },
  {
    value: 'rejected',
    label: 'Rejected',
  },
];

const requiredStringValidation = z
  .string({
    required_error: 'This field is required',
  })
  .max(255)
  .nonempty({
    message: 'This field is required',
  });

const requiredEmailValidation = z
  .string({
    required_error: 'This field is required',
  })
  .email({
    message: 'Please enter a valid email address',
  });

const vendorPrimaryDetailsFormSchema = z
  .object({
    legalName: requiredStringValidation,
    displayName: requiredStringValidation,
    vendorType: requiredStringValidation,
    registrationType: requiredStringValidation,
    registrationCert: z.object(
      {
        imgSrc: z.string(),
        newDocument: z.boolean(),
      },
      {
        required_error: 'Please upload Company Registration Certificate',
      }
    ),
    pancard: z
      .object({
        imgSrc: z.string({
          required_error: 'Invalid Document',
        }),
        newDocument: z.boolean(),
      })
      .optional(),
    branchIds: z
      .array(z.string(), {
        required_error: 'Please select at least one branch',
      })
      .nonempty({
        message: 'Please select at least one branch',
      }),
    isEInvoicingEnabled: z.boolean({
      required_error: 'Please choose an option',
    }),
    panNumber: z
      .string({
        required_error: 'This field is required',
      })
      .regex(
        new RegExp('^[a-zA-Z]{5}[0-9]{4}[a-zA-Z]{1}$'),
        'Please enter a valid PAN Number'
      ),
    tanNumber: z.string().optional(),
    cinNumber: z.string().optional(),
    isDpiitCertified: z.boolean({
      required_error: 'Please choose an option',
    }),
    dpiitNumber: z.string().optional(),
    dpiitCert: z
      .object({
        imgSrc: z.string({
          required_error: 'Invalid Document',
        }),
        newDocument: z.boolean(),
      })
      .optional(),
    isMsmeRegistered: z.boolean({
      required_error: 'Please choose an option',
    }),
    msmeNumber: z.string().optional(),
    msmeCert: z
      .object({
        imgSrc: z.string({
          required_error: 'Invalid Document',
        }),
        newDocument: z.boolean(),
      })
      .optional(),
  })
  .superRefine((data, context) => {
    if (
      data.tanNumber &&
      !data.tanNumber.match(new RegExp('^[a-zA-Z]{4}[0-9]{5}[a-zA-Z]{1}$'))
    ) {
      context.addIssue({
        code: z.ZodIssueCode.custom,
        message: 'Please enter a valid TAN Number',
        path: ['tanNumber'],
      });
    }
    if (
      data.registrationType &&
      data.panNumber &&
      data.panNumber.match(new RegExp('^[a-zA-Z]{5}[0-9]{4}[a-zA-Z]{1}$'))
    ) {
      if (!isPanNumberCorrect(data.registrationType, data.panNumber)) {
        context.addIssue({
          code: z.ZodIssueCode.custom,
          message:
            'Please enter a PAN Number which belongs to selected reg. type',
          path: ['panNumber'],
        });
      }
      if (data.legalName) {
        const legalNameSplitArray = data.legalName.split(' ');
        const legalNameLength = legalNameSplitArray.length;
        if (
          data.registrationType === 'individual' ||
          data.registrationType === 'proprietorship' ||
          data.registrationType === 'one_person_company'
        ) {
          if (
            legalNameSplitArray[legalNameLength - 1][0].toLowerCase() !==
            data.panNumber[4].toLowerCase()
          ) {
            context.addIssue({
              code: z.ZodIssueCode.custom,
              message: `The fifth character should match first character of vendor's last name`,
              path: ['panNumber'],
            });
          }
        } else {
          if (
            data.legalName[0].toLowerCase() !== data.panNumber[4].toLowerCase()
          ) {
            context.addIssue({
              code: z.ZodIssueCode.custom,
              message: `The fifth character should match first character of vendor's legal name`,
              path: ['panNumber'],
            });
          }
        }
      }
    }
    if (
      data.cinNumber &&
      !data.cinNumber.match(
        new RegExp(
          '^([LUu]{1})([0-9]{5})([A-Za-z]{2})([0-9]{4})([A-Za-z]{3})([0-9]{6})$'
        )
      )
    ) {
      context.addIssue({
        code: z.ZodIssueCode.custom,
        message: 'Please enter a valid CIN Number',
        path: ['cinNumber'],
      });
    }
    if (data.isDpiitCertified && !data.dpiitNumber) {
      context.addIssue({
        code: z.ZodIssueCode.custom,
        message: 'Please enter DPIIT Certificate Number',
        path: ['dpiitNumber'],
      });
    }
    if (data.isMsmeRegistered) {
      if (!data.msmeNumber) {
        context.addIssue({
          code: z.ZodIssueCode.custom,
          message: 'Please enter MSME Number',
          path: ['msmeNumber'],
        });
      } else if (data.msmeNumber.length !== 19) {
        context.addIssue({
          code: z.ZodIssueCode.custom,
          message: 'Please enter a valid MSME Number',
          path: ['msmeNumber'],
        });
      }
    }
    return data;
  });

export const vendorAddressDetailsFormSchema = z
  .object({
    contactPersonDetails: z.object({
      name: requiredStringValidation,
      designation: requiredStringValidation,
      mobile: phoneValidationSchema,
      phone: z.string().optional(),
      email: requiredEmailValidation,
    }),
    applicantDetails: z.object({
      sameAsContactPerson: z.boolean().default(false),
      name: z.string().optional(),
      designation: z.string().optional(),
      mobile: z.string().optional(),
      phone: z.string().optional(),
      email: z.string().optional(),
    }),
    registeredAddress: z.object({
      addressLine1: requiredStringValidation,
      addressLine2: z.string().optional(),
      pincode: requiredStringValidation,
      city: requiredStringValidation,
      state: requiredStringValidation,
      phone: z.string().optional(),
      email: z.string().optional(),
    }),
    businessAddress: z.object({
      sameAsRegisteredAddress: z.boolean().default(false),
      addressLine1: z.string().optional(),
      addressLine2: z.string().optional(),
      pincode: z.string().optional(),
      city: z.string().optional(),
      state: z.string().optional(),
      phone: z.string().optional(),
      email: z.string().optional(),
    }),
    addressProofDoc: z.object(
      {
        imgSrc: z.string(),
        newDocument: z.boolean(),
      },
      {
        required_error: 'Please upload Address Proof Document',
      }
    ),
    isGstRegistered: z.boolean({
      required_error: 'Please choose an option',
    }),
    gstNumber: z.string().optional(),
    gstCert: z
      .object({
        imgSrc: z.string({
          required_error: 'Invalid Document',
        }),
        newDocument: z.boolean(),
      })
      .optional(),
    officeStatus: z.enum(['owned', 'rented'], {
      required_error: 'Please choose an option',
    }),
  })
  .superRefine((data, context) => {
    if (
      !!data.registeredAddress.email &&
      !data.registeredAddress.email.match(
        new RegExp('^[a-zA-Z0-9+_.-]+@[a-zA-Z0-9.-]+$')
      )
    ) {
      context.addIssue({
        code: z.ZodIssueCode.custom,
        message: 'Please enter a valid email address',
        path: ['registeredAddress', 'email'],
      });
    }

    if (data.contactPersonDetails.phone) {
      const phoneNumber = parsePhoneNumber(
        data.contactPersonDetails.phone,
        'IN'
      );
      if (!phoneNumber.isValid()) {
        context.addIssue({
          code: z.ZodIssueCode.custom,
          message: 'Invalid phone number',
          path: ['contactPersonDetails', 'phone'],
        });
      }
    }

    if (!data.applicantDetails.sameAsContactPerson) {
      if (!data.applicantDetails.name) {
        context.addIssue({
          code: z.ZodIssueCode.custom,
          message: 'This field is required',
          path: ['applicantDetails', 'name'],
        });
      }
      if (!data.applicantDetails.designation) {
        context.addIssue({
          code: z.ZodIssueCode.custom,
          message: 'This field is required',
          path: ['applicantDetails', 'designation'],
        });
      }
      if (!data.applicantDetails.mobile) {
        context.addIssue({
          code: z.ZodIssueCode.custom,
          message: 'This field is required',
          path: ['applicantDetails', 'mobile'],
        });
      }
      if (data.applicantDetails.phone) {
        const phoneNumber = parsePhoneNumber(data.applicantDetails.phone, 'IN');
        if (!phoneNumber.isValid()) {
          context.addIssue({
            code: z.ZodIssueCode.custom,
            message: 'Invalid phone number',
            path: ['applicantDetails', 'phone'],
          });
        }
      }
      if (!data.applicantDetails.email) {
        context.addIssue({
          code: z.ZodIssueCode.custom,
          message: 'This field is required',
          path: ['applicantDetails', 'email'],
        });
      } else if (
        !data.applicantDetails.email.match(
          new RegExp('^[a-zA-Z0-9+_.-]+@[a-zA-Z0-9.-]+$')
        )
      ) {
        context.addIssue({
          code: z.ZodIssueCode.custom,
          message: 'Please enter a valid email address',
          path: ['applicantDetails', 'email'],
        });
      }
    }
    if (!data.businessAddress.sameAsRegisteredAddress) {
      if (!data.businessAddress.addressLine1) {
        context.addIssue({
          code: z.ZodIssueCode.custom,
          message: 'This field is required',
          path: ['businessAddress', 'addressLine1'],
        });
      }
      if (!data.businessAddress.pincode) {
        context.addIssue({
          code: z.ZodIssueCode.custom,
          message: 'This field is required',
          path: ['businessAddress', 'pincode'],
        });
      }
      if (!data.businessAddress.city) {
        context.addIssue({
          code: z.ZodIssueCode.custom,
          message: 'This field is required',
          path: ['businessAddress', 'city'],
        });
      }
      if (!data.businessAddress.state) {
        context.addIssue({
          code: z.ZodIssueCode.custom,
          message: 'This field is required',
          path: ['businessAddress', 'state'],
        });
      }
      if (data.businessAddress.phone) {
        const phoneNumber = parsePhoneNumber(data.businessAddress.phone, 'IN');
        if (!phoneNumber.isValid()) {
          context.addIssue({
            code: z.ZodIssueCode.custom,
            message: 'Invalid phone number',
            path: ['businessAddress', 'phone'],
          });
        }
      }
      if (
        !!data.businessAddress.email &&
        !data.businessAddress.email.match(
          new RegExp('^[a-zA-Z0-9+_.-]+@[a-zA-Z0-9.-]+$')
        )
      ) {
        context.addIssue({
          code: z.ZodIssueCode.custom,
          message: 'Please enter a valid email address',
          path: ['businessAddress', 'email'],
        });
      }
      if (data.isGstRegistered) {
        if (!data.gstNumber) {
          context.addIssue({
            code: z.ZodIssueCode.custom,
            message: 'Please enter GST Number',
            path: ['gstNumber'],
          });
        } else if (
          !data.gstNumber.match(
            new RegExp(
              '^[0-9]{2}[a-zA-Z]{5}[0-9]{4}[a-zA-Z]{1}[1-9a-zA-Z]{1}[zZ][0-9a-zA-Z]{1}$'
            )
          )
        ) {
          context.addIssue({
            code: z.ZodIssueCode.custom,
            message: 'Please enter a valid GST Number',
            path: ['gstNumber'],
          });
        }
      }
    }
    return data;
  });

export const vendorPaymentDetailsFormSchema = z
  .object({
    accountHolderName: requiredStringValidation,
    accountNumber: requiredStringValidation,
    accountType: z.enum(['saving', 'current'], {
      required_error: 'Please select Account Type',
    }),
    ifscCode: z
      .string({
        required_error: 'This field is required',
      })
      .regex(
        new RegExp('^[a-zA-Z]{4}0[a-zA-Z0-9]{6}$'),
        'Please enter a valid IFSC Code'
      ),
    bankName: requiredStringValidation,
    branchName: z.string().optional(),
    micrCode: z.string().optional(),
    swiftCode: z.string().optional(),
    cancelledChequeCopy: z.object(
      {
        imgSrc: z.string(),
        newDocument: z.boolean(),
      },
      {
        required_error: 'Please upload Cancelled Cheque Copy',
      }
    ),
  })
  .superRefine((data, context) => {
    if (data.micrCode) {
      if (!data.micrCode.match(new RegExp('^[0-9]{9}$'))) {
        context.addIssue({
          code: z.ZodIssueCode.custom,
          message: 'Please enter a valid MICR Code',
          path: ['micrCode'],
        });
      }
    }
    if (data.swiftCode) {
      if (
        !data.swiftCode.match(
          new RegExp('^[a-zA-Z]{6}[a-zA-Z0-9]{2}([a-zA-Z0-9]{3})?$')
        )
      ) {
        context.addIssue({
          code: z.ZodIssueCode.custom,
          message: 'Please enter a valid SWIFT Code',
          path: ['swiftCode'],
        });
      }
    }
    return data;
  });

const vendorLinkFormSchema = z
  .object({
    website: z.string().optional(),
    facebook: z.string().optional(),
    instagram: z.string().optional(),
    linkedIn: z.string().optional(),
    twitter: z.string().optional(),
    others: z.string().optional(),
  })
  .superRefine((data, context) => {
    if (
      data.website &&
      !data.website.match(
        new RegExp(
          '^((http|https)://)?(www.)?[a-zA-Z0-9@:%._\\+~#?&//=]{2,256}\\.[a-z]{2,6}\\b([-a-zA-Z0-9@:%._\\+~#?&//=]*)'
        )
      )
    ) {
      context.addIssue({
        code: z.ZodIssueCode.custom,
        message: 'Please enter a valid URL',
        path: ['website'],
      });
    }
    if (
      data.facebook &&
      !data.facebook.match(
        new RegExp('^((http|https)://)?(www.)?facebook.com/.*$')
      )
    ) {
      context.addIssue({
        code: z.ZodIssueCode.custom,
        message: 'Please enter a valid Facebook URL',
        path: ['facebook'],
      });
    }
    if (
      data.instagram &&
      !data.instagram.match(
        new RegExp('^((http|https)://)?(www.)?instagram.com/.*$')
      )
    ) {
      context.addIssue({
        code: z.ZodIssueCode.custom,
        message: 'Please enter a valid Instagram URL',
        path: ['instagram'],
      });
    }
    if (
      data.linkedIn &&
      !data.linkedIn.match(
        new RegExp('^((http|https)://)?(www.)?linkedin.com/.*$')
      )
    ) {
      context.addIssue({
        code: z.ZodIssueCode.custom,
        message: 'Please enter a valid LinkedIn URL',
        path: ['linkedIn'],
      });
    }
    if (
      data.twitter &&
      !data.twitter.match(
        new RegExp('^((http|https)://)?(www.)?twitter.com/.*$')
      )
    ) {
      context.addIssue({
        code: z.ZodIssueCode.custom,
        message: 'Please enter a valid Twitter URL',
        path: ['twitter'],
      });
    }
    if (
      data.others &&
      !data.others.match(new RegExp('^((http|https)://)?(www.)?.*$'))
    ) {
      context.addIssue({
        code: z.ZodIssueCode.custom,
        message: 'Please enter a valid URL',
        path: ['others'],
      });
    }
    return data;
  });

const formFillingStates: {
  stateId: AddOrEditVendorFormStates;
  title: string;
  substateId?: 'fillDetails' | 'showDetails';
}[] = [
  {
    stateId: 'primaryDetails',
    title: 'Primary Details',
  },
  {
    stateId: 'addressDetails',
    title: 'Addresses & Contacts',
  },
  {
    stateId: 'paymentDetails',
    title: 'Payment Details',
  },
  {
    stateId: 'links',
    title: 'Links',
  },
];

export type VendorAddressStateType = {
  addressProofDocument?: DocumentFileType;
  gstCertificate?: DocumentFileType;
  cancelledCheque?: DocumentFileType;
} & VendorAddressPayloadType;

export function useAddOrEditVendor(flowType: 'add' | 'edit') {
  const navigate = useNavigate();
  const { user } = useUser();
  const { state } = useLocation();

  const [currentFormFillingStateIndex, setCurrentFormFillingStateIndex] =
    useState<number>(0);
  const [createOrUpdateVendorApiLoading, setCreateOrUpdateVendorApiLoading] =
    useState<boolean>(false);
  const [vendorTypeOptions, setVendorTypeOptions] = useState<BaseOption[]>();
  const [vendorBranchOptions, setVendorBranchOptions] =
    useState<BaseOption[]>();
  const modalState = useOverlayTriggerState({});

  const scrollViewRef = useRef<HTMLDivElement>(null);

  const addOrEditVendorPayloadRef = useRef<
    Partial<CreateOrUpdateVendorPayload>
  >({});
  const editVendorFormErrorRef = useRef<{
    [key in AddOrEditVendorFormStates]?: boolean;
  }>({});

  const vendorDetails = state?.vendorDetails as VendorDetails | undefined;

  const [vendorAddresses, setVendorAddresses] = useState<
    VendorAddressStateType[]
  >(() => {
    // Your initialization logic here
    const vendorAddreses: VendorAddressStateType[] =
      vendorDetails?.vendor_addresses
        ? vendorDetails?.vendor_addresses.map((vAddress) => {
            return {
              id: vAddress.id,
              contact_person_details: vAddress.contact_person_details,
              applicant_details: vAddress.applicant_details,
              registered_address: vAddress.registered_address,
              business_address: vAddress.business_address,
              addressProofDocument: vAddress?.attachments?.address_proof
                ? {
                    imgSrc: vAddress?.attachments?.address_proof?.url,
                    name: vAddress?.attachments?.address_proof?.name,
                    size: parseFloat(
                      vAddress?.attachments?.address_proof?.size || '0'
                    ),
                    type: vAddress?.attachments?.address_proof?.mime_type,
                    newDocument: false,
                  }
                : undefined,
              gst_number: vAddress?.gst_number || null,
              is_gst_registered: Boolean(vAddress?.is_gst_registered),
              office_status: vAddress?.office_status || undefined,
              payment_details: vAddress?.payment_details,
              cancelledCheque: vAddress?.attachments?.cancelled_cheque
                ? {
                    imgSrc: vAddress?.attachments?.cancelled_cheque?.url,
                    name: vAddress?.attachments?.cancelled_cheque?.name,
                    size: parseFloat(
                      vAddress?.attachments?.cancelled_cheque?.size || '0'
                    ),
                    type: vAddress?.attachments?.cancelled_cheque?.mime_type,
                    newDocument: false,
                  }
                : undefined,
              gstCertificate: vAddress?.attachments?.gst_certificate
                ? {
                    imgSrc: vAddress?.attachments?.gst_certificate?.url,
                    name: vAddress?.attachments?.gst_certificate?.name,
                    size: parseFloat(
                      vAddress?.attachments?.gst_certificate?.size || '0'
                    ),
                    type: vAddress?.attachments?.gst_certificate?.mime_type,
                    newDocument: false,
                  }
                : undefined,
            };
          })
        : [];
    return vendorAddreses;
  });

  const {
    control: vendorPrimaryDetailsFormControl,
    handleSubmit: handleVendorPrimaryDetailsFormSubmit,
    setValue: setVendorPrimaryDetailsFormValue,
    getValues: getVendorPrimaryDetailsFormValues,
  } = useForm<VendorPrimaryDetailFormInputs>({
    resolver: zodResolver(vendorPrimaryDetailsFormSchema),
    defaultValues: {
      legalName: vendorDetails?.legal_name || undefined,
      displayName: vendorDetails?.name || undefined,
      vendorType: vendorDetails?.vendor_type?.id.toString() || undefined,
      registrationType: vendorDetails?.company_reg_type || undefined,
      registrationCert: vendorDetails?.attachments?.company_reg_cert
        ? {
            imgSrc: vendorDetails?.attachments?.company_reg_cert?.url,
            name: vendorDetails?.attachments?.company_reg_cert?.name,
            size: parseFloat(
              vendorDetails?.attachments?.company_reg_cert?.size || '0'
            ),
            type: vendorDetails?.attachments?.company_reg_cert?.mime_type,
            newDocument: false,
          }
        : undefined,
      pancard: vendorDetails?.attachments?.pancard_certificate
        ? {
            imgSrc: vendorDetails?.attachments?.pancard_certificate?.url,
            name: vendorDetails?.attachments?.pancard_certificate?.name,
            size: parseFloat(
              vendorDetails?.attachments?.pancard_certificate?.size || '0'
            ),
            type: vendorDetails?.attachments?.pancard_certificate?.mime_type,
            newDocument: false,
          }
        : undefined,
      branchIds: vendorDetails?.branches?.map((branch) => branch.id.toString()),
      isEInvoicingEnabled: vendorDetails?.is_einvoicing_enabled || false,
      panNumber: vendorDetails?.pan_number || undefined,
      tanNumber: vendorDetails?.tan_number || undefined,
      cinNumber: vendorDetails?.cin_number || undefined,
      isDpiitCertified: vendorDetails?.is_dpiit_certified || false,
      dpiitNumber: vendorDetails?.dpiit_cert_number || undefined,
      dpiitCert: vendorDetails?.attachments?.dpiit_certificate
        ? {
            imgSrc: vendorDetails?.attachments?.dpiit_certificate?.url,
            name: vendorDetails?.attachments?.dpiit_certificate?.name,
            size: parseFloat(
              vendorDetails?.attachments?.dpiit_certificate?.size || '0'
            ),
            type: vendorDetails?.attachments?.dpiit_certificate?.mime_type,
            newDocument: false,
          }
        : undefined,
      isMsmeRegistered: vendorDetails?.is_msme_registered || false,
      msmeNumber: vendorDetails?.msme_reg_number || undefined,
      msmeCert: vendorDetails?.attachments?.msme_certificate
        ? {
            imgSrc: vendorDetails?.attachments?.msme_certificate?.url,
            name: vendorDetails?.attachments?.msme_certificate?.name,
            size: parseFloat(
              vendorDetails?.attachments?.msme_certificate?.size || '0'
            ),
            type: vendorDetails?.attachments?.msme_certificate?.mime_type,
            newDocument: false,
          }
        : undefined,
    },
  });

  const {
    control: vendorLinkFormControl,
    handleSubmit: handleVendorLinkFormSubmit,
  } = useForm<VendorLinkFormInputs>({
    resolver: zodResolver(vendorLinkFormSchema),
    defaultValues: {
      website: vendorDetails?.social_links?.website || undefined,
      facebook: vendorDetails?.social_links?.facebook || undefined,
      instagram: vendorDetails?.social_links?.instagram || undefined,
      linkedIn: vendorDetails?.social_links?.linkedin || undefined,
      twitter: vendorDetails?.social_links?.twitter || undefined,
      others: vendorDetails?.social_links?.others || undefined,
    },
  });
  const currentAddressIndex = useRef<number>(-1);

  const isPaymentDetailsFilledForAllAddress = useMemo(() => {
    let detailsFilledFlag = true;
    vendorAddresses.forEach((vendorAddress) => {
      if (!vendorAddress.payment_details) {
        detailsFilledFlag = false;
      }
    });
    return detailsFilledFlag;
  }, [vendorAddresses]);

  useEffect(() => {
    if (!vendorDetails && flowType === 'edit') {
      navigate('/home');
    }
  }, [navigate, vendorDetails, flowType]);

  useEffect(() => {
    getVendorTypes()
      .then((response) => {
        const { data } = response as { data: { id: number; name: string }[] };
        setVendorTypeOptions(
          data.map((vendorType) => ({
            label: vendorType.name,
            value: vendorType.id.toString(),
          }))
        );
      })
      .catch(() => null);
  }, []);

  useEffect(() => {
    if (
      !checkForSinglePermission(
        'readBranch',
        user?.roles[0]?.permissions || null
      )
    ) {
      if (user?.branches?.length === 1) {
        setVendorPrimaryDetailsFormValue('branchIds', [
          user?.branches[0].id.toString(),
        ]);
      }
      setVendorBranchOptions(
        (user?.branches || []).map((branch) => ({
          label: branch.name,
          value: branch.id.toString(),
        }))
      );
    } else {
      getBranchListing()
        .then((response) => {
          const { data } = response as { data: { id: number; name: string }[] };
          setVendorBranchOptions(
            data.map((branch) => ({
              label: branch.name,
              value: branch.id.toString(),
            }))
          );
        })
        .catch(() => null);
    }
  }, [user?.branches, user?.roles, setVendorPrimaryDetailsFormValue]);

  const selectNextFormState = () => {
    if (currentFormFillingStateIndex >= formFillingStates.length - 1) return;
    setCurrentFormFillingStateIndex((prevIndex) => prevIndex + 1);
    window.scrollTo(0, 0);
  };

  const selectPreviousFormState = () => {
    window.scrollTo(0, 0);
    if (currentFormFillingStateIndex <= 0) {
      navigate(-1);
      return;
    }
    setCurrentFormFillingStateIndex((prevIndex) => prevIndex - 1);
  };

  const goBack = () => {
    navigate(-1);
  };

  const uploadDocAndCreateNewVendor = () => {
    setCreateOrUpdateVendorApiLoading(true);
    const companyRegCert =
      getVendorPrimaryDetailsFormValues('registrationCert');
    const dpiitCert = getVendorPrimaryDetailsFormValues('dpiitCert');
    const msmeCert = getVendorPrimaryDetailsFormValues('msmeCert');
    const pancard = getVendorPrimaryDetailsFormValues('pancard');
    const docsList: { id: string; file: DocumentFileType }[] = [];
    if (companyRegCert?.imgSrc) {
      docsList.push({ id: 'company_reg_cert_upload_id', file: companyRegCert });
    }
    if (dpiitCert?.imgSrc) {
      docsList.push({ id: 'dpiit_certificate_upload_id', file: dpiitCert });
    }
    if (msmeCert?.imgSrc) {
      docsList.push({ id: 'msme_certificate_upload_id', file: msmeCert });
    }
    if (pancard?.imgSrc) {
      docsList.push({ id: 'pancard_cert_upload_id', file: pancard });
    }
    const uploadDocRequests: Promise<any>[] = [];
    docsList.forEach((doc) => {
      const formData = new FormData();
      formData.append('file', doc.file.fileBlobValue as any, doc.file.name);
      uploadDocRequests.push(uploadMediaToTemp(formData));
    });
    Promise.all(uploadDocRequests)
      .then((res: any) => {
        res.forEach((responseItem: any, index: number) => {
          const addresses = vendorAddresses.map((vendorAddress) => {
            const address = {
              ...vendorAddress,
            };
            delete address['addressProofDocument'];
            delete address['cancelledCheque'];
            delete address['gstCertificate'];
            return address;
          });
          addOrEditVendorPayloadRef.current = {
            ...addOrEditVendorPayloadRef.current,
            addresses,
            [docsList[index].id]: responseItem.data.id as number,
          };
        });
        createNewVendor(
          addOrEditVendorPayloadRef.current as CreateOrUpdateVendorPayload
        )
          .then(() => {
            setCreateOrUpdateVendorApiLoading(false);
            navigate('/home/vendors');
            toastQueue.add(
              {
                title: 'Vendor Created Successfully',
                type: 'success',
              },
              {
                timeout: 2000,
              }
            );
          })
          .catch((err) => {
            setCreateOrUpdateVendorApiLoading(false);
            toastQueue.add(
              {
                title: err.message || 'Something went wrong,  Please try again',
                type: 'error',
              },
              {
                timeout: 2000,
              }
            );
          });
      })
      .catch((err) => {
        toastQueue.add(
          {
            title: 'Error in uploading docs, Please try again',
            type: 'error',
          },
          {
            timeout: 2000,
          }
        );
      });
  };

  const validatedVendorPrimaryDetails = (
    data: VendorPrimaryDetailFormInputs
  ) => {
    addOrEditVendorPayloadRef.current.branch_ids = data.branchIds.map(
      (branchId) => parseInt(branchId)
    );
    addOrEditVendorPayloadRef.current.legal_name = data.legalName;
    addOrEditVendorPayloadRef.current.name = data.displayName;
    addOrEditVendorPayloadRef.current.vendor_type_id = parseInt(
      data.vendorType
    );
    addOrEditVendorPayloadRef.current.company_reg_type = data.registrationType;
    addOrEditVendorPayloadRef.current.is_einvoicing_enabled =
      data.isEInvoicingEnabled;
    addOrEditVendorPayloadRef.current.pan_number = data.panNumber;
    addOrEditVendorPayloadRef.current.tan_number = data.tanNumber;
    addOrEditVendorPayloadRef.current.cin_number = data.cinNumber;
    addOrEditVendorPayloadRef.current.is_dpiit_certified =
      data.isDpiitCertified;
    addOrEditVendorPayloadRef.current.dpiit_cert_number = data.isDpiitCertified
      ? data.dpiitNumber
      : undefined;
    addOrEditVendorPayloadRef.current.is_msme_registered =
      data.isMsmeRegistered;
    addOrEditVendorPayloadRef.current.msme_reg_number = data.isMsmeRegistered
      ? data.msmeNumber
      : undefined;
    if (flowType === 'add') {
      selectNextFormState();
      if (vendorAddresses.length === 0) {
        modalState.open();
      }
    }
  };

  const validatedVendorAddressDetails = (
    data: VendorAdressDetailFormInputs
  ) => {
    const contact_person_details = {
      name: data.contactPersonDetails.name,
      designation: data.contactPersonDetails.designation,
      phone_number: data.contactPersonDetails.phone,
      mobile_number: data.contactPersonDetails.mobile,
      email: data.contactPersonDetails.email,
    };
    const applicant_details = {
      name: data.applicantDetails.name,
      designation: data.applicantDetails.designation,
      phone_number: data.applicantDetails.phone,
      mobile_number: data.applicantDetails.mobile,
      email: data.applicantDetails.email,
    };
    const registered_address = {
      address_line_1: data.registeredAddress.addressLine1,
      address_line_2: data.registeredAddress.addressLine2,
      city: data.registeredAddress.city,
      pincode: data.registeredAddress.pincode,
      state: data.registeredAddress.state,
      country: 'India',
      phone_number: data.registeredAddress.phone,
      email: data.registeredAddress.email,
    };
    const business_address = {
      address_line_1: data.businessAddress.addressLine1,
      address_line_2: data.businessAddress.addressLine2,
      city: data.businessAddress.city,
      pincode: data.businessAddress.pincode,
      state: data.businessAddress.state,
      country: 'India',
      phone_number: data.businessAddress.phone,
      email: data.businessAddress.email,
    };

    const vendorAddress: VendorAddressStateType = {
      contact_person_details,
      is_gst_registered: data.isGstRegistered,
      gst_number: data.isGstRegistered ? data.gstNumber : null,
      applicant_details: data.applicantDetails.sameAsContactPerson
        ? contact_person_details
        : applicant_details,
      registered_address,
      business_address: data.businessAddress.sameAsRegisteredAddress
        ? registered_address
        : business_address,
      office_status: data.officeStatus,
      gstCertificate: data.gstCert,
      addressProofDocument: data.addressProofDoc,
      address_proof_upload_id: data.addressProofDoc?.uploadId,
      gst_certificate_upload_id: null,
    };
    if (data.gstCert?.uploadId) {
      vendorAddress['gst_certificate_upload_id'] = data.gstCert?.uploadId;
    }
    if (currentAddressIndex.current === -1) {
      setVendorAddresses((prevAddresses) => [...prevAddresses, vendorAddress]);
    } else {
      const vendorAddressesClone = [...vendorAddresses];
      vendorAddressesClone[currentAddressIndex.current] = vendorAddress;
      if (vendorAddresses[currentAddressIndex.current].payment_details) {
        vendorAddressesClone[currentAddressIndex.current]['payment_details'] =
          vendorAddresses[currentAddressIndex.current].payment_details;
      }
      if (vendorAddresses[currentAddressIndex.current].id) {
        vendorAddressesClone[currentAddressIndex.current]['id'] =
          vendorAddresses[currentAddressIndex.current].id;
      }
      setVendorAddresses(vendorAddressesClone);
    }
    currentAddressIndex.current = -1;
  };

  const validatedVendorPaymentDetails = (
    data: VendorPaymentDetailFormInputs
  ) => {
    const vendorAddressesClone = [...vendorAddresses];
    vendorAddressesClone[currentAddressIndex.current]['payment_details'] = {
      account_holder_name: data.accountHolderName,
      account_number: data.accountNumber,
      account_type: data.accountType,
      ifsc_code: data.ifscCode,
      bank_name: data.bankName,
      branch_name: data.branchName,
      micr: data.micrCode,
      swift_code: data.swiftCode,
    };
    vendorAddressesClone[currentAddressIndex.current]['cancelledCheque'] =
      data.cancelledChequeCopy;
    vendorAddressesClone[currentAddressIndex.current][
      'cancelled_cheque_upload_id'
    ] = data.cancelledChequeCopy.uploadId;
    setVendorAddresses(vendorAddressesClone);
  };

  const validatedVendorLinks = (data: VendorLinkFormInputs) => {
    addOrEditVendorPayloadRef.current.social_links = {
      website: data.website || null,
      facebook: data.facebook || null,
      instagram: data.instagram || null,
      linkedin: data.linkedIn || null,
      twitter: data.twitter || null,
      others: data.others || null,
    };
    if (flowType === 'add') {
      uploadDocAndCreateNewVendor();
    }
  };

  const deleteVendorAddress = (index: number) => {
    const vendorAddressesClone = vendorAddresses.filter(
      (item, itemIndex) => itemIndex !== index
    );
    setVendorAddresses([...vendorAddressesClone]);
    toastQueue.add(
      {
        title: 'Address deleted successfully!',
        type: 'custom',
      },
      {
        timeout: 2000,
      }
    );
  };

  const scrollBasedOnPrimaryDetailsFieldError = (
    err: FieldErrors<VendorPrimaryDetailFormInputs>
  ) => {
    if (
      err.legalName ||
      err.displayName ||
      err.vendorType ||
      err.registrationType ||
      err.registrationCert
    ) {
      scrollToTop();
    } else if (err.branchIds || err.isEInvoicingEnabled) {
      scrollToCenter();
    } else {
      scrollToBottom();
    }
    showMissingDetailsErrorToast();
  };

  const showMissingDetailsErrorToast = (
    msg = 'Please fill the mandatory details correctly'
  ) => {
    toastQueue.add(
      {
        title: msg,
        type: 'error',
      },
      {
        timeout: 2000,
      }
    );
  };

  const validateFormAndNext = () => {
    switch (formFillingStates[currentFormFillingStateIndex].stateId) {
      case 'primaryDetails':
        handleVendorPrimaryDetailsFormSubmit(
          validatedVendorPrimaryDetails,
          scrollBasedOnPrimaryDetailsFieldError
        )();
        return;
      case 'addressDetails':
        if (vendorAddresses.length === 0) {
          showMissingDetailsErrorToast('Please add atleast one vendor address');
        } else {
          selectNextFormState();
        }
        return;
      case 'paymentDetails':
        if (isPaymentDetailsFilledForAllAddress) {
          selectNextFormState();
        } else {
          showMissingDetailsErrorToast(
            'Please add payment details for all addresses'
          );
        }
        return;
      case 'links':
        handleVendorLinkFormSubmit(validatedVendorLinks, () =>
          showMissingDetailsErrorToast()
        )();
        return;
    }
  };

  const checkForErrorInFormAndNext = (
    nextStateIndex: number,
    currentStateId: AddOrEditVendorFormStates
  ) => {
    if (
      nextStateIndex >= 0 &&
      nextStateIndex < formFillingStates.length &&
      editVendorFormErrorRef.current[currentStateId] !== true
    ) {
      setCurrentFormFillingStateIndex(nextStateIndex);
    } else {
      toastQueue.add(
        {
          title: 'Please fill the mandatory details correctly',
          type: 'error',
        },
        {
          timeout: 2000,
        }
      );
    }
  };

  const changeVendorDetailsFormState = (stateIndex: number) => {
    if (currentFormFillingStateIndex === stateIndex) return;
    switch (formFillingStates[currentFormFillingStateIndex].stateId) {
      case 'primaryDetails':
        handleVendorPrimaryDetailsFormSubmit(
          () => {
            delete editVendorFormErrorRef.current['primaryDetails'];
          },
          (err) => {
            editVendorFormErrorRef.current['primaryDetails'] = true;
            scrollBasedOnPrimaryDetailsFieldError(err);
          }
        )().then(() => {
          checkForErrorInFormAndNext(stateIndex, 'primaryDetails');
        });
        return;
      case 'addressDetails':
        delete editVendorFormErrorRef.current['addressDetails'];
        checkForErrorInFormAndNext(stateIndex, 'addressDetails');
        return;
      case 'paymentDetails':
        delete editVendorFormErrorRef.current['paymentDetails'];
        checkForErrorInFormAndNext(stateIndex, 'paymentDetails');
        return;
      case 'links':
        handleVendorLinkFormSubmit(
          () => {
            delete editVendorFormErrorRef.current['links'];
          },
          () => {
            editVendorFormErrorRef.current['links'] = true;
          }
        )().then(() => {
          checkForErrorInFormAndNext(stateIndex, 'links');
        });
        return;
    }
  };

  const updateVendorDetails = () => {
    const validateCompleteFormPromise = Promise.all([
      handleVendorPrimaryDetailsFormSubmit(
        (data) => {
          delete editVendorFormErrorRef.current['primaryDetails'];
          validatedVendorPrimaryDetails(data);
        },
        () => {
          editVendorFormErrorRef.current['primaryDetails'] = true;
        }
      )(),
      handleVendorLinkFormSubmit(
        (data) => {
          delete editVendorFormErrorRef.current['links'];
          validatedVendorLinks(data);
        },
        () => {
          editVendorFormErrorRef.current['links'] = true;
        }
      )(),
    ]);
    validateCompleteFormPromise.then(() => {
      if (Object.keys(editVendorFormErrorRef.current).length > 0) {
        if (
          editVendorFormErrorRef.current[
            formFillingStates[currentFormFillingStateIndex].stateId
          ] === true
        ) {
          toastQueue.add(
            {
              title: 'Please fill the mandatory details correctly',
              type: 'error',
            },
            {
              timeout: 2000,
            }
          );
          return;
        } else {
          for (
            let stateIndex = 0;
            stateIndex < formFillingStates.length;
            stateIndex++
          ) {
            const formState = formFillingStates[stateIndex];
            if (editVendorFormErrorRef.current[formState.stateId] === true) {
              toastQueue.add(
                {
                  title: 'Please fill the mandatory details correctly',
                  type: 'error',
                },
                {
                  timeout: 2000,
                }
              );
              setCurrentFormFillingStateIndex(stateIndex);
              break;
            }
          }
        }
      } else {
        if (!vendorDetails?.id) {
          toastQueue.add(
            {
              title: 'Something went wrong, Please try again',
              type: 'error',
            },
            {
              timeout: 2000,
            }
          );
          return;
        }
        if (!isPaymentDetailsFilledForAllAddress) {
          toastQueue.add(
            {
              title: 'Please fill payment details for all addresses',
              type: 'error',
            },
            {
              timeout: 2000,
            }
          );
          setCurrentFormFillingStateIndex(2);
          return;
        }
        const companyRegCert =
          getVendorPrimaryDetailsFormValues('registrationCert');
        const dpiitCert = getVendorPrimaryDetailsFormValues('dpiitCert');
        const msmeCert = getVendorPrimaryDetailsFormValues('msmeCert');
        const pancard = getVendorPrimaryDetailsFormValues('pancard');
        const docsList: { id: string; file: DocumentFileType }[] = [];
        if (companyRegCert?.newDocument) {
          docsList.push({
            id: 'company_reg_cert_upload_id',
            file: companyRegCert,
          });
        }
        if (dpiitCert?.newDocument) {
          docsList.push({ id: 'dpiit_certificate_upload_id', file: dpiitCert });
        }
        if (msmeCert?.newDocument) {
          docsList.push({ id: 'msme_certificate_upload_id', file: msmeCert });
        }
        if (pancard?.newDocument) {
          docsList.push({ id: 'pancard_cert_upload_id', file: pancard });
        }

        const uploadDocRequests: Promise<any>[] = [];
        docsList.forEach((doc) => {
          const formData = new FormData();
          formData.append('file', doc.file.fileBlobValue as any, doc.file.name);
          uploadDocRequests.push(uploadMediaToTemp(formData));
        });
        Promise.all(uploadDocRequests)
          .then((res: any) => {
            res.forEach((responseItem: any, index: number) => {
              addOrEditVendorPayloadRef.current = {
                ...addOrEditVendorPayloadRef.current,
                [docsList[index].id]: responseItem.data.id as number,
              };
            });
            if (!addOrEditVendorPayloadRef.current.addresses) {
              const addresses = vendorAddresses.map((vendorAddress) => {
                const address = {
                  ...vendorAddress,
                  address_proof_upload_id:
                    vendorAddress.addressProofDocument?.uploadId || null,
                  gst_certificate_upload_id: null,
                  cancelled_cheque_upload_id:
                    vendorAddress.cancelledCheque?.uploadId || null,
                  payment_details: vendorAddress.payment_details,
                };
                delete address['addressProofDocument'];
                delete address['cancelledCheque'];
                delete address['gstCertificate'];
                return address;
              });
              addOrEditVendorPayloadRef.current = {
                ...addOrEditVendorPayloadRef.current,
                addresses,
              };
            }
            updateVendor(vendorDetails.id, addOrEditVendorPayloadRef.current)
              .then(() => {
                setCreateOrUpdateVendorApiLoading(false);
                navigate('/home/vendors');
                toastQueue.add(
                  {
                    title: 'Vendor Updated Successfully',
                    type: 'success',
                  },
                  {
                    timeout: 2000,
                  }
                );
              })
              .catch((err) => {
                setCreateOrUpdateVendorApiLoading(false);
                toastQueue.add(
                  {
                    title:
                      err.message || 'Something went wrong, Please try again',
                    type: 'error',
                  },
                  {
                    timeout: 2000,
                  }
                );
              });
          })
          .catch((err) => {
            toastQueue.add(
              {
                title: 'Error in uploading docs, Please try again',
                type: 'error',
              },
              {
                timeout: 2000,
              }
            );
          });
      }
    });
  };

  const scrollToTop = () => {
    scrollViewRef.current?.firstElementChild?.scrollIntoView({
      behavior: 'smooth',
    });
  };

  const scrollToCenter = () => {
    window.scrollTo({
      top: (scrollViewRef.current?.scrollHeight || 0) / 3,
      behavior: 'smooth',
    });
  };

  const scrollToBottom = () => {
    scrollViewRef.current?.lastElementChild?.scrollIntoView({
      behavior: 'smooth',
    });
  };

  return {
    formFillingStates,
    currentFormFillingStateIndex,
    vendorPrimaryDetailsFormControl,
    vendorLinkFormControl,
    createOrUpdateVendorApiLoading,
    vendorRegistrationTypeOptions,
    vendorBranchOptions,
    vendorTypeOptions,
    selectStateOptions,
    scrollViewRef,
    goBack,
    selectPreviousFormState,
    validateFormAndNext,
    changeVendorDetailsFormState,
    updateVendorDetails,
    vendorAddresses,
    currentAddressIndex,
    deleteVendorAddress,
    validatedVendorAddressDetails,
    validatedVendorPaymentDetails,
    modalState,
  };
}

export const useVendorProfile = (vendorOptionalId?: string) => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const { vendorId } = useParams();
  const { user } = useUser();

  const [state, dispatch] = useReducer(
    vendorDetailsReducer,
    initialVendorDetails
  );

  const [
    isDeactivateConfirmationDialogVisible,
    setIsDeactivateConfirmationDialogVisible,
  ] = useState<boolean>(false);
  const [isDeactivateVendorApiLoading, setIsDeactivateVendorApiLoading] =
    useState<boolean>(false);

  const hasEditProfilePermission = useMemo(() => {
    return checkForSinglePermission(
      'updateVendor',
      user?.roles[0]?.permissions || null
    );
  }, [user?.roles]);

  const hasDeactivateVendorPermission = useMemo(() => {
    return checkForSinglePermission(
      'deleteVendor',
      user?.roles[0]?.permissions || null
    );
  }, [user?.roles]);

  const fromScreenName = useMemo(() => {
    const from = searchParams.get('from');
    switch (from) {
      case 'active-vendors':
        return 'Active Vendors';
      case 'waiting-for-approval':
        return 'Waiting for Approval';
      case 'inactive-vendors':
        return 'Inactive Vendors';
      default:
        return undefined;
    }
  }, [searchParams]);

  const getVendorDetails = useCallback(async () => {
    try {
      dispatch({ type: 'FETCHING_VENDOR' });
      const response = await vendorDetails<{ data: VendorDetails }>(
        vendorId || vendorOptionalId || '-1'
      );
      dispatch({ type: 'FETCHED_VENDOR', payload: response.data });
    } catch (e) {
      const error = e as Error;
      dispatch({ type: 'FETCHING_VENDOR_FAILED', payload: error });
    }
  }, [vendorId, vendorOptionalId]);

  useEffect(() => {
    getVendorDetails();
  }, [getVendorDetails]);

  const reloadDetails = () => {
    getVendorDetails();
  };

  const goBack = () => {
    navigate(-1);
  };

  const navigateToVendorsListing = () => {
    navigate(-2);
  };

  const navigateToEditVendor = () => {
    navigate('/edit-vendor', {
      state: {
        vendorDetails: state.vendor,
      },
    });
  };

  const openDeactivateVendorConfirmationDialog = () => {
    setIsDeactivateConfirmationDialogVisible(true);
  };

  const closeDeactivateVendorConfirmationDialog = () => {
    setIsDeactivateConfirmationDialogVisible(false);
  };

  const deactivateVendor = async () => {
    closeDeactivateVendorConfirmationDialog();
    setIsDeactivateVendorApiLoading(true);
    try {
      await deactivateVendorApi(state.vendor?.id as number);
      toastQueue.add(
        {
          title: `Vendor ${state.vendor?.name} deactivated successfully`,
          type: 'success',
        },
        {
          timeout: 2000,
        }
      );
      navigate(-2);
    } catch {
      toastQueue.add(
        {
          title: 'Something went wrong, Please try again',
          type: 'error',
        },
        {
          timeout: 2000,
        }
      );
    } finally {
      setIsDeactivateVendorApiLoading(false);
    }
    return;
  };

  return {
    loadingStatus: state.status,
    vendorDetails: state.vendor,
    fromScreenName,
    hasEditProfilePermission,
    hasDeactivateVendorPermission,
    isDeactivateConfirmationDialogVisible,
    isDeactivateVendorApiLoading,
    reloadDetails,
    goBack,
    navigateToVendorsListing,
    navigateToEditVendor,
    openDeactivateVendorConfirmationDialog,
    closeDeactivateVendorConfirmationDialog,
    deactivateVendor,
  };
};

export const useVendorProfileDetails = () => {
  const [selectedSection, setSelectedSection] =
    useState<AddOrEditVendorFormStates>('primaryDetails');

  const onClickSection = (sectionId: AddOrEditVendorFormStates) => {
    setSelectedSection(sectionId);
  };

  return {
    vendorDetailSections: formFillingStates,
    selectedSection,
    onClickSection,
  };
};

export const useBulkImportVendors = () => {
  const navigate = useNavigate();

  const goBack = () => {
    navigate(-1);
  };

  return {
    goBack,
  };
};

export function useVendorActions(vendorId: string) {
  const [loading, setLoading] = useState<boolean>(false);
  const [isCompleted, setIsCompleted] = useState<boolean>(false);

  const approve = useCallback(async () => {
    setLoading(true);
    setIsCompleted(false);
    try {
      await approveVendor(vendorId);
      setLoading(false);
      setIsCompleted(true);
    } catch (e) {
      const error = e as ErrorResponse;
      toastQueue.add({
        type: 'error',
        title: error.message || 'Something Went Wrong!',
      });
      setLoading(false);
      setIsCompleted(false);
    }
  }, [vendorId]);

  const reject = useCallback(async () => {
    setLoading(true);
    setIsCompleted(false);
    try {
      await rejectVendor(vendorId);
      setLoading(false);
      setIsCompleted(true);
    } catch (e) {
      const error = e as ErrorResponse;
      toastQueue.add({
        type: 'error',
        title: error.message || 'Something Went Wrong!',
      });
      setLoading(false);
      setIsCompleted(false);
    }
  }, [vendorId]);

  return {
    loading,
    isCompleted,
    approve,
    reject,
  };
}
