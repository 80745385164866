import {
  useActiveVendors,
  useEmployees,
  useInvoices,
  useRoleTypes,
  useVendorTypesForFilter,
  useVendorsForFilter,
  vendorRegistrationTypeOptions,
} from '@nbfc-expense-tool/data-store/dashboard';
import {
  ArrowLeftIcon,
  Box,
  BreadcrumbItem,
  Breadcrumbs,
  DateSelect,
  Inline,
  MultiSelect,
  SearchIcon,
  SkeletonRows,
  Stack,
  Tabs,
  Text,
  TextInput,
  Date as DateAndTime,
  Time,
  Avatar,
  Amount,
  EmptyState,
  FileIcon,
  Button,
  Tag,
  RightCaratIcon,
  UserCheckIcon,
  SpinnerIcon,
  ReturnIcon,
} from '@nbfc-expense-tool/ui';
import React, { useCallback, useMemo } from 'react';
import {
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from 'react-router-dom';
import { Item } from 'react-stately';
import { InvoiceStatusTag } from '../../Invoices';
import { debounce } from '@nbfc-expense-tool/data-store/utils';

export default function BranchDetailsPage() {
  const { branchId } = useParams();
  if (!branchId) return null;
  return <BranchDetails key={branchId} branchId={branchId} />;
}

const BranchDetailsTabOptions: Array<{
  title: string;
  id: string;
  node: (props: { branchId: string }) => React.ReactNode;
}> = [
  {
    title: 'Invoices',
    id: 'invoices',
    node: (props) => <BranchInvoices {...props} />,
  },
  {
    title: 'Vendors',
    id: 'vendors',
    node: (props) => <BranchVendors {...props} />,
  },
  {
    title: 'Employees',
    id: 'employees',
    node: (props) => <BranchEmployees {...props} />,
  },
  {
    title: 'Details',
    id: 'branches_details',
    node: () => <BranchDetailsTab />,
  },
];

function BranchDetails({ branchId }: { branchId: string }) {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const branchName = searchParams.get('name');
  function onBack() {
    navigate(-1);
  }

  return (
    <Stack flex="1" paddingX="4" paddingY="5" gap="6">
      <Stack gap="1">
        <Breadcrumbs>
          <BreadcrumbItem isCurrent={false} isFirst onClick={onBack}>
            Branches
          </BreadcrumbItem>
          <BreadcrumbItem isCurrent>
            Branch Details: {branchName}
          </BreadcrumbItem>
        </Breadcrumbs>
        <Inline alignItems="center" gap="6">
          <ArrowLeftIcon size="4" onClick={onBack} cursor="pointer" />
          <Text as="h3" variation="h3">
            Branch Details: {branchName}
          </Text>
        </Inline>
      </Stack>
      <Tabs>
        {BranchDetailsTabOptions.map((option) => (
          <Item textValue={option.id} key={option.id} title={option.title}>
            {option.node({ branchId })}
          </Item>
        ))}
      </Tabs>
    </Stack>
  );
}

const initialFilters = {
  dateFilter: {
    label: 'All Time',
    value: 'all',
  },
};
function BranchInvoices({ branchId }: { branchId: string }) {
  const navigate = useNavigate();
  const {
    params,
    loading,
    perPage,
    canGoBack,
    canGoNext,
    syncingData,
    invoices,
    lastPage,
    currentPage,
    totalInvoices,
    resetFilters,
    handleDateChange,
    handlePageChange,
    handleParamChange,
    hasAppliedFilters,
    exportExcelReport,
    reportLoading,
  } = useInvoices('all-invoices', { ...initialFilters, branches: [branchId] });
  const { status: vendorStatus, vendors } = useVendorsForFilter();
  const vendorForFilters = useMemo(() => {
    return vendors?.map((b) => {
      return {
        label: b.name,
        value: `${b.id}`,
      };
    });
  }, [vendors]);

  const idPrefix = 'branch-invoice-list';

  const onInvoiceClickHandler = useCallback(
    (ticketNumber: string) => {
      navigate(`/home/invoices/${ticketNumber}?from=created-by-you`);
    },
    [navigate]
  );

  return (
    <Stack paddingTop="3" gap="6">
      <Inline justifyContent="between">
        <Inline gap="4">
          <DateSelect
            id={`${idPrefix}-select-date`}
            value={params.dateFilter}
            onSave={(option) => {
              handleDateChange(option);
            }}
          />
          <MultiSelect
            id={`${idPrefix}-select-vendor`}
            label="Vendors"
            actionBtnTitle="Show Results"
            value={params.vendors}
            options={vendorForFilters}
            loadingOptions={vendorStatus === 'in_progress'}
            onSave={(values) => {
              handleParamChange('vendors', values);
            }}
          />
        </Inline>
        <Button
          id={`${idPrefix}-export-excel-button`}
          title="Export To Excel"
          onClick={() => {
            exportExcelReport();
          }}
          type="outlined"
          state={reportLoading ? 'loading' : undefined}
          leftIcon={(props) => <ReturnIcon {...props} />}
        />
      </Inline>
      <Stack
        borderWidth="1"
        backgroundColor="surfaceDefault"
        borderColor="borderSeparator"
        rounded="md"
        gap="6"
      >
        <Inline paddingX="2.5" paddingTop="2.5" justifyContent="between">
          <Box style={{ width: 372 }}>
            <TextInput
              id={`${idPrefix}-search-input`}
              fullWidth
              leftIcon={(props) => <SearchIcon {...props} />}
              onChange={(value) => {
                handleParamChange('q', value);
              }}
              placeholder="Search by invoice number or amount"
            />
          </Box>
          <Inline alignItems="center" gap="6">
            {totalInvoices ? (
              <Text color="textMedium" variation="c1">
                Showing{' '}
                {currentPage === 1 ? 1 : (currentPage - 1) * perPage + 1}-
                {lastPage === currentPage
                  ? totalInvoices
                  : totalInvoices < perPage
                  ? totalInvoices
                  : currentPage * perPage}{' '}
                of {totalInvoices}
              </Text>
            ) : null}
            <Inline gap="2">
              <Box
                id={`${idPrefix}-previous-page`}
                as="button"
                disabled={!canGoBack}
                backgroundColor="transparent"
                onClick={() =>
                  canGoBack ? handlePageChange('previous') : undefined
                }
              >
                <RightCaratIcon
                  cursor="pointer"
                  size="2.5"
                  rotate="180"
                  color={canGoBack ? 'iconMedium' : 'iconLowest'}
                />
              </Box>
              <Box
                as="hr"
                width="px"
                height="2.5"
                backgroundColor="borderSeparator"
              />
              <Box
                id={`${idPrefix}-next-page`}
                as="button"
                disabled={!canGoNext}
                backgroundColor="transparent"
                onClick={() =>
                  canGoNext ? handlePageChange('next') : undefined
                }
              >
                <RightCaratIcon
                  cursor="pointer"
                  size="2.5"
                  color={canGoNext ? 'iconMedium' : 'iconLowest'}
                />
              </Box>
            </Inline>
          </Inline>
        </Inline>
        <Box as="table" width="full" position="relative">
          <Box as="thead" bgColor="surfaceNeutralLowest">
            <Box as="tr">
              <Box
                as="th"
                position="sticky"
                paddingY="1.5"
                paddingX="2"
                style={{
                  width: 82,
                }}
                top="0"
                textAlign="left"
                bgColor="surfaceNeutralLowest"
              >
                <Text variation="c1">Date &amp; Time</Text>
              </Box>
              <Box
                as="th"
                position="sticky"
                paddingY="1.5"
                paddingX="2"
                style={{
                  width: 130,
                }}
                top="0"
                textAlign="left"
                bgColor="surfaceNeutralLowest"
              >
                <Text variation="c1">Creation Date &amp; Time</Text>
              </Box>
              <Box
                as="th"
                position="sticky"
                paddingY="1.5"
                paddingX="2"
                style={{
                  width: 120,
                }}
                top="0"
                textAlign="left"
                bgColor="surfaceNeutralLowest"
              >
                <Text variation="c1">Ticket ID</Text>
              </Box>
              <Box
                as="th"
                position="sticky"
                paddingY="1.5"
                paddingX="2"
                style={{
                  maxWidth: 160,
                }}
                top="0"
                textAlign="left"
                bgColor="surfaceNeutralLowest"
              >
                <Text variation="c1">Vendor</Text>
              </Box>
              <Box
                as="th"
                position="sticky"
                paddingY="1.5"
                paddingX="2"
                style={{
                  width: 120,
                }}
                top="0"
                bgColor="surfaceNeutralLowest"
              >
                <Inline justifyContent="end">
                  <Text variation="c1">Amount</Text>
                </Inline>
              </Box>
              <Box
                as="th"
                position="sticky"
                paddingY="1.5"
                paddingLeft="2"
                paddingRight="3"
                style={{
                  width: 120,
                }}
                top="0"
                bgColor="surfaceNeutralLowest"
                textAlign="left"
              >
                <Text variation="c1">Status</Text>
              </Box>
            </Box>
          </Box>
          {syncingData || loading === 'in_progress' ? (
            <SkeletonRows numOfRows={10} numOfCols={5} />
          ) : (
            <Box as="tbody">
              {invoices?.length ? (
                invoices.map(
                  ({
                    id,
                    created_at,
                    vendor,
                    grand_total_amount,
                    invoice_status,
                    ticket_number,
                    invoice_date,
                  }) => {
                    return (
                      <React.Fragment key={id}>
                        <Box
                          id={`${idPrefix}-${id}`}
                          as="tr"
                          borderTopWidth="1"
                          cursor="pointer"
                          tabIndex={-1}
                          backgroundColor={{
                            hover: 'surfacePrimaryLowest',
                          }}
                          onClick={() => onInvoiceClickHandler(ticket_number)}
                        >
                          <Box
                            as="td"
                            paddingX="2"
                            paddingY="1.5"
                            textAlign="left"
                            borderBottomWidth="1"
                            borderColor="borderSeparator"
                          >
                            <Stack gap="1">
                              <DateAndTime variation="b2" date={invoice_date} />
                              <Time
                                variation="c2"
                                color="textMedium"
                                date={invoice_date}
                                format="hh:mm a"
                              />
                            </Stack>
                          </Box>
                          <Box
                            as="td"
                            paddingX="2"
                            paddingY="1.5"
                            textAlign="left"
                            borderBottomWidth="1"
                            borderColor="borderSeparator"
                          >
                            <Stack gap="1">
                              <DateAndTime variation="b2" date={created_at} />
                              <Time
                                variation="c2"
                                color="textMedium"
                                date={created_at}
                                format="hh:mm a"
                              />
                            </Stack>
                          </Box>
                          <Box
                            as="td"
                            paddingX="2"
                            paddingY="1.5"
                            borderBottomWidth="1"
                            borderColor="borderSeparator"
                          >
                            <Text variation="b2">{ticket_number}</Text>
                          </Box>
                          <Box
                            as="td"
                            paddingX="2"
                            paddingY="1.5"
                            borderBottomWidth="1"
                            borderColor="borderSeparator"
                          >
                            <Inline gap="2" marginBottom="0.5">
                              <Avatar
                                avatarSize="sm"
                                id={vendor?.id.toString() || id.toString()}
                                avatarText={
                                  vendor?.name?.length ? vendor?.name[0] : 'U'
                                }
                              />
                              <Stack gap="1">
                                <Text variation="b2">{vendor?.name}</Text>
                                <Text variation="c2" color="textMedium">
                                  {vendor?.vendor_type?.name}
                                </Text>
                              </Stack>
                            </Inline>
                          </Box>
                          <Box
                            as="td"
                            paddingX="2"
                            paddingY="1.5"
                            className="whitespace-pre"
                            textAlign="right"
                            borderBottomWidth="1"
                            borderColor="borderSeparator"
                          >
                            <Amount
                              amount={Number(grand_total_amount || 0)}
                              variation="t4"
                            />
                          </Box>
                          <Box
                            as="td"
                            paddingLeft="2"
                            paddingRight="3"
                            paddingY="1.5"
                            className="whitespace-pre"
                            textAlign="left"
                            borderBottomWidth="1"
                            borderColor="borderSeparator"
                          >
                            <Box width="fitContent">
                              <InvoiceStatusTag status={invoice_status} />
                            </Box>
                          </Box>
                        </Box>
                      </React.Fragment>
                    );
                  }
                )
              ) : (
                <Box as="tr">
                  <Box as="td" colSpan={8}>
                    <EmptyState
                      renderIcon={(props) => <FileIcon {...props} />}
                      title="No Invoices Found!"
                      subText={
                        hasAppliedFilters
                          ? `Please try changing your applied filters!`
                          : ''
                      }
                      renderButton={
                        hasAppliedFilters
                          ? () => (
                              <Button
                                id={`${idPrefix}-empty-state-reset-filter`}
                                title="Reset Filters"
                                onClick={resetFilters}
                              />
                            )
                          : undefined
                      }
                    />
                  </Box>
                </Box>
              )}
            </Box>
          )}
        </Box>
      </Stack>
    </Stack>
  );
}

function BranchVendors({ branchId }: { branchId: string }) {
  const {
    vendors,
    loading,
    perPage,
    totalVendors,
    canGoNext,
    canGoBack,
    syncingData,
    currentPage,
    lastPage,
    params,
    hasAppliedFilters,
    resetFilters,
    handlePageChange,
    handleDateChange,
    handleParamChange,
  } = useActiveVendors({ branches: [`${branchId}`] });
  const { status: vendorTypesFetchedStatus, vendorTypes: vendorTypesList } =
    useVendorTypesForFilter();
  const navigate = useNavigate();
  const idPrefix = 'branch-vendor-list';

  const vendorTypesForFilters = useMemo(() => {
    return vendorTypesList?.length
      ? vendorTypesList?.map((b) => {
          return {
            label: b.name,
            value: `${b.id}`,
          };
        })
      : [];
  }, [vendorTypesList]);

  const onVendorClickHandler = (vendorId: string) => {
    navigate(`/home/vendors/${vendorId}?from=active-vendors`);
  };

  return !vendors?.length && !hasAppliedFilters ? (
    <Stack paddingTop="10" alignItems="center" justifyContent="center">
      {loading === 'in_progress' ? (
        <Inline gap="4">
          <SpinnerIcon size="3" color="iconMedium" />
          <Text variation="b2">Loading...</Text>
        </Inline>
      ) : (
        <Stack
          gap="4"
          textAlign="center"
          alignItems="center"
          justifyContent="center"
        >
          <UserCheckIcon size="8" color="iconMedium" />
          <Stack gap="2" paddingBottom="0.5">
            <Text variation="t2">No Vendors Found!</Text>
          </Stack>
        </Stack>
      )}
    </Stack>
  ) : (
    <Stack paddingTop="3.5" gap="6">
      <Box gap="4" display="flex">
        <DateSelect
          id={`${idPrefix}-select-date`}
          value={params.dateFilter}
          onSave={(option) => {
            handleDateChange(option);
          }}
        />
        <MultiSelect
          id={`${idPrefix}-select-type`}
          label="Type"
          actionBtnTitle="Show Results"
          value={params.type}
          options={vendorTypesForFilters}
          loadingOptions={vendorTypesFetchedStatus === 'in_progress'}
          onSave={(values) => {
            handleParamChange('type', values);
          }}
        />
        <MultiSelect
          id={`${idPrefix}-registration-type`}
          label="Registration Type"
          actionBtnTitle="Show Results"
          value={params.registrationType}
          options={vendorRegistrationTypeOptions}
          loadingOptions={false}
          onSave={(values) => {
            handleParamChange('registrationType', values);
          }}
        />
      </Box>

      <Box
        rounded="md"
        borderWidth="1"
        paddingTop="2.5"
        borderColor="borderSeparator"
        backgroundColor="surfaceDefault"
      >
        <Stack gap="6">
          <Inline paddingX="2.5" alignItems="center" justifyContent="between">
            <Inline style={{ width: 324 }}>
              <TextInput
                id={`${idPrefix}-search-input`}
                minHeight="5"
                aria-label="search"
                placeholder="Search by Vendor Name"
                leftIcon={(props) => <SearchIcon {...props} marginRight="1" />}
                fullWidth
                onChange={debounce((value) => {
                  handleParamChange('q', [value]);
                })}
              />
            </Inline>
            <Inline alignItems="center" gap="6">
              {totalVendors ? (
                totalVendors <= 10 ? (
                  <Text color="textMedium" variation="c1">
                    Showing{' '}
                    {currentPage === 1 ? 1 : (currentPage - 1) * perPage + 1} of{' '}
                    {totalVendors}
                  </Text>
                ) : (
                  <Text color="textMedium" variation="c1">
                    Showing{' '}
                    {currentPage === 1 ? 1 : (currentPage - 1) * perPage + 1}-
                    {lastPage === currentPage
                      ? totalVendors
                      : vendors
                      ? vendors.length * (currentPage - 1) + vendors.length
                      : 0}{' '}
                    of {totalVendors}
                  </Text>
                )
              ) : null}
              <Inline gap="2">
                <Box
                  id={`${idPrefix}-previous-page`}
                  as="button"
                  disabled={!canGoBack}
                  backgroundColor="transparent"
                  onClick={() =>
                    canGoBack ? handlePageChange('previous') : undefined
                  }
                >
                  <RightCaratIcon
                    cursor="pointer"
                    size="2.5"
                    rotate="180"
                    color={canGoBack ? 'iconMedium' : 'iconLowest'}
                  />
                </Box>
                <Box
                  as="hr"
                  width="px"
                  height="2.5"
                  backgroundColor="borderSeparator"
                />
                <Box
                  id={`${idPrefix}-next-page`}
                  as="button"
                  disabled={!canGoNext}
                  backgroundColor="transparent"
                  onClick={() =>
                    canGoNext ? handlePageChange('next') : undefined
                  }
                >
                  <RightCaratIcon
                    cursor="pointer"
                    size="2.5"
                    color={canGoNext ? 'iconMedium' : 'iconLowest'}
                  />
                </Box>
              </Inline>
            </Inline>
          </Inline>
          <Box as="table" width="full" position="relative">
            <Box as="thead" bgColor="surfaceNeutralLowest">
              <Box as="tr">
                <Box
                  as="th"
                  position="sticky"
                  paddingY="1.5"
                  paddingX="2"
                  style={{
                    width: 248,
                  }}
                  top="0"
                  textAlign="left"
                  bgColor="surfaceNeutralLowest"
                >
                  <Text variation="c1">Vendor Name</Text>
                </Box>
                <Box
                  as="th"
                  position="sticky"
                  paddingY="1.5"
                  paddingX="2"
                  style={{
                    width: 120,
                  }}
                  top="0"
                  textAlign="left"
                  bgColor="surfaceNeutralLowest"
                >
                  <Text variation="c1">Type & Reg. Type</Text>
                </Box>
                <Box
                  as="th"
                  position="sticky"
                  paddingY="1.5"
                  paddingX="2"
                  style={{
                    width: 120,
                  }}
                  top="0"
                  textAlign="left"
                  bgColor="surfaceNeutralLowest"
                >
                  <Text variation="c1">Pending Invoices</Text>
                </Box>
                <Box
                  as="th"
                  position="sticky"
                  paddingY="1.5"
                  paddingX="2"
                  style={{
                    width: 120,
                  }}
                  top="0"
                  bgColor="surfaceNeutralLowest"
                >
                  <Inline>
                    <Text variation="c1">Approved Invoices</Text>
                  </Inline>
                </Box>
                <Box
                  as="th"
                  position="sticky"
                  paddingY="1.5"
                  paddingX="2"
                  style={{
                    width: 120,
                  }}
                  top="0"
                  bgColor="surfaceNeutralLowest"
                >
                  <Inline>
                    <Text variation="c1">Paid</Text>
                  </Inline>
                </Box>
                <Box
                  as="th"
                  position="sticky"
                  paddingY="1.5"
                  paddingX="2"
                  style={{
                    width: 120,
                  }}
                  top="0"
                  bgColor="surfaceNeutralLowest"
                >
                  <Inline>
                    <Text variation="c1">Unpaid</Text>
                  </Inline>
                </Box>
              </Box>
            </Box>
            {syncingData ? (
              <SkeletonRows numOfRows={10} numOfCols={5} />
            ) : (
              <Box as="tbody">
                {vendors?.length ? (
                  vendors.map((vendor) => {
                    return (
                      <React.Fragment key={vendor.id}>
                        <Box
                          id={`${idPrefix}-${vendor.id}`}
                          as="tr"
                          borderTopWidth="1"
                          cursor="pointer"
                          tabIndex={-1}
                          backgroundColor={{
                            hover: 'surfacePrimaryLowest',
                          }}
                          borderBottomWidth="1"
                          borderColor="borderSeparator"
                          onClick={() =>
                            onVendorClickHandler(String(vendor.id))
                          }
                        >
                          <Box
                            as="td"
                            paddingX="2"
                            paddingY="2"
                            borderBottomWidth="1"
                            borderColor="borderSeparator"
                          >
                            <Inline marginBottom="0.5" alignItems="center">
                              <Avatar
                                avatarSize="lg"
                                id={
                                  vendor?.id.toString() || vendor.id.toString()
                                }
                                avatarText={
                                  vendor?.name?.length ? vendor?.name[0] : 'U'
                                }
                              />
                              <Stack marginLeft="1.5" flex="1">
                                <Text variation="t4">{vendor?.name}</Text>
                                <Text
                                  variation="c2"
                                  color="textLow"
                                  marginTop="0.5"
                                >
                                  {vendor?.contact_person_details?.email}
                                </Text>
                              </Stack>
                            </Inline>
                          </Box>
                          <Box
                            as="td"
                            paddingX="2"
                            paddingY="2"
                            className="whitespace-pre"
                            textAlign="left"
                            borderBottomWidth="1"
                            borderColor="borderSeparator"
                            valign="top"
                          >
                            <Stack>
                              <Text variation="t4">
                                {vendor.vendor_type?.name}
                              </Text>
                              <Text variation="c2" color="textLow">
                                {
                                  vendorRegistrationTypeOptions.find(
                                    (item) =>
                                      item.value === vendor.company_reg_type
                                  )?.label
                                }
                              </Text>
                            </Stack>
                          </Box>
                          <Box
                            as="td"
                            paddingX="2"
                            paddingY="2"
                            className="whitespace-pre"
                            textAlign="left"
                            borderBottomWidth="1"
                            borderColor="borderSeparator"
                            valign="top"
                          >
                            <Stack>
                              <Text variation="t4">
                                {vendor.pending_invoices_count || 0}
                              </Text>
                              <Amount
                                variation="b2"
                                amount={
                                  vendor.pending_invoices_sum_grand_total_amount ||
                                  0
                                }
                                color="textLow"
                              />
                            </Stack>
                          </Box>
                          <Box
                            as="td"
                            paddingX="2"
                            paddingY="2"
                            className="whitespace-pre"
                            textAlign="left"
                            borderBottomWidth="1"
                            borderColor="borderSeparator"
                            valign="top"
                          >
                            <Stack>
                              <Text variation="t4">
                                {vendor.approved_invoices_count || 0}
                              </Text>
                              <Amount
                                variation="b2"
                                amount={
                                  vendor.approved_invoices_sum_grand_total_amount ||
                                  0
                                }
                                color="textLow"
                              />
                            </Stack>
                          </Box>
                          <Box
                            as="td"
                            paddingX="2"
                            paddingY="2"
                            className="whitespace-pre"
                            textAlign="left"
                            borderBottomWidth="1"
                            borderColor="borderSeparator"
                            valign="top"
                          >
                            <Stack>
                              <Text variation="t4" color="textSuccess">
                                {vendor.paid_invoices_count || 0}
                              </Text>
                              <Amount
                                variation="b2"
                                amount={
                                  vendor.paid_invoices_sum_grand_total_amount ||
                                  0
                                }
                                color="textSuccess"
                              />
                            </Stack>
                          </Box>
                          <Box
                            as="td"
                            paddingX="2"
                            paddingY="2"
                            className="whitespace-pre"
                            textAlign="left"
                            borderBottomWidth="1"
                            borderColor="borderSeparator"
                            valign="top"
                          >
                            <Stack>
                              <Text variation="b2" color="textError">
                                {vendor.unpaid_invoices_count || 0}
                              </Text>
                              <Amount
                                variation="b2"
                                amount={
                                  vendor.unpaid_invoices_sum_grand_total_amount ||
                                  0
                                }
                                color="textError"
                              />
                            </Stack>
                          </Box>
                        </Box>
                      </React.Fragment>
                    );
                  })
                ) : (
                  <Box as="tr">
                    <Box as="td" colSpan={8}>
                      <EmptyState
                        renderIcon={(props) => <FileIcon {...props} />}
                        title="No Vendors Found!"
                        subText={`Please try changing your applied filters!`}
                        renderButton={() => (
                          <Button
                            id={`${idPrefix}-empty-state-reset-filter`}
                            title="Reset Filters"
                            onClick={resetFilters}
                          />
                        )}
                      />
                    </Box>
                  </Box>
                )}
              </Box>
            )}
          </Box>
        </Stack>
      </Box>
    </Stack>
  );
}

function BranchEmployees({ branchId }: { branchId: string }) {
  const {
    employees,
    loading,
    perPage,
    totalEmployees,
    canGoNext,
    canGoBack,
    syncingData,
    currentPage,
    lastPage,
    params,
    resetFilters,
    handlePageChange,
    handleSearchQuery,
    handleParamChange,
    hasAppliedFilters,
  } = useEmployees({ branches: [branchId] });
  const idPrefix = 'branch-employees-list';
  const { status: rolesStatus, roles } = useRoleTypes();
  const rolesForFilters = useMemo(() => {
    return roles?.map((r) => {
      return {
        label: r.display_name,
        value: `${r.name}`,
      };
    });
  }, [roles]);
  return (
    <Stack paddingTop="3" gap="6">
      <Inline gap="4">
        <MultiSelect
          id={`${idPrefix}-select-designation`}
          label="Designation"
          actionBtnTitle="Show Results"
          value={params.roles}
          options={rolesForFilters}
          loadingOptions={rolesStatus === 'in_progress'}
          onSave={(values) => {
            handleParamChange('role', values);
          }}
        />
      </Inline>
      <Box
        rounded="md"
        borderWidth="1"
        paddingTop="2.5"
        borderColor="borderSeparator"
        backgroundColor="surfaceDefault"
      >
        <Stack gap="6">
          <Inline paddingX="2.5" alignItems="center" justifyContent="between">
            <Inline style={{ width: 324 }}>
              <TextInput
                minHeight="5"
                id={`${idPrefix}-search-input`}
                aria-label="search"
                placeholder="Search by name or code"
                leftIcon={(props) => <SearchIcon {...props} marginRight="1" />}
                onChange={(value) => {
                  handleSearchQuery(value);
                }}
                fullWidth
              />
            </Inline>
            <Inline alignItems="center" gap="6">
              {totalEmployees ? (
                <Text color="textMedium" variation="c1">
                  Showing{' '}
                  {currentPage === 1 ? 1 : (currentPage - 1) * perPage + 1}-
                  {lastPage === currentPage
                    ? totalEmployees
                    : employees?.length}{' '}
                  of {totalEmployees}
                </Text>
              ) : null}
              <Inline gap="2">
                <Box
                  id={`${idPrefix}-previous-page`}
                  as="button"
                  disabled={!canGoBack}
                  backgroundColor="transparent"
                  onClick={() =>
                    canGoBack ? handlePageChange('previous') : undefined
                  }
                >
                  <RightCaratIcon
                    cursor="pointer"
                    size="2.5"
                    rotate="180"
                    color={canGoBack ? 'iconMedium' : 'iconLowest'}
                  />
                </Box>
                <Box
                  as="hr"
                  width="px"
                  height="2.5"
                  backgroundColor="borderSeparator"
                />
                <Box
                  id={`${idPrefix}-next-page`}
                  as="button"
                  disabled={!canGoNext}
                  backgroundColor="transparent"
                  onClick={() =>
                    canGoNext ? handlePageChange('next') : undefined
                  }
                >
                  <RightCaratIcon
                    cursor="pointer"
                    size="2.5"
                    color={canGoNext ? 'iconMedium' : 'iconLowest'}
                  />
                </Box>
              </Inline>
            </Inline>
          </Inline>
          <Box as="table" width="full" position="relative">
            <Box as="thead" bgColor="surfaceNeutralLowest">
              <Box as="tr">
                <Box
                  as="th"
                  position="sticky"
                  paddingY="1.5"
                  paddingX="2"
                  style={{
                    width: 248,
                  }}
                  top="0"
                  textAlign="left"
                  bgColor="surfaceNeutralLowest"
                >
                  <Text variation="c1">Employeee Name & Code</Text>
                </Box>
                <Box
                  as="th"
                  position="sticky"
                  paddingY="1.5"
                  paddingX="2"
                  style={{
                    width: 120,
                  }}
                  top="0"
                  textAlign="left"
                  bgColor="surfaceNeutralLowest"
                >
                  <Text variation="c1">Designation</Text>
                </Box>
                <Box
                  as="th"
                  position="sticky"
                  paddingY="1.5"
                  paddingX="2"
                  style={{
                    maxWidth: 120,
                    width: 120,
                  }}
                  top="0"
                  textAlign="left"
                  bgColor="surfaceNeutralLowest"
                >
                  <Text variation="c1">Branch</Text>
                </Box>
                <Box
                  as="th"
                  position="sticky"
                  paddingY="1.5"
                  paddingX="2"
                  style={{
                    width: 120,
                  }}
                  top="0"
                  bgColor="surfaceNeutralLowest"
                >
                  <Inline justifyContent="start">
                    <Text variation="c1">Mobile Number</Text>
                  </Inline>
                </Box>
                <Box
                  as="th"
                  position="sticky"
                  paddingY="1.5"
                  paddingLeft="2"
                  paddingRight="3"
                  style={{
                    width: 120,
                  }}
                  top="0"
                  bgColor="surfaceNeutralLowest"
                  textAlign="left"
                >
                  <Text variation="c1">Email Id</Text>
                </Box>
              </Box>
            </Box>
            {syncingData || loading === 'in_progress' ? (
              <SkeletonRows numOfRows={10} numOfCols={5} />
            ) : (
              <Box as="tbody">
                {employees?.length ? (
                  employees.map(
                    ({
                      id,
                      email,
                      name,
                      phone_number,
                      roles,
                      branches,
                      avatar,
                      employee_code,
                    }) => {
                      return (
                        <Box
                          key={id}
                          as="tr"
                          cursor="pointer"
                          tabIndex={-1}
                          bgColor={{
                            hover: 'surfacePrimaryLowest',
                          }}
                        >
                          <Box
                            as="td"
                            paddingX="2"
                            paddingY={'1.5'}
                            textAlign="left"
                            borderBottomWidth="1"
                            borderColor="borderSeparator"
                          >
                            <Inline gap="4" alignItems="center">
                              {avatar?.preview.length ? (
                                <Avatar
                                  as="img"
                                  avatarSize="lg"
                                  src={avatar.preview}
                                />
                              ) : (
                                <Avatar
                                  id={id.toString()}
                                  avatarSize="lg"
                                  avatarText={name?.length ? name[0] : 'U'}
                                />
                              )}

                              <Stack gap="1">
                                <Text variation="b2" marginBottom="0.5">
                                  {name}
                                </Text>
                                {employee_code ? (
                                  <Text variation="c2" color={'textLow'}>
                                    {employee_code}
                                  </Text>
                                ) : null}
                              </Stack>
                            </Inline>
                          </Box>
                          <Box
                            as="td"
                            paddingX="2"
                            paddingY={'1.5'}
                            borderBottomWidth="1"
                            borderColor="borderSeparator"
                          >
                            <Text variation="b2">
                              {roles?.length ? roles[0].display_name : ''}
                            </Text>
                          </Box>
                          <Box
                            as="td"
                            paddingX="2"
                            paddingY={'1.5'}
                            borderBottomWidth="1"
                            borderColor="borderSeparator"
                          >
                            <Text variation="b2">
                              {branches?.length ? branches[0].name : ''}
                            </Text>
                          </Box>
                          <Box
                            as="td"
                            borderBottomWidth="1"
                            borderColor="borderSeparator"
                            paddingX="2"
                            paddingY={'1.5'}
                            className="whitespace-pre"
                            textAlign="left"
                          >
                            <Text variation="b2">{phone_number || 'NA'}</Text>
                          </Box>
                          <Box
                            borderBottomWidth="1"
                            borderColor="borderSeparator"
                            as="td"
                            paddingLeft="2"
                            paddingRight="3"
                            className="whitespace-pre"
                            textAlign="left"
                            paddingY={'1.5'}
                          >
                            <Text variation="b2">{email || 'NA'}</Text>
                          </Box>
                        </Box>
                      );
                    }
                  )
                ) : (
                  <Box as="tr">
                    <Box as="td" colSpan={8}>
                      <EmptyState
                        renderIcon={(props) => <FileIcon {...props} />}
                        title="No Employees Found!"
                        subText={
                          hasAppliedFilters
                            ? `Please try changing your applied filters!`
                            : ''
                        }
                        renderButton={
                          hasAppliedFilters
                            ? () => (
                                <Button
                                  id={`${idPrefix}-empty-state-reset-filter`}
                                  title="Reset Filters"
                                  onClick={resetFilters}
                                />
                              )
                            : undefined
                        }
                      />
                    </Box>
                  </Box>
                )}
              </Box>
            )}
          </Box>
        </Stack>
      </Box>
    </Stack>
  );
}

function BranchDetailsTab() {
  const { state } = useLocation();
  return (
    <Stack
      marginTop="2.5"
      backgroundColor="surfaceDefault"
      borderWidth="1"
      borderColor="borderSeparator"
      rounded="md"
      paddingTop="2"
      paddingX="3"
      gap="6"
      paddingBottom="3"
    >
      <Inline
        borderBottomWidth="1"
        borderColor="borderSeparator"
        paddingBottom="2"
      >
        <Stack gap="1">
          <Text variation="t3">{state.name}</Text>
          <Inline color="textLow" gap="2" alignItems="center">
            <Text variation="b2">Pincode - {state.address_pincode}</Text>
            <Text variation="b2">•</Text>
            <Text variation="b2">Branch Code - {state.branch_code}</Text>
            <Box paddingLeft="1">
              <Tag
                status={state?.branch_level === 5 ? 'success' : 'default'}
                title={
                  state.branch_level
                    ? `${state?.branch_level !== 1 ? '' : 'Local'}(${
                        state?.branch_level
                      })`
                    : 'Local (1)'
                }
              />
            </Box>
          </Inline>
        </Stack>
      </Inline>
      <Stack gap="10" maxWidth="2xl">
        <Inline>
          <Stack gap="1" width="1/3">
            <Text variation="c2" color="textLow">
              Parent Branch
            </Text>
            {!state?.parent_branch?.name ? (
              <Text variation="b2" color="textHigh">
                {' '}
                NA
              </Text>
            ) : (
              <Text variation="b2" color="textHigh">
                {state.parent_branch?.name} - {state.parent_branch?.branch_code}
              </Text>
            )}
          </Stack>
          <Stack gap="1" width="1/3">
            <Text variation="c2" color="textLow">
              Business By
            </Text>
            <Text variation="b2" color="textHigh">
              {state?.business_by || 'NA'}
            </Text>
          </Stack>
          <Stack gap="1" width="1/3">
            <Text variation="c2" color="textLow">
              Rent By
            </Text>
            <Text variation="b2" color="textHigh">
              {state?.rent_by || 'NA'}
            </Text>
          </Stack>
        </Inline>
        <Inline>
          <Stack gap="1" minWidth="1/3">
            <Text variation="c2" color="textLow">
              Mobile Number
            </Text>
            <Text variation="b2" color="textHigh">
              {state?.phone_number || 'NA'}
            </Text>
          </Stack>
          <Stack gap="1">
            <Text variation="c2" color="textLow">
              Branch Address
            </Text>
            <Text variation="b2" color="textHigh">
              {state?.address_line_1 ? `${state.address_line_1}, ` : ''}
              {state?.address_line_2 ? `${state?.address_line_2}, ` : ''}
              {state?.address_city ? `${state.address_city}, ` : ''}{' '}
              {state?.address_state ? `${state.address_state} - ` : ''}
              {state.address_pincode}
            </Text>
          </Stack>
        </Inline>
      </Stack>
    </Stack>
  );
}
