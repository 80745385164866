import { useBulkMarkPaidEntities } from '@nbfc-expense-tool/data-store/dashboard';
import {
  Box,
  BoxOwnProps,
  Button,
  CheckCircle,
  CrossCircle,
  DownloadIcon,
  FileIcon,
  FilePlusIcon,
  Inline,
  SpinnerIcon,
  Stack,
  Text,
  UploadIcon,
  Banner,
} from '@nbfc-expense-tool/ui';
import React, { useMemo } from 'react';

type Props = {
  entityType: 'invoices';
  downloadSampleFile: () => void;
  downloadSampleFileLoading: boolean;
  appliedFiltersList?: string[];
  onClickOpenEntityList?: () => void;
};

const BulkMarkPaidEntities: React.FC<Props & BoxOwnProps> = ({
  entityType,
  downloadSampleFile,
  downloadSampleFileLoading,
  appliedFiltersList = [],
  onClickOpenEntityList,
  ...props
}) => {
  const {
    labels,
    entitiesToBeMarkedPaidFile,
    markPaidEntitiesApiState,
    fileInputRef,
    clickFileInputField,
    onPickFile,
    markPaidEntities,
  } = useBulkMarkPaidEntities(entityType);

  const renderSelectFileView = useMemo(() => {
    if (!entitiesToBeMarkedPaidFile) {
      return (
        <Inline
          paddingX="3"
          paddingY="4"
          marginBottom="2"
          width="full"
          justifyContent="between"
          alignItems="center"
          borderWidth="1"
          borderRadius="md"
          borderColor="borderSeparator"
          borderStyle="dashed"
          backgroundColor="surfaceNeutralLowest"
        >
          <Inline flex="1" alignItems="center">
            <FilePlusIcon size="6" color="iconPrimary" marginRight="3" />
            <Box>
              <Text variation="t4">Select xlsx/csv File</Text>
              <Text variation="c2" color="textMedium">
                Please upload file in excel file format only
              </Text>
            </Box>
          </Inline>
          <Button
            id={`select-file-for-${entityType}`}
            type="outlined"
            title={'Select File'}
            onClick={clickFileInputField}
            leftIcon={(props) => <UploadIcon {...props} />}
          />
        </Inline>
      );
    }
    return (
      <Inline
        paddingX="3"
        paddingY="4"
        marginBottom="2"
        width="full"
        justifyContent="between"
        alignItems="center"
        borderWidth="1"
        borderRadius="md"
        borderColor="borderSeparator"
      >
        <Inline flex="1" alignItems="center" marginRight="4">
          <FileIcon size="6" color="iconPrimary" marginRight="3" />
          <Box>
            <Text variation="t4">{entitiesToBeMarkedPaidFile.name || '-'}</Text>
            <Inline alignItems="center" marginTop="1">
              <Text variation="c2" color="textMedium" flex="1">
                {entitiesToBeMarkedPaidFile.type || '-'}
              </Text>
              <Box
                width="0.5"
                height="0.5"
                marginX="0.5"
                borderRadius="full"
                backgroundColor="surfaceNeutralMedium"
              />
              <Text variation="c2" color="textMedium">
                {((entitiesToBeMarkedPaidFile.size || 0) / 1024).toFixed(2)} KB
              </Text>
            </Inline>
          </Box>
        </Inline>
        {(markPaidEntitiesApiState.status === 'idle' ||
          markPaidEntitiesApiState.status === 'error') && (
          <Button
            id={`change-for-${entityType}`}
            type="outlined"
            title={'Change'}
            onClick={clickFileInputField}
          />
        )}
      </Inline>
    );
  }, [
    entitiesToBeMarkedPaidFile,
    markPaidEntitiesApiState,
    clickFileInputField,
    entityType,
  ]);

  const renderViewBasedOnEntitiesState = useMemo(() => {
    switch (markPaidEntitiesApiState.status) {
      case 'idle':
        if (entitiesToBeMarkedPaidFile) {
          return (
            <Inline
              paddingX="3"
              paddingY="2"
              borderWidth="1"
              borderRadius="md"
              borderColor="borderSeparator"
              width="full"
              justifyContent="end"
            >
              <Button
                id={`mark-paid-file-for-${entityType}`}
                type="filled"
                title={`Mark Paid ${labels[entityType].singularLabel}`}
                onClick={markPaidEntities}
              />
            </Inline>
          );
        }
        return null;
      case 'loading':
        return (
          <Inline
            paddingX="3"
            paddingY="2"
            borderWidth="1"
            borderRadius="md"
            borderColor="borderSeparator"
            width="full"
          >
            <SpinnerIcon size="3" color="iconLowest" marginRight="2" />
            <Text variation="b1" color="textLow">{`Marking Paid ${labels[
              entityType
            ].singularLabel.toLowerCase()}, please wait...`}</Text>
          </Inline>
        );
      case 'success':
        return (
          <Inline
            paddingX="3"
            paddingY="2"
            borderWidth="1"
            borderRadius="md"
            borderColor="borderSuccessLow"
            backgroundColor="surfaceSuccessLowest"
            width="full"
            justifyContent="between"
          >
            <Inline flex="1" marginRight="3">
              <CheckCircle size="3" color="iconSuccess" marginRight="2" />
              <Text variation="b1" color="textSuccess">
                {markPaidEntitiesApiState.message}
              </Text>
            </Inline>
            <Text
              id={`open-${entityType}-file`}
              variation="lk"
              color="textSuccess"
              cursor="pointer"
              onClick={onClickOpenEntityList}
            >{`Open ${labels[entityType].singularLabel} List`}</Text>
          </Inline>
        );
      case 'error':
        return (
          <Inline
            paddingX="3"
            paddingY="2"
            borderWidth="1"
            borderRadius="md"
            borderColor="borderErrorLow"
            backgroundColor="surfaceErrorLowest"
            width="full"
          >
            <CrossCircle size="3" color="iconError" marginRight="2" />
            <Text variation="b1" color="textError">
              {markPaidEntitiesApiState.message}
            </Text>
          </Inline>
        );
      default:
        return null;
    }
  }, [
    markPaidEntitiesApiState.status,
    markPaidEntitiesApiState.message,
    entitiesToBeMarkedPaidFile,
    labels,
    entityType,
    onClickOpenEntityList,
    markPaidEntities,
  ]);

  return (
    <Box
      paddingX="3"
      paddingY="4"
      borderRadius="md"
      borderWidth="1"
      borderColor="borderSeparator"
      backgroundColor="surfaceDefault"
      {...props}
    >
      <Stack alignItems="center" marginBottom="8" paddingX="20">
        {renderSelectFileView}
        {renderViewBasedOnEntitiesState}
      </Stack>
      <Stack textAlign="center" alignItems="center">
        <Text variation="t4">Download excel file with invoice numbers</Text>
        <Text variation="b3" color="textMedium" marginTop="1">
          Please enter the UTR numbers corresponding to each invoice in the
          below excel and mark them all paid at once.
        </Text>
        <Button
          id={`download-${entityType}-file`}
          title="Download Excel File"
          type="outlined"
          leftIcon={(props) => <DownloadIcon {...props} />}
          marginTop="2"
          onClick={downloadSampleFile}
          state={downloadSampleFileLoading ? 'loading' : undefined}
        />
      </Stack>
      <Box
        as="input"
        type="file"
        ref={fileInputRef}
        onChange={onPickFile}
        display="none"
        accept=".xlsx, .xls, .csv"
      />
      {appliedFiltersList.length ? (
        <Banner
          marginTop="3"
          bannerType="warning"
          bannerText={`${
            labels[entityType].pluralLabel
          } included in the excel file will be based on these filters: ${appliedFiltersList.join(
            ', '
          )}`}
        />
      ) : null}
    </Box>
  );
};

export default BulkMarkPaidEntities;
