import {
  InvoiceDetails,
  Invoice_Status,
  Payment_status,
  useActionOnInvoice,
} from '@nbfc-expense-tool/data-store/dashboard';
import {
  Alert,
  Amount,
  ApprovedInvoiceIcon,
  Avatar,
  Box,
  Button,
  CheckIcon,
  CrossIcon,
  Date,
  Inline,
  Modal,
  ModalBody,
  ModalFooter,
  RejectedInvoiceIcon,
  ReturnedInvoiceIcon,
  Stack,
  Text,
  TextArea,
  TextInput,
  toastQueue,
  Checkbox,
} from '@nbfc-expense-tool/ui';
import { useMemo, useState } from 'react';
import { useOverlayTriggerState } from 'react-stately';
import { useForm, Controller } from 'react-hook-form';
import { z } from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';
import { ErrorResponse } from '@nbfc-expense-tool/data-store/utils';

export function InvoiceStatusTag({
  status,
}: {
  status: Invoice_Status | Payment_status;
}) {
  const colorsAndText: {
    color: React.ComponentProps<typeof Stack>['color'];
    bgColor: React.ComponentProps<typeof Stack>['backgroundColor'];
    label: string;
  } = useMemo(() => {
    switch (status) {
      case 'under_review':
        return {
          label: 'Under Review',
          color: 'textWarning',
          bgColor: 'surfaceWarningLowest',
        };
      case 'rejected':
        return {
          label: 'Rejected',
          color: 'textError',
          bgColor: 'surfaceErrorLowest',
        };
      case 'approved':
        return {
          label: 'Approved',
          color: 'textSuccess',
          bgColor: 'surfaceSuccessLowest',
        };
      case 'returned':
        return {
          label: 'Returned',
          color: 'textMedium',
          bgColor: 'surfaceNeutralLowest',
        };
      case 'paid':
        return {
          label: 'Paid',
          color: 'textSuccess',
          bgColor: 'surfaceSuccessLowest',
        };
      case 'unpaid':
        return {
          label: 'Unpaid',
          color: 'textWarning',
          bgColor: 'surfaceWarningLowest',
        };
      case 'hold':
        return {
          label: 'On Hold',
          color: 'textMedium',
          bgColor: 'surfaceNeutralLowest',
        };
      default:
        return {
          label: 'Returned',
          color: 'textMedium',
          bgColor: 'surfaceNeutralLowest',
        };
    }
  }, [status]);
  return (
    <Stack
      backgroundColor={colorsAndText.bgColor}
      paddingX="1"
      alignItems="center"
      justifyContent="center"
      rounded="md"
      style={{ paddingTop: 2, paddingBottom: 2 }}
    >
      <Text variation="c1" color={colorsAndText.color}>
        {colorsAndText.label}
      </Text>
    </Stack>
  );
}

type InvoiceActionTypes = 'approve' | 'reject' | 'return' | 'markPaid';
export function ActionOnInvoiceInModal({
  children,
  ...props
}: React.ComponentProps<typeof ApproveAnInvoice> & {
  children: (props: {
    onOpen: (type: InvoiceActionTypes) => void;
  }) => React.ReactNode;
}) {
  const [actionType, setActionType] = useState<InvoiceActionTypes | undefined>(
    undefined
  );
  const [isActionDone, setIsActionDone] = useState<boolean>(false);
  const state = useOverlayTriggerState({});

  const markIsActionDone = () => {
    setIsActionDone(true);
  };

  return (
    <>
      {children({
        onOpen: (type: InvoiceActionTypes) => {
          state.open();
          setActionType(type);
        },
      })}
      <Modal
        isOpen={state.isOpen && Boolean(actionType)}
        onClose={state.close}
        placement="right"
        title={
          actionType === 'approve'
            ? isActionDone
              ? 'Approved'
              : 'Approve Invoice?'
            : actionType === 'reject'
            ? isActionDone
              ? 'Rejected'
              : 'Reject Invoice?'
            : actionType === 'return'
            ? isActionDone
              ? 'Returned'
              : 'Return Invoice?'
            : actionType === 'markPaid'
            ? isActionDone
              ? 'Marked Paid'
              : 'Mark Paid?'
            : ''
        }
        isDismissable
        size="lg"
      >
        {actionType === 'approve' ? (
          <ApproveAnInvoice
            {...props}
            onClose={state.close}
            setActionStatus={markIsActionDone}
          />
        ) : actionType === 'reject' ? (
          <RejectAnInvoice
            {...props}
            onClose={state.close}
            setActionStatus={markIsActionDone}
          />
        ) : actionType === 'return' ? (
          <ReturnAnInvoice
            {...props}
            onClose={state.close}
            setActionStatus={markIsActionDone}
          />
        ) : actionType === 'markPaid' ? (
          <MarkPaidAnInvoice
            {...props}
            onClose={state.close}
            setActionStatus={markIsActionDone}
          />
        ) : null}
      </Modal>
    </>
  );
}

const actionOnInvoiceSchema = z.object({
  remark: z.string().optional(),
});

function ApproveAnInvoice({
  invoice,
  onClose,
  onSuccess,
  setActionStatus,
}: {
  invoice: InvoiceDetails;
  onClose?: () => void;
  onSuccess?: () => void;
  setActionStatus?: () => void;
}) {
  const [approved, setApproved] = useState<boolean>(false);
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

  const form = useForm<{ remark?: string }>({
    resolver: zodResolver(actionOnInvoiceSchema),
  });
  const { control, getValues, handleSubmit } = form;
  const {
    ticket_number,
    vendor_id,
    grand_total_amount,
    vendor,
    invoice_status,
  } = invoice;
  const [isCheckboxSelected, setIsCheckboxSelected] = useState(false);

  const approveAnInvoice = useActionOnInvoice(ticket_number, 'approve');

  const approve = async (data: { remark?: string }) => {
    setIsSubmitting(true);
    try {
      await approveAnInvoice(data.remark);
      setApproved(true);
      setActionStatus?.();
      setIsSubmitting(false);
    } catch (e) {
      setIsSubmitting(false);
    }
  };

  return approved ? (
    <>
      <ModalBody>
        <Stack gap="10">
          <Stack gap="5" justifyContent="center" alignItems="center">
            <ApprovedInvoiceIcon size="10" />
            <Box maxWidth="sm" textAlign="center">
              <Text as="h2" variation="h2">
                Invoice Approved Successfully!
              </Text>
            </Box>
          </Stack>
          <Box borderWidth="1" borderColor="borderOutline" rounded="md">
            <Inline
              paddingY="2"
              paddingX="3"
              alignItems="center"
              justifyContent="between"
              borderBottomWidth="1"
              borderColor="borderOutline"
            >
              <Text variation="t3">Invoice: {ticket_number}</Text>
              <Inline gap="2">
                <Text variation="t4" color="textMedium">
                  Status:{' '}
                </Text>
                <InvoiceStatusTag status="approved" />
              </Inline>
            </Inline>
            <Stack
              gap="8"
              backgroundColor="surfaceNeutralLowest"
              paddingX="3"
              paddingBottom="3"
            >
              <Inline
                justifyContent="between"
                borderBottomWidth="1"
                paddingY="2"
                borderColor="borderOutline"
              >
                <Inline gap="4">
                  <Avatar
                    avatarSize="lg"
                    id={vendor_id.toString()}
                    avatarText={vendor?.name?.[0]}
                  />
                  <Stack gap="1">
                    <Text variation="t1">{vendor?.name}</Text>
                    <Text variation="b2" color="textLow">
                      {vendor?.vendor_type?.name}
                    </Text>
                  </Stack>
                </Inline>
                <Stack textAlign="right">
                  <Amount variation="t1" amount={Number(grand_total_amount)} />
                  <Text variation="b2" color="textMedium">
                    Amount
                  </Text>
                </Stack>
              </Inline>
              <Inline>
                <Inline width="1/4">
                  <Stack>
                    <Text variation="c2" color="textLow">
                      Invoice Number
                    </Text>
                    <Text variation="b2">{invoice.invoice_number}</Text>
                  </Stack>
                </Inline>
                <Inline width="1/4">
                  <Stack>
                    <Text variation="c2" color="textLow">
                      Branch
                    </Text>
                    <Text variation="b2">
                      {invoice.branch.name} - {invoice.branch.branch_code}
                    </Text>
                  </Stack>
                </Inline>
                <Inline width="1/4">
                  <Stack>
                    <Text variation="c2" color="textLow">
                      Invoice Date
                    </Text>
                    <Date variation="b2" date={invoice.invoice_date} />
                  </Stack>
                </Inline>
                <Inline width="1/4">
                  <Stack>
                    <Text variation="c2" color="textLow">
                      Due Date
                    </Text>
                    <Date variation="b2" date={invoice.due_date} />
                  </Stack>
                </Inline>
              </Inline>
            </Stack>
          </Box>
          {getValues('remark')?.length ? (
            <Stack gap="1">
              <Text color="textLow" variation="c1">
                Remark
              </Text>
              <Text variation="b1">{getValues('remark')}</Text>
            </Stack>
          ) : null}
        </Stack>
      </ModalBody>
      <ModalFooter>
        <Button
          id={'invoive-approval-done'}
          size="lg"
          title="Ok, Got it"
          onClick={() => {
            onSuccess?.();
            onClose?.();
          }}
          leftIcon={(props) => <CheckIcon {...props} />}
        />
      </ModalFooter>
    </>
  ) : (
    <>
      <ModalBody>
        <Stack gap="4">
          <Text variation="t1">Confirm Invoice Details</Text>
          <Stack gap="12">
            <Box borderWidth="1" borderColor="borderOutline" rounded="md">
              <Inline
                paddingY="2"
                paddingX="3"
                alignItems="center"
                justifyContent="between"
                borderBottomWidth="1"
                borderColor="borderOutline"
              >
                <Text variation="t3">Invoice: {ticket_number}</Text>
                <Inline gap="2">
                  <Text variation="t4" color="textMedium">
                    Status:{' '}
                  </Text>
                  <InvoiceStatusTag status={invoice_status} />
                </Inline>
              </Inline>
              <Stack
                gap="8"
                backgroundColor="surfaceNeutralLowest"
                paddingX="3"
                paddingBottom="3"
              >
                <Inline
                  justifyContent="between"
                  borderBottomWidth="1"
                  paddingY="2"
                  borderColor="borderOutline"
                >
                  <Inline gap="4">
                    <Avatar
                      avatarSize="lg"
                      id={vendor_id.toString()}
                      avatarText={vendor?.name?.[0]}
                    />
                    <Stack gap="1">
                      <Text variation="t1">{vendor?.name}</Text>
                      <Text variation="b2" color="textLow">
                        {vendor?.vendor_type?.name}
                      </Text>
                    </Stack>
                  </Inline>
                  <Stack textAlign="right">
                    <Amount
                      variation="t1"
                      amount={Number(grand_total_amount)}
                    />
                    <Text variation="b2" color="textMedium">
                      Amount
                    </Text>
                  </Stack>
                </Inline>
                <Inline>
                  <Inline width="1/4">
                    <Stack>
                      <Text variation="c2" color="textLow">
                        Invoice Number
                      </Text>
                      <Text variation="b2">{invoice.invoice_number}</Text>
                    </Stack>
                  </Inline>
                  <Inline width="1/4">
                    <Stack>
                      <Text variation="c2" color="textLow">
                        Branch
                      </Text>
                      <Text variation="b2">
                        {invoice.branch.name} - {invoice.branch.branch_code}
                      </Text>
                    </Stack>
                  </Inline>
                  <Inline width="1/4">
                    <Stack>
                      <Text variation="c2" color="textLow">
                        Invoice Date
                      </Text>
                      <Date variation="b2" date={invoice.invoice_date} />
                    </Stack>
                  </Inline>
                  <Inline width="1/4">
                    <Stack>
                      <Text variation="c2" color="textLow">
                        Due Date
                      </Text>
                      <Date variation="b2" date={invoice.due_date} />
                    </Stack>
                  </Inline>
                </Inline>
              </Stack>
            </Box>

            <Controller
              control={control}
              name="remark"
              render={(props) => {
                const {
                  field: { onChange },
                  fieldState: { error },
                } = props;
                return (
                  <Stack gap="1">
                    <Text variation="t1">Enter Remark</Text>
                    <TextArea
                      id="remark"
                      name="remark"
                      placeholder="Add remark here"
                      onChange={onChange}
                      errorMessage={error?.message}
                    />
                  </Stack>
                );
              }}
            />
          </Stack>
        </Stack>
      </ModalBody>
      <ModalFooter
        display="flex"
        justifyContent="start"
        paddingX="0"
        paddingY="0"
        shadowLevel="s1"
      >
        <Stack flex="1">
          <Inline
            alignItems="center"
            onClick={() => setIsCheckboxSelected((prevState) => !prevState)}
            cursor="pointer"
            paddingY="1"
            borderBottomWidth="1"
            borderColor="borderSeparator"
            marginBottom="2"
            paddingX="3"
          >
            <Checkbox
              isSelected={isCheckboxSelected}
              onChange={(value) => setIsCheckboxSelected(value)}
            />
            <Text variation="c2" marginLeft="1.5">
              I confirm that I have thoroughly reviewed all the details of this
              invoice.
            </Text>
          </Inline>
          <Inline justifyContent="end" paddingX="5" paddingBottom="2">
            <Button
              id={'invoive-approval-cancel'}
              title="Cancel"
              type="outlined"
              size="lg"
              leftIcon={(props) => <CrossIcon {...props} />}
              onClick={onClose}
              marginRight="2"
            />
            <Button
              id={'invoive-approval-confirm'}
              title="Yes, Approve Invoice"
              size="lg"
              buttonRole="success"
              state={
                !isCheckboxSelected
                  ? 'disabled'
                  : isSubmitting
                  ? 'loading'
                  : undefined
              }
              onClick={handleSubmit(approve)}
              leftIcon={(props) => <CheckIcon {...props} />}
            />
          </Inline>
        </Stack>
      </ModalFooter>
    </>
  );
}

const rejectOnInvoiceSchema = z.object({
  remark: z
    .string({ required_error: 'Please enter a reason for rejection.' })
    .nonempty(),
});

function RejectAnInvoice({
  invoice,
  onClose,
  onSuccess,
  setActionStatus,
}: {
  invoice: InvoiceDetails;
  onClose?: () => void;
  onSuccess?: () => void;
  setActionStatus?: () => void;
}) {
  const [rejected, setRejected] = useState<boolean>(false);
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const form = useForm<{ remark?: string }>({
    resolver: zodResolver(rejectOnInvoiceSchema),
  });
  const { control, getValues, handleSubmit } = form;
  const {
    ticket_number,
    vendor_id,
    grand_total_amount,
    vendor,
    invoice_status,
  } = invoice;

  const rejectAnInvoice = useActionOnInvoice(ticket_number, 'reject');

  const reject = async (data: { remark?: string }) => {
    setIsSubmitting(true);
    try {
      await rejectAnInvoice(data.remark);
      setRejected(true);
      setActionStatus?.();
      setIsSubmitting(false);
    } catch (e) {
      setIsSubmitting(false);
    }
  };

  return rejected ? (
    <>
      <ModalBody>
        <Stack gap="10">
          <Stack gap="5" justifyContent="center" alignItems="center">
            <RejectedInvoiceIcon size="10" />
            <Box maxWidth="sm" textAlign="center">
              <Text as="h2" variation="h2">
                Invoice Rejected Successfully!
              </Text>
            </Box>
          </Stack>
          <Box borderWidth="1" borderColor="borderOutline" rounded="md">
            <Inline
              paddingY="2"
              paddingX="3"
              alignItems="center"
              justifyContent="between"
              borderBottomWidth="1"
              borderColor="borderOutline"
            >
              <Text variation="t3">Invoice: {ticket_number}</Text>
              <Inline gap="2">
                <Text variation="t4" color="textMedium">
                  Status:{' '}
                </Text>
                <InvoiceStatusTag status="rejected" />
              </Inline>
            </Inline>
            <Stack
              gap="8"
              backgroundColor="surfaceNeutralLowest"
              paddingX="3"
              paddingBottom="3"
            >
              <Inline
                justifyContent="between"
                borderBottomWidth="1"
                paddingY="2"
                borderColor="borderOutline"
              >
                <Inline gap="4">
                  <Avatar
                    avatarSize="lg"
                    id={vendor_id.toString()}
                    avatarText={vendor?.name?.[0]}
                  />
                  <Stack gap="1">
                    <Text variation="t1">{vendor?.name}</Text>
                    <Text variation="b2" color="textLow">
                      {vendor?.vendor_type?.name}
                    </Text>
                  </Stack>
                </Inline>
                <Stack textAlign="right">
                  <Amount variation="t1" amount={Number(grand_total_amount)} />
                  <Text variation="b2" color="textMedium">
                    Amount
                  </Text>
                </Stack>
              </Inline>
              <Inline>
                <Inline width="1/4">
                  <Stack>
                    <Text variation="c2" color="textLow">
                      Invoice Number
                    </Text>
                    <Text variation="b2">{invoice.invoice_number}</Text>
                  </Stack>
                </Inline>
                <Inline width="1/4">
                  <Stack>
                    <Text variation="c2" color="textLow">
                      Branch
                    </Text>
                    <Text variation="b2">
                      {invoice.branch.name} - {invoice.branch.branch_code}
                    </Text>
                  </Stack>
                </Inline>
                <Inline width="1/4">
                  <Stack>
                    <Text variation="c2" color="textLow">
                      Invoice Date
                    </Text>
                    <Date variation="b2" date={invoice.invoice_date} />
                  </Stack>
                </Inline>
                <Inline width="1/4">
                  <Stack>
                    <Text variation="c2" color="textLow">
                      Due Date
                    </Text>
                    <Date variation="b2" date={invoice.due_date} />
                  </Stack>
                </Inline>
              </Inline>
            </Stack>
          </Box>
          {getValues('remark')?.length ? (
            <Stack gap="1">
              <Text color="textLow" variation="c1">
                Reason for rejection
              </Text>
              <Text variation="b1">{getValues('remark')}</Text>
            </Stack>
          ) : null}
        </Stack>
      </ModalBody>
      <ModalFooter>
        <Button
          id={'invoive-rejection-done'}
          title="Ok, Got it"
          size="lg"
          onClick={() => {
            onSuccess?.();
            onClose?.();
          }}
        />
      </ModalFooter>
    </>
  ) : (
    <>
      <ModalBody>
        <Stack gap="4">
          <Alert status="warning">
            <Text variation="t3" color="textWarning">
              If you reject the invoice then it needs to be created once again
              for approval. Consider using ‘Return’ if you want to make changes
            </Text>
          </Alert>
          <Text variation="t1">Confirm Invoice Details</Text>
          <Stack gap="12">
            <Box borderWidth="1" borderColor="borderOutline" rounded="md">
              <Inline
                paddingY="2"
                paddingX="3"
                alignItems="center"
                justifyContent="between"
                borderBottomWidth="1"
                borderColor="borderOutline"
              >
                <Text variation="t3">Invoice: {ticket_number}</Text>
                <Inline gap="2">
                  <Text variation="t4" color="textMedium">
                    Status:{' '}
                  </Text>
                  <InvoiceStatusTag status={invoice_status} />
                </Inline>
              </Inline>
              <Stack
                gap="8"
                backgroundColor="surfaceNeutralLowest"
                paddingX="3"
                paddingBottom="3"
              >
                <Inline
                  justifyContent="between"
                  borderBottomWidth="1"
                  paddingY="2"
                  borderColor="borderOutline"
                >
                  <Inline gap="4">
                    <Avatar
                      avatarSize="lg"
                      id={vendor_id.toString()}
                      avatarText={vendor?.name?.[0]}
                    />
                    <Stack gap="1">
                      <Text variation="t1">{vendor?.name}</Text>
                      <Text variation="b2" color="textLow">
                        {vendor?.vendor_type?.name}
                      </Text>
                    </Stack>
                  </Inline>
                  <Stack textAlign="right">
                    <Amount
                      variation="t1"
                      amount={Number(grand_total_amount)}
                    />
                    <Text variation="b2" color="textMedium">
                      Amount
                    </Text>
                  </Stack>
                </Inline>
                <Inline>
                  <Inline width="1/4">
                    <Stack>
                      <Text variation="c2" color="textLow">
                        Invoice Number
                      </Text>
                      <Text variation="b2">{invoice.invoice_number}</Text>
                    </Stack>
                  </Inline>
                  <Inline width="1/4">
                    <Stack>
                      <Text variation="c2" color="textLow">
                        Branch
                      </Text>
                      <Text variation="b2">
                        {invoice.branch.name} - {invoice.branch.branch_code}
                      </Text>
                    </Stack>
                  </Inline>
                  <Inline width="1/4">
                    <Stack>
                      <Text variation="c2" color="textLow">
                        Invoice Date
                      </Text>
                      <Date variation="b2" date={invoice.invoice_date} />
                    </Stack>
                  </Inline>
                  <Inline width="1/4">
                    <Stack>
                      <Text variation="c2" color="textLow">
                        Due Date
                      </Text>
                      <Date variation="b2" date={invoice.due_date} />
                    </Stack>
                  </Inline>
                </Inline>
              </Stack>
            </Box>

            <Controller
              control={control}
              name="remark"
              render={(props) => {
                const {
                  field: { onChange },
                  fieldState: { error },
                } = props;
                return (
                  <Stack gap="1">
                    <TextArea
                      id="remark"
                      name="remark"
                      labelVariation="t1"
                      label="Enter reason for rejection"
                      placeholder="Please mention why are you rejecting this invoice?"
                      onChange={onChange}
                      isRequired
                      errorMessage={error?.message}
                    />
                  </Stack>
                );
              }}
            />
          </Stack>
        </Stack>
      </ModalBody>
      <ModalFooter>
        <Button
          id={'invoive-rejection-cancel'}
          title="Cancel"
          type="outlined"
          size="lg"
          leftIcon={(props) => <CrossIcon {...props} />}
          onClick={onClose}
        />
        <Button
          id={'invoive-rejection-confirm'}
          title="Yes, Reject Invoice"
          size="lg"
          buttonRole="destructive"
          state={isSubmitting ? 'loading' : undefined}
          onClick={handleSubmit(reject)}
          leftIcon={(props) => <CheckIcon {...props} />}
        />
      </ModalFooter>
    </>
  );
}

function ReturnAnInvoice({
  invoice,
  onClose,
  onSuccess,
  setActionStatus,
}: {
  invoice: InvoiceDetails;
  onClose?: () => void;
  onSuccess?: () => void;
  setActionStatus?: () => void;
}) {
  const [returned, setReturned] = useState<boolean>(false);
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const form = useForm<{ remark?: string }>({
    resolver: zodResolver(rejectOnInvoiceSchema),
  });
  const { control, getValues, handleSubmit } = form;
  const {
    ticket_number,
    vendor_id,
    grand_total_amount,
    vendor,
    invoice_status,
  } = invoice;

  const returnAnInvoice = useActionOnInvoice(ticket_number, 'return');

  const returnInvoice = async (data: { remark?: string }) => {
    setIsSubmitting(true);
    try {
      await returnAnInvoice(data.remark);
      setReturned(true);
      setActionStatus?.();
      setIsSubmitting(false);
    } catch (e) {
      setIsSubmitting(false);
    }
  };

  return returned ? (
    <>
      <ModalBody>
        <Stack gap="10">
          <Stack gap="5" justifyContent="center" alignItems="center">
            <ReturnedInvoiceIcon size="10" />
            <Box maxWidth="sm" textAlign="center">
              <Text as="h2" variation="h2">
                Invoice Returned Successfully!
              </Text>
            </Box>
          </Stack>
          <Box borderWidth="1" borderColor="borderOutline" rounded="md">
            <Inline
              paddingY="2"
              paddingX="3"
              alignItems="center"
              justifyContent="between"
              borderBottomWidth="1"
              borderColor="borderOutline"
            >
              <Text variation="t3">Invoice: {ticket_number}</Text>
              <Inline gap="2">
                <Text variation="t4" color="textMedium">
                  Status:{' '}
                </Text>
                <InvoiceStatusTag status="returned" />
              </Inline>
            </Inline>
            <Stack
              gap="8"
              backgroundColor="surfaceNeutralLowest"
              paddingX="3"
              paddingBottom="3"
            >
              <Inline
                justifyContent="between"
                borderBottomWidth="1"
                paddingY="2"
                borderColor="borderOutline"
              >
                <Inline gap="4">
                  <Avatar
                    avatarSize="lg"
                    id={vendor_id.toString()}
                    avatarText={vendor?.name?.[0]}
                  />
                  <Stack gap="1">
                    <Text variation="t1">{vendor?.name}</Text>
                    <Text variation="b2" color="textLow">
                      {vendor?.vendor_type?.name}
                    </Text>
                  </Stack>
                </Inline>
                <Stack textAlign="right">
                  <Amount variation="t1" amount={Number(grand_total_amount)} />
                  <Text variation="b2" color="textMedium">
                    Amount
                  </Text>
                </Stack>
              </Inline>
              <Inline>
                <Inline width="1/4">
                  <Stack>
                    <Text variation="c2" color="textLow">
                      Invoice Number
                    </Text>
                    <Text variation="b2">{invoice.invoice_number}</Text>
                  </Stack>
                </Inline>
                <Inline width="1/4">
                  <Stack>
                    <Text variation="c2" color="textLow">
                      Branch
                    </Text>
                    <Text variation="b2">
                      {invoice.branch.name} - {invoice.branch.branch_code}
                    </Text>
                  </Stack>
                </Inline>
                <Inline width="1/4">
                  <Stack>
                    <Text variation="c2" color="textLow">
                      Invoice Date
                    </Text>
                    <Date variation="b2" date={invoice.invoice_date} />
                  </Stack>
                </Inline>
                <Inline width="1/4">
                  <Stack>
                    <Text variation="c2" color="textLow">
                      Due Date
                    </Text>
                    <Date variation="b2" date={invoice.due_date} />
                  </Stack>
                </Inline>
              </Inline>
            </Stack>
          </Box>
          {getValues('remark')?.length ? (
            <Stack gap="1">
              <Text color="textLow" variation="c1">
                Changes to be made
              </Text>
              <Text variation="b1">{getValues('remark')}</Text>
            </Stack>
          ) : null}
        </Stack>
      </ModalBody>
      <ModalFooter>
        <Button
          id={'invoive-return-done'}
          title="Ok, Got it"
          size="lg"
          onClick={() => {
            onSuccess?.();
            onClose?.();
          }}
        />
      </ModalFooter>
    </>
  ) : (
    <>
      <ModalBody>
        <Stack gap="4">
          <Alert status="warning">
            <Text variation="t3" color="textWarning">
              If you return this invoice, this will go to invoice creator to
              make changes. All the other people in the approval chain will have
              to approve it again
            </Text>
          </Alert>
          <Text variation="t1">Confirm Invoice Details</Text>
          <Stack gap="12">
            <Box borderWidth="1" borderColor="borderOutline" rounded="md">
              <Inline
                paddingY="2"
                paddingX="3"
                alignItems="center"
                justifyContent="between"
                borderBottomWidth="1"
                borderColor="borderOutline"
              >
                <Text variation="t3">Invoice: {ticket_number}</Text>
                <Inline gap="2">
                  <Text variation="t4" color="textMedium">
                    Status:{' '}
                  </Text>
                  <InvoiceStatusTag status={invoice_status} />
                </Inline>
              </Inline>
              <Stack
                gap="8"
                backgroundColor="surfaceNeutralLowest"
                paddingX="3"
                paddingBottom="3"
              >
                <Inline
                  justifyContent="between"
                  borderBottomWidth="1"
                  paddingY="2"
                  borderColor="borderOutline"
                >
                  <Inline gap="4">
                    <Avatar
                      avatarSize="lg"
                      id={vendor_id.toString()}
                      avatarText={vendor?.name?.[0]}
                    />
                    <Stack gap="1">
                      <Text variation="t1">{vendor?.name}</Text>
                      <Text variation="b2" color="textLow">
                        {vendor?.vendor_type?.name}
                      </Text>
                    </Stack>
                  </Inline>
                  <Stack textAlign="right">
                    <Amount
                      variation="t1"
                      amount={Number(grand_total_amount)}
                    />
                    <Text variation="b2" color="textMedium">
                      Amount
                    </Text>
                  </Stack>
                </Inline>
                <Inline>
                  <Inline width="1/4">
                    <Stack>
                      <Text variation="c2" color="textLow">
                        Invoice Number
                      </Text>
                      <Text variation="b2">{invoice.invoice_number}</Text>
                    </Stack>
                  </Inline>
                  <Inline width="1/4">
                    <Stack>
                      <Text variation="c2" color="textLow">
                        Branch
                      </Text>
                      <Text variation="b2">
                        {invoice.branch.name} - {invoice.branch.branch_code}
                      </Text>
                    </Stack>
                  </Inline>
                  <Inline width="1/4">
                    <Stack>
                      <Text variation="c2" color="textLow">
                        Invoice Date
                      </Text>
                      <Date variation="b2" date={invoice.invoice_date} />
                    </Stack>
                  </Inline>
                  <Inline width="1/4">
                    <Stack>
                      <Text variation="c2" color="textLow">
                        Due Date
                      </Text>
                      <Date variation="b2" date={invoice.due_date} />
                    </Stack>
                  </Inline>
                </Inline>
              </Stack>
            </Box>

            <Controller
              control={control}
              name="remark"
              render={(props) => {
                const {
                  field: { onChange },
                  fieldState: { error },
                } = props;
                return (
                  <TextArea
                    id="remark"
                    name="remark"
                    labelVariation="t1"
                    label="Suggest changes to be made"
                    placeholder="Please mention specific issues here?"
                    onChange={onChange}
                    isRequired
                    errorMessage={error?.message}
                  />
                );
              }}
            />
          </Stack>
        </Stack>
      </ModalBody>
      <ModalFooter>
        <Button
          id={'invoive-return-cancel'}
          title="Cancel"
          type="outlined"
          size="lg"
          leftIcon={(props) => <CrossIcon {...props} />}
          onClick={onClose}
        />
        <Button
          id={'invoive-return-confirm'}
          title="Yes, Return Invoice"
          size="lg"
          buttonRole="destructive"
          state={isSubmitting ? 'loading' : undefined}
          onClick={handleSubmit(returnInvoice)}
          leftIcon={(props) => <CheckIcon {...props} />}
        />
      </ModalFooter>
    </>
  );
}

const markPaidOnInvoiceSchema = z.object({
  utr_number: z.string(),
});

function MarkPaidAnInvoice({
  invoice,
  onClose,
  onSuccess,
  setActionStatus,
}: {
  invoice: InvoiceDetails;
  onClose?: () => void;
  onSuccess?: () => void;
  setActionStatus?: () => void;
}) {
  const [ismarkPaid, setIsMarkPaid] = useState<boolean>(false);
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

  const form = useForm<{ utr_number?: string }>({
    resolver: zodResolver(markPaidOnInvoiceSchema),
  });
  const { control, getValues, handleSubmit } = form;

  const {
    ticket_number,
    vendor_id,
    grand_total_amount,
    vendor,
    payment_status,
    remarks,
  } = invoice;

  const markPaidAnInvoice = useActionOnInvoice(ticket_number, 'markPaid');

  const markPaid = async (data: { utr_number?: string }) => {
    setIsSubmitting(true);
    try {
      await markPaidAnInvoice(remarks || '', data.utr_number);
      setIsMarkPaid(true);
      setActionStatus?.();
      setIsSubmitting(false);
    } catch (e) {
      const err = e as ErrorResponse;
      toastQueue.add(
        {
          title: err?.message || 'Something went wrong',
          type: 'error',
        },
        {
          timeout: 2000,
        }
      );
      setIsSubmitting(false);
    }
  };

  return ismarkPaid ? (
    <>
      <ModalBody>
        <Stack gap="10">
          <Stack gap="5" justifyContent="center" alignItems="center">
            <ApprovedInvoiceIcon size="10" />
            <Box maxWidth="sm" textAlign="center">
              <Text as="h2" variation="h2">
                Invoice Marked Paid Successfully!
              </Text>
            </Box>
          </Stack>
          <Box borderWidth="1" borderColor="borderOutline" rounded="md">
            <Inline
              paddingY="2"
              paddingX="3"
              alignItems="center"
              justifyContent="between"
              borderBottomWidth="1"
              borderColor="borderOutline"
            >
              <Text variation="t3">Invoice: {ticket_number}</Text>
              <Inline gap="2">
                <Text variation="t4" color="textMedium">
                  Paid Status:{' '}
                </Text>
                <InvoiceStatusTag status="paid" />
              </Inline>
            </Inline>
            <Stack
              gap="8"
              backgroundColor="surfaceNeutralLowest"
              paddingX="3"
              paddingBottom="3"
            >
              <Inline
                justifyContent="between"
                borderBottomWidth="1"
                paddingY="2"
                borderColor="borderOutline"
              >
                <Inline gap="4">
                  <Avatar
                    avatarSize="lg"
                    id={vendor_id.toString()}
                    avatarText={vendor?.name?.[0]}
                  />
                  <Stack gap="1">
                    <Text variation="t1">{vendor?.name}</Text>
                    <Text variation="b2" color="textLow">
                      {vendor?.vendor_type?.name}
                    </Text>
                  </Stack>
                </Inline>
                <Stack textAlign="right">
                  <Amount variation="t1" amount={Number(grand_total_amount)} />
                  <Text variation="b2" color="textMedium">
                    Amount
                  </Text>
                </Stack>
              </Inline>
              <Inline>
                <Inline width="1/4">
                  <Stack>
                    <Text variation="c2" color="textLow">
                      Invoice Number
                    </Text>
                    <Text variation="b2">{invoice.invoice_number}</Text>
                  </Stack>
                </Inline>
                <Inline width="1/4">
                  <Stack>
                    <Text variation="c2" color="textLow">
                      Branch
                    </Text>
                    <Text variation="b2">
                      {invoice.branch.name} - {invoice.branch.branch_code}
                    </Text>
                  </Stack>
                </Inline>
                <Inline width="1/4">
                  <Stack>
                    <Text variation="c2" color="textLow">
                      Invoice Date
                    </Text>
                    <Date variation="b2" date={invoice.invoice_date} />
                  </Stack>
                </Inline>
                <Inline width="1/4">
                  <Stack>
                    <Text variation="c2" color="textLow">
                      Approve Date
                    </Text>
                    <Date
                      variation="b2"
                      date={invoice.final_approval_at || ''}
                    />
                  </Stack>
                </Inline>
              </Inline>
            </Stack>
          </Box>
          <Stack gap="1">
            <Text variation="c0" color="textLow">
              Remark
            </Text>
            <Text variation="b1">{invoice.remarks}</Text>
          </Stack>
          {getValues('utr_number')?.length ? (
            <Stack gap="1">
              <Text color="textLow" variation="c1">
                UTR Number
              </Text>
              <Text variation="b1">{getValues('utr_number')}</Text>
            </Stack>
          ) : null}
        </Stack>
      </ModalBody>
      <ModalFooter>
        <Button
          id="invoive-mark-paid-done"
          size="lg"
          title="Ok, Got it"
          onClick={() => {
            onSuccess?.();
            onClose?.();
          }}
          leftIcon={(props) => <CheckIcon {...props} />}
        />
      </ModalFooter>
    </>
  ) : (
    <>
      <ModalBody>
        <Stack gap="4">
          <Text variation="t1">Confirm Invoice Details</Text>
          <Stack gap="12">
            <Box borderWidth="1" borderColor="borderOutline" rounded="md">
              <Inline
                paddingY="2"
                paddingX="3"
                alignItems="center"
                justifyContent="between"
                borderBottomWidth="1"
                borderColor="borderOutline"
              >
                <Text variation="t3">Invoice: {ticket_number}</Text>
                <Inline gap="2">
                  <Text variation="t4" color="textMedium">
                    Paid Status:{' '}
                  </Text>
                  <InvoiceStatusTag status={payment_status} />
                </Inline>
              </Inline>
              <Stack
                gap="8"
                backgroundColor="surfaceNeutralLowest"
                paddingX="3"
                paddingBottom="3"
              >
                <Inline
                  justifyContent="between"
                  borderBottomWidth="1"
                  paddingY="2"
                  borderColor="borderOutline"
                >
                  <Inline gap="4">
                    <Avatar
                      avatarSize="lg"
                      id={vendor_id.toString()}
                      avatarText={vendor?.name?.[0]}
                    />
                    <Stack gap="1">
                      <Text variation="t1">{vendor?.name}</Text>
                      <Text variation="b2" color="textLow">
                        {vendor?.vendor_type?.name}
                      </Text>
                    </Stack>
                  </Inline>
                  <Stack textAlign="right">
                    <Amount
                      variation="t1"
                      amount={Number(grand_total_amount)}
                    />
                    <Text variation="b2" color="textMedium">
                      Amount
                    </Text>
                  </Stack>
                </Inline>
                <Inline>
                  <Inline width="1/4">
                    <Stack>
                      <Text variation="c2" color="textLow">
                        Invoice Number
                      </Text>
                      <Text variation="b2">{invoice.invoice_number}</Text>
                    </Stack>
                  </Inline>
                  <Inline width="1/4">
                    <Stack>
                      <Text variation="c2" color="textLow">
                        Branch
                      </Text>
                      <Text variation="b2">
                        {invoice.branch.name} - {invoice.branch.branch_code}
                      </Text>
                    </Stack>
                  </Inline>
                  <Inline width="1/4">
                    <Stack>
                      <Text variation="c2" color="textLow">
                        Invoice Date
                      </Text>
                      <Date variation="b2" date={invoice?.invoice_date || ''} />
                    </Stack>
                  </Inline>
                  <Inline width="1/4">
                    <Stack>
                      <Text variation="c2" color="textLow">
                        Approve Date
                      </Text>
                      <Date
                        variation="b2"
                        date={invoice?.final_approval_at || ''}
                      />
                    </Stack>
                  </Inline>
                </Inline>
              </Stack>
            </Box>
            <Stack gap="1">
              <Text variation="c0" color="textLow">
                Remark
              </Text>
              <Text variation="b1">{invoice.remarks || '-'}</Text>
            </Stack>
            <Controller
              control={control}
              name="utr_number"
              render={(props) => {
                const {
                  field: { onChange },
                  fieldState: { error },
                } = props;
                return (
                  <Stack gap="1">
                    <Inline>
                      <Text variation="t1">{'Enter UTR Number '}</Text>
                      <Text variation="t1" color={'textError'}>
                        *
                      </Text>
                    </Inline>
                    <TextInput
                      id="utr_number"
                      name="Utr_number"
                      placeholder="Example: HDFCR92023012200543116"
                      onChange={onChange}
                      errorMessage={error?.message}
                    />
                    <Text variation="c2" color="textLow">
                      UTR reference number helps in identifying a RTGS, IMPS or
                      NEFT transaction
                    </Text>
                  </Stack>
                );
              }}
            />
          </Stack>
        </Stack>
      </ModalBody>
      <ModalFooter>
        <Button
          id="invoive-mark-paid-cancel"
          title="Cancel"
          type="outlined"
          size="lg"
          leftIcon={(props) => <CrossIcon {...props} />}
          onClick={onClose}
        />
        <Button
          id="invoive-mark-paid-confirm"
          title="Yes, mark paid"
          size="lg"
          state={isSubmitting ? 'loading' : undefined}
          onClick={handleSubmit(markPaid)}
          leftIcon={(props) => <CheckIcon {...props} />}
        />
      </ModalFooter>
    </>
  );
}
