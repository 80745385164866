import { PERMISSION_LIST, WILD_CARD_PERMISSIONS } from './permissionList';
import { permission } from './type';
/* eslint-disable-next-line */
export interface DataStorePermissionsProps {}

export type Operations =
  | 'createRole'
  | 'updateRole'
  | 'readRole'
  | 'deleteRole'
  | 'assignRole'
  | 'createBranch'
  | 'updateBranch'
  | 'readBranch'
  | 'deleteBranch'
  | 'createEmp'
  | 'readEmp'
  | 'updateEmp'
  | 'deleteEmp'
  | 'readVendor'
  | 'createVendor'
  | 'updateVendor'
  | 'deleteVendor'
  | 'readInvoice'
  | 'createInvoice'
  | 'updateInvoice'
  | 'deleteInvoice'
  | 'markPaidInvoice'
  | 'markPaidInvoiceBulk'
  | 'importVendors'
  | 'importBranches'
  | 'importEmployees'
  | 'readInvoiceApproval'
  | 'readVendorApproval'
  | 'readReimbursementApproval'
  | 'readReimbursement'
  | 'createReimbursement'
  | 'updateReimbursement'
  | 'deleteReimbursement'
  | 'markPaidReimbursement';

export function checkForSinglePermission(
  operationName: Operations,
  permissions: permission[] | null
) {
  if (!permissions) return false;
  let filteredPermissions = [];
  if (operationName === 'createRole') {
    filteredPermissions = permissions.filter(
      (permission) => permission.name === PERMISSION_LIST.CREATE_ROLE
    );
  } else if (operationName === 'updateRole') {
    filteredPermissions = permissions.filter(
      (permission) => permission.name === PERMISSION_LIST.UPDATE_ROLE
    );
  } else if (operationName === 'readRole') {
    filteredPermissions = permissions.filter(
      (permission) => permission.name === PERMISSION_LIST.READ_ROLE
    );
  } else if (operationName === 'deleteRole') {
    filteredPermissions = permissions.filter(
      (permission) => permission.name === PERMISSION_LIST.DELETE_ROLE
    );
  } else if (operationName === 'assignRole') {
    filteredPermissions = permissions.filter(
      (permission) => permission.name === PERMISSION_LIST.ASSIGN_ROLE
    );
  } else if (operationName === 'createBranch') {
    filteredPermissions = permissions.filter(
      (permission) => permission.name === PERMISSION_LIST.CREATE_BRANCH
    );
  } else if (operationName === 'updateBranch') {
    filteredPermissions = permissions.filter(
      (permission) => permission.name === PERMISSION_LIST.UPDATE_BRANCH
    );
  } else if (operationName === 'readBranch') {
    filteredPermissions = permissions.filter(
      (permission) => permission.name === PERMISSION_LIST.READ_BRANCH
    );
  } else if (operationName === 'deleteBranch') {
    filteredPermissions = permissions.filter(
      (permission) => permission.name === PERMISSION_LIST.DELETE_BRANCH
    );
  } else if (operationName === 'createEmp') {
    filteredPermissions = permissions.filter(
      (permission) =>
        permission.name === PERMISSION_LIST.CREATE_ANY_EMPLOYEE ||
        permission.name === PERMISSION_LIST.CREATE_BRANCH_EMPLOYEE
    );
  } else if (operationName === 'updateEmp') {
    filteredPermissions = permissions.filter(
      (permission) =>
        permission.name === PERMISSION_LIST.UPDATE_ANY_EMPLOYEE ||
        permission.name === PERMISSION_LIST.UPDATE_BRANCH_EMPLOYEE
    );
  } else if (operationName === 'readEmp') {
    filteredPermissions = permissions.filter(
      (permission) =>
        permission.name === PERMISSION_LIST.READ_ANY_EMPLOYEE ||
        permission.name === PERMISSION_LIST.READ_BRANCH_EMPLOYEE
    );
  } else if (operationName === 'deleteEmp') {
    filteredPermissions = permissions.filter(
      (permission) =>
        permission.name === PERMISSION_LIST.DELETE_ANY_EMPLOYEE ||
        permission.name === PERMISSION_LIST.DELETE_BRANCH_EMPLOYEE
    );
  } else if (operationName === 'createVendor') {
    filteredPermissions = permissions.filter(
      (permission) =>
        permission.name === PERMISSION_LIST.CREATE_ANY_VENDOR ||
        permission.name === PERMISSION_LIST.CREATE_BRANCH_VENDOR
    );
  } else if (operationName === 'updateVendor') {
    filteredPermissions = permissions.filter(
      (permission) =>
        permission.name === PERMISSION_LIST.UPDATE_ANY_VENDOR ||
        permission.name === PERMISSION_LIST.UPDATE_BRANCH_VENDOR
    );
  } else if (operationName === 'deleteVendor') {
    filteredPermissions = permissions.filter(
      (permission) =>
        permission.name === PERMISSION_LIST.DELETE_ANY_VENDOR ||
        permission.name === PERMISSION_LIST.DELETE_BRANCH_VENDOR
    );
  } else if (operationName === 'readVendor') {
    filteredPermissions = permissions.filter(
      (permission) =>
        permission.name === PERMISSION_LIST.READ_ANY_VENDOR ||
        permission.name === PERMISSION_LIST.READ_BRANCH_VENDOR
    );
  } else if (operationName === 'createInvoice') {
    filteredPermissions = permissions.filter(
      (permission) =>
        permission.name === PERMISSION_LIST.CREATE_ANY_INVOICE ||
        permission.name === PERMISSION_LIST.CREATE_BRANCH_INVOICE ||
        permission.name === PERMISSION_LIST.CREATE_OWN_INVOICE
    );
  } else if (operationName === 'updateInvoice') {
    filteredPermissions = permissions.filter(
      (permission) =>
        permission.name === PERMISSION_LIST.UPDATE_ANY_INVOICE ||
        permission.name === PERMISSION_LIST.UPDATE_BRANCH_INVOICE ||
        permission.name === PERMISSION_LIST.UPDATE_OWN_INVOICE
    );
  } else if (operationName === 'deleteInvoice') {
    filteredPermissions = permissions.filter(
      (permission) =>
        permission.name === PERMISSION_LIST.DELETE_ANY_INVOICE ||
        permission.name === PERMISSION_LIST.DELETE_BRANCH_INVOICE ||
        permission.name === PERMISSION_LIST.DELETE_OWN_INVOICE
    );
  } else if (operationName === 'readInvoice') {
    filteredPermissions = permissions.filter(
      (permission) =>
        permission.name === PERMISSION_LIST.READ_ANY_INVOICE ||
        permission.name === PERMISSION_LIST.READ_BRANCH_INVOICE ||
        permission.name === PERMISSION_LIST.READ_OWN_INVOICE
    );
  } else if (operationName === 'markPaidInvoice') {
    filteredPermissions = permissions.filter(
      (permission) =>
        permission.name === PERMISSION_LIST.MARK_PAID_ANY_INVOICE ||
        permission.name === PERMISSION_LIST.MARK_PAID_BRANCH_INVOICE
    );
  } else if (operationName === 'markPaidInvoiceBulk') {
    filteredPermissions = permissions.filter(
      (permission) => permission.name === PERMISSION_LIST.MARK_PAID_BULK_INVOICE
    );
  } else if (operationName === 'importVendors') {
    filteredPermissions = permissions.filter(
      (permission) => permission.name === PERMISSION_LIST.IMPORT_ANY_VENDOR
    );
  } else if (operationName === 'importBranches') {
    filteredPermissions = permissions.filter(
      (permission) => permission.name === PERMISSION_LIST.IMPORT_BRANCH
    );
  } else if (operationName === 'importEmployees') {
    filteredPermissions = permissions.filter(
      (permission) => permission.name === PERMISSION_LIST.IMPORT_ANY_EMPLOYEE
    );
  } else if (operationName === 'readInvoiceApproval') {
    filteredPermissions = permissions.filter(
      (permission) => permission.name === PERMISSION_LIST.READ_INVOICE_APPROVAL
    );
  } else if (operationName === 'readVendorApproval') {
    filteredPermissions = permissions.filter(
      (permission) => permission.name === PERMISSION_LIST.READ_VENDOR_APPROVAL
    );
  } else if (operationName === 'readReimbursementApproval') {
    filteredPermissions = permissions.filter(
      (permission) =>
        permission.name === PERMISSION_LIST.READ_REIMBURSEMENT_APPROVAL
    );
  } else if (operationName === 'readReimbursement') {
    filteredPermissions = permissions.filter(
      (permission) =>
        permission.name === PERMISSION_LIST.READ_ANY_REIMBURSEMENT ||
        permission.name === PERMISSION_LIST.READ_BRANCH_REIMBURSEMENT ||
        permission.name === PERMISSION_LIST.READ_OWN_REIMBURSEMENT
    );
  } else if (operationName === 'createReimbursement') {
    filteredPermissions = permissions.filter(
      (permission) =>
        permission.name === PERMISSION_LIST.CREATE_ANY_REIMBURSEMENT ||
        permission.name === PERMISSION_LIST.CREATE_BRANCH_REIMBURSEMENT ||
        permission.name === PERMISSION_LIST.CREATE_OWN_REIMBURSEMENT
    );
  } else if (operationName === 'deleteReimbursement') {
    filteredPermissions = permissions.filter(
      (permission) =>
        permission.name === PERMISSION_LIST.DELETE_ANY_REIMBURSEMENT ||
        permission.name === PERMISSION_LIST.DELETE_BRANCH_REIMBURSEMENT ||
        permission.name === PERMISSION_LIST.DELETE_OWN_REIMBURSEMENT
    );
  } else if (operationName === 'updateReimbursement') {
    filteredPermissions = permissions.filter(
      (permission) =>
        permission.name === PERMISSION_LIST.UPDATE_ANY_REIMBURSEMENT ||
        permission.name === PERMISSION_LIST.UPDATE_BRANCH_REIMBURSEMENT ||
        permission.name === PERMISSION_LIST.UPDATE_OWN_REIMBURSEMENT
    );
  } else if (operationName === 'markPaidReimbursement') {
    filteredPermissions = permissions.filter(
      (permission) =>
        permission.name === PERMISSION_LIST.MARK_PAID_ANY_REIMBURSEMENT
    );
  }
  if (filteredPermissions.length > 0) {
    return true;
  } else {
    return false;
  }
}

export const checkForModuleAccess = (permissions: permission[] | null) => {
  const permissionStatus = {
    invoices: false,
    employees: false,
    branches: false,
    vendors: false,
    dashboard: false,
    rules_policies: false,
    reimbursement: false,
  };
  permissions &&
    permissions.forEach((permission) => {
      if (permission.name.startsWith(WILD_CARD_PERMISSIONS.invoices)) {
        permissionStatus.invoices = true;
      }
      if (permission.name.startsWith(WILD_CARD_PERMISSIONS.employees)) {
        permissionStatus.employees = true;
      }
      if (permission.name.startsWith(WILD_CARD_PERMISSIONS.branches)) {
        permissionStatus.branches = true;
      }
      if (permission.name.startsWith(WILD_CARD_PERMISSIONS.vendors)) {
        permissionStatus.vendors = true;
      }
      if (permission.name.startsWith(WILD_CARD_PERMISSIONS.dashboard)) {
        permissionStatus.dashboard = true;
      }
      if (permission.name.startsWith(WILD_CARD_PERMISSIONS.reimbursements)) {
        permissionStatus.reimbursement = true;
      }
      if (
        [
          PERMISSION_LIST.READ_INVOICE_APPROVAL,
          PERMISSION_LIST.READ_VENDOR_APPROVAL,
          PERMISSION_LIST.READ_ROLE,
        ].includes(permission.name)
      ) {
        permissionStatus.rules_policies = true;
      }
    });
  return permissionStatus;
};
