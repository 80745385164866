/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useMemo, useContext } from 'react';
import {
  Amount,
  Avatar,
  Box,
  Button,
  EmptyState,
  FileIcon,
  Inline,
  MultiSelect,
  RightCaratIcon,
  SearchIcon,
  SkeletonRows,
  SpinnerIcon,
  Stack,
  Text,
  TextInput,
  DateSelect,
  AlertTriangleIcon,
  HoverableBox,
  ReturnIcon,
} from '@nbfc-expense-tool/ui';
import {
  useActiveVendors,
  vendorRegistrationTypeOptions,
} from '@nbfc-expense-tool/data-store/dashboard';
import { useNavigate } from 'react-router-dom';
import { FilterContextType, VendorFilterContext } from '.';
import { debounce } from '@nbfc-expense-tool/data-store/utils';
import { EmptyVendorList } from '../../Vendors';

const idPrefix = 'active-vendors';

export const ActiveVendors: React.FC = () => {
  const {
    vendors,
    loading,
    perPage,
    totalVendors,
    canGoNext,
    canGoBack,
    syncingData,
    currentPage,
    lastPage,
    params,
    hasAppliedFilters,
    resetFilters,
    handlePageChange,
    handleDateChange,
    handleParamChange,
    exportExcel,
    reportLoading,
  } = useActiveVendors();
  const filterOptions = useContext<FilterContextType | null>(
    VendorFilterContext
  );
  const branches = filterOptions?.branches;
  const branchesFetchedStatus = filterOptions?.branchesStatus;
  const vendorTypesList = filterOptions?.vendorTypes;
  const vendorTypesFetchedStatus = filterOptions?.vendorTypesStatus;
  const navigate = useNavigate();

  const branchForFilters = useMemo(() => {
    return branches?.length
      ? branches?.map((b) => {
          return {
            label: `${b.name} - ${b.branch_code}`,
            value: `${b.id}`,
          };
        })
      : [];
  }, [branches]);

  const vendorTypesForFilters = useMemo(() => {
    return vendorTypesList?.length
      ? vendorTypesList?.map((b) => {
          return {
            label: b.name,
            value: `${b.id}`,
          };
        })
      : [];
  }, [vendorTypesList]);

  const onVendorClickHandler = (vendorId: string) => {
    navigate(`/home/vendors/${vendorId}?from=active-vendors`);
  };

  return !vendors?.length && !hasAppliedFilters ? (
    <Stack paddingTop="10" alignItems="center" justifyContent="center">
      {loading === 'in_progress' ? (
        <Inline gap="4">
          <SpinnerIcon size="3" color="iconMedium" />
          <Text variation="b2">Loading...</Text>
        </Inline>
      ) : (
        <EmptyVendorList vendorStatus="active-vendors" />
      )}
    </Stack>
  ) : (
    <Stack paddingTop="3.5" gap="6">
      <Inline justifyContent={'between'}>
        <Box gap="4" display="flex">
          <DateSelect
            id={`${idPrefix}-select-date`}
            value={params.dateFilter}
            onSave={(option) => {
              handleDateChange(option);
            }}
          />
          <MultiSelect
            id={`${idPrefix}-select-branch`}
            label="Branch"
            actionBtnTitle="Show Results"
            value={params.branches}
            options={branchForFilters}
            loadingOptions={branchesFetchedStatus === 'in_progress'}
            onSave={(values) => {
              handleParamChange('branches', values);
            }}
          />
          <MultiSelect
            id={`${idPrefix}-select-type`}
            label="Type"
            actionBtnTitle="Show Results"
            value={params.type}
            options={vendorTypesForFilters}
            loadingOptions={vendorTypesFetchedStatus === 'in_progress'}
            onSave={(values) => {
              handleParamChange('type', values);
            }}
          />
          <MultiSelect
            id={`${idPrefix}-select-registration-type`}
            label="Registration Type"
            actionBtnTitle="Show Results"
            value={params.registrationType}
            options={vendorRegistrationTypeOptions}
            loadingOptions={false}
            onSave={(values) => {
              handleParamChange('registrationType', values);
            }}
          />
        </Box>
        <Button
          id={`${idPrefix}-export-excel-button`}
          title="Export To Excel"
          onClick={exportExcel}
          type="outlined"
          state={reportLoading ? 'loading' : undefined}
          leftIcon={(props) => <ReturnIcon {...props} />}
        />
      </Inline>

      <Box
        rounded="md"
        borderWidth="1"
        paddingTop="2.5"
        borderColor="borderSeparator"
        backgroundColor="surfaceDefault"
      >
        <Stack gap="6">
          <Inline paddingX="2.5" alignItems="center" justifyContent="between">
            <Inline style={{ width: 324 }}>
              <TextInput
                minHeight="5"
                id={`${idPrefix}-search-input`}
                aria-label="search"
                placeholder="Search by Vendor Name"
                leftIcon={(props) => <SearchIcon {...props} marginRight="1" />}
                fullWidth
                onChange={debounce((value) => {
                  handleParamChange('q', [value]);
                })}
              />
            </Inline>
            <Inline alignItems="center" gap="6">
              {totalVendors ? (
                totalVendors <= 10 ? (
                  <Text color="textMedium" variation="c1">
                    Showing{' '}
                    {currentPage === 1 ? 1 : (currentPage - 1) * perPage + 1} of{' '}
                    {totalVendors}
                  </Text>
                ) : (
                  <Text color="textMedium" variation="c1">
                    Showing{' '}
                    {currentPage === 1 ? 1 : (currentPage - 1) * perPage + 1}-
                    {lastPage === currentPage
                      ? totalVendors
                      : vendors
                      ? vendors.length * (currentPage - 1) + vendors.length
                      : 0}{' '}
                    of {totalVendors}
                  </Text>
                )
              ) : null}
              <Inline gap="2">
                <Box
                  id={`${idPrefix}-show-previous-page`}
                  as="button"
                  disabled={!canGoBack}
                  backgroundColor="transparent"
                  onClick={() =>
                    canGoBack ? handlePageChange('previous') : undefined
                  }
                >
                  <RightCaratIcon
                    cursor="pointer"
                    size="2.5"
                    rotate="180"
                    color={canGoBack ? 'iconMedium' : 'iconLowest'}
                  />
                </Box>
                <Box
                  as="hr"
                  width="px"
                  height="2.5"
                  backgroundColor="borderSeparator"
                />
                <Box
                  id={`${idPrefix}-show-next-page`}
                  as="button"
                  disabled={!canGoNext}
                  backgroundColor="transparent"
                  onClick={() =>
                    canGoNext ? handlePageChange('next') : undefined
                  }
                >
                  <RightCaratIcon
                    cursor="pointer"
                    size="2.5"
                    color={canGoNext ? 'iconMedium' : 'iconLowest'}
                  />
                </Box>
              </Inline>
            </Inline>
          </Inline>
          <Box as="table" width="full" position="relative">
            <Box as="thead" bgColor="surfaceNeutralLowest">
              <Box as="tr">
                <Box
                  as="th"
                  position="sticky"
                  paddingY="1.5"
                  paddingX="2"
                  style={{
                    width: 248,
                  }}
                  top="0"
                  textAlign="left"
                  bgColor="surfaceNeutralLowest"
                >
                  <Text variation="c1">Vendor Name</Text>
                </Box>
                <Box
                  as="th"
                  position="sticky"
                  paddingY="1.5"
                  paddingX="2"
                  style={{
                    width: 120,
                  }}
                  top="0"
                  textAlign="left"
                  bgColor="surfaceNeutralLowest"
                >
                  <Text variation="c1">Type & Reg. Type</Text>
                </Box>
                <Box
                  as="th"
                  position="sticky"
                  paddingY="1.5"
                  paddingX="2"
                  style={{
                    width: 120,
                  }}
                  top="0"
                  textAlign="left"
                  bgColor="surfaceNeutralLowest"
                >
                  <Text variation="c1">Pending Invoices</Text>
                </Box>
                <Box
                  as="th"
                  position="sticky"
                  paddingY="1.5"
                  paddingX="2"
                  style={{
                    width: 120,
                  }}
                  top="0"
                  bgColor="surfaceNeutralLowest"
                >
                  <Inline>
                    <Text variation="c1">Approved Invoices</Text>
                  </Inline>
                </Box>
                <Box
                  as="th"
                  position="sticky"
                  paddingY="1.5"
                  paddingX="2"
                  style={{
                    width: 120,
                  }}
                  top="0"
                  bgColor="surfaceNeutralLowest"
                >
                  <Inline>
                    <Text variation="c1">Paid</Text>
                  </Inline>
                </Box>
                <Box
                  as="th"
                  position="sticky"
                  paddingY="1.5"
                  paddingX="2"
                  style={{
                    width: 120,
                  }}
                  top="0"
                  bgColor="surfaceNeutralLowest"
                >
                  <Inline>
                    <Text variation="c1">Unpaid</Text>
                  </Inline>
                </Box>
              </Box>
            </Box>
            {syncingData ? (
              <SkeletonRows numOfRows={10} numOfCols={5} />
            ) : (
              <Box as="tbody">
                {vendors?.length ? (
                  vendors.map((vendor) => {
                    return (
                      <React.Fragment key={vendor.id}>
                        <Box
                          id={`${idPrefix}-list-${vendor.id}`}
                          as="tr"
                          borderTopWidth="1"
                          cursor="pointer"
                          tabIndex={-1}
                          backgroundColor={{
                            hover: 'surfacePrimaryLowest',
                          }}
                          borderBottomWidth="1"
                          borderColor="borderSeparator"
                          onClick={() =>
                            onVendorClickHandler(String(vendor.id))
                          }
                        >
                          <Box
                            as="td"
                            paddingX="2"
                            paddingY="2"
                            borderBottomWidth="1"
                            borderColor="borderSeparator"
                          >
                            <Inline marginBottom="0.5" alignItems="center">
                              <Avatar
                                avatarSize="lg"
                                id={
                                  vendor?.id.toString() || vendor.id.toString()
                                }
                                avatarText={
                                  vendor?.name?.length ? vendor?.name[0] : 'U'
                                }
                              />
                              <Stack marginLeft="1.5" flex="1">
                                <Inline alignItems="center">
                                  <Text variation="t4" flex="1">
                                    {vendor?.name}
                                  </Text>
                                  {!vendor?.is_data_complete && (
                                    <HoverableBox text="Mandatory Details Missing">
                                      <AlertTriangleIcon
                                        color="iconWarning"
                                        size="3"
                                        marginLeft="1"
                                      />
                                    </HoverableBox>
                                  )}
                                </Inline>
                              </Stack>
                            </Inline>
                          </Box>
                          <Box
                            as="td"
                            paddingX="2"
                            paddingY="2"
                            className="whitespace-pre"
                            textAlign="left"
                            borderBottomWidth="1"
                            borderColor="borderSeparator"
                            valign="top"
                          >
                            <Stack>
                              <Text variation="t4">
                                {vendor.vendor_type?.name}
                              </Text>
                              <Text variation="c2" color="textLow">
                                {
                                  vendorRegistrationTypeOptions.find(
                                    (item) =>
                                      item.value === vendor.company_reg_type
                                  )?.label
                                }
                              </Text>
                            </Stack>
                          </Box>
                          <Box
                            as="td"
                            paddingX="2"
                            paddingY="2"
                            className="whitespace-pre"
                            textAlign="left"
                            borderBottomWidth="1"
                            borderColor="borderSeparator"
                            valign="top"
                          >
                            <Stack>
                              <Text variation="t4">
                                {vendor.pending_invoices_count || 0}
                              </Text>
                              <Text variation="b2" color="textLow">
                                Amt:{' '}
                                <Amount
                                  amount={
                                    vendor.pending_invoices_sum_grand_total_amount ||
                                    0
                                  }
                                />
                              </Text>
                            </Stack>
                          </Box>
                          <Box
                            as="td"
                            paddingX="2"
                            paddingY="2"
                            className="whitespace-pre"
                            textAlign="left"
                            borderBottomWidth="1"
                            borderColor="borderSeparator"
                            valign="top"
                          >
                            <Stack>
                              <Text variation="t4">
                                {vendor.approved_invoices_count || 0}
                              </Text>
                              <Text variation="b2" color="textLow">
                                Amt:{' '}
                                <Amount
                                  amount={
                                    vendor.approved_invoices_sum_grand_total_amount ||
                                    0
                                  }
                                />
                              </Text>
                            </Stack>
                          </Box>
                          <Box
                            as="td"
                            paddingX="2"
                            paddingY="2"
                            className="whitespace-pre"
                            textAlign="left"
                            borderBottomWidth="1"
                            borderColor="borderSeparator"
                            valign="top"
                          >
                            <Stack>
                              <Text variation="t4" color="textSuccess">
                                {vendor.paid_invoices_count || 0}
                              </Text>
                              <Text variation="b2" color="textSuccess">
                                Amt:{' '}
                                <Amount
                                  amount={
                                    vendor.paid_invoices_sum_grand_total_amount ||
                                    0
                                  }
                                />
                              </Text>
                            </Stack>
                          </Box>
                          <Box
                            as="td"
                            paddingX="2"
                            paddingY="2"
                            className="whitespace-pre"
                            textAlign="left"
                            borderBottomWidth="1"
                            borderColor="borderSeparator"
                            valign="top"
                          >
                            <Stack>
                              <Text variation="b2" color="textError">
                                {vendor.unpaid_invoices_count || 0}
                              </Text>
                              <Text variation="b2" color="textError">
                                Amt:{' '}
                                <Amount
                                  amount={
                                    vendor.unpaid_invoices_sum_grand_total_amount ||
                                    0
                                  }
                                />
                              </Text>
                            </Stack>
                          </Box>
                        </Box>
                      </React.Fragment>
                    );
                  })
                ) : (
                  <Box as="tr">
                    <Box as="td" colSpan={8}>
                      <EmptyState
                        renderIcon={(props) => <FileIcon {...props} />}
                        title="No Vendors Found!"
                        subText={`Please try changing your applied filters!`}
                        renderButton={() => (
                          <Button
                            id={`${idPrefix}-empty-state-reset-filter-button`}
                            title="Reset Filters"
                            onClick={resetFilters}
                          />
                        )}
                      />
                    </Box>
                  </Box>
                )}
              </Box>
            )}
          </Box>
        </Stack>
      </Box>
    </Stack>
  );
};
